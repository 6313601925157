import { createAction, createReducer } from "@reduxjs/toolkit"

export const changeName = createAction('CHANGE_NAME')                   // 1
export const changeText = createAction('CHANGE_TEXT')                   // 2
export const changeVideo = createAction('CHANGE_VIDEO')                 // 3
export const pushPics = createAction('PUSH_PICS')                       // 4
export const filterPic = createAction('FILTER_PIC')                     // 5
export const changeCategory = createAction('CHANGE_CATEGORY')           // 6
export const changeCover = createAction('CHANGE_COVER')                 // 7
export const pushIngredient = createAction('PUSH_INGREDIENT')           // 8
export const changeIngredient = createAction('CHANGE_INGREDIENT')       // 9
export const removeIngredient = createAction('DELETE_INGREDIENT')       // 10
export const changeIngredientsList = createAction('CHANGE_INGREDIENTS_LIST')    // 11
export const pushIngredientToList = createAction('PUSH_INGREDIENT_TO_LIST')     // 12
export const changeRecipesList = createAction('CHANGE_RECIPES_LIST')    // 13
export const pushRecipeToList = createAction('PUSH_RECIPE_TO_LIST')     // 14
export const removeAllIngredients = createAction('REMOVE_ALL_INGREDIENTS')     // 14



export function setName(data) {      // 1
    console.log('setName');
    return async (dispatch) => {
        dispatch(changeName(data))
    }
}
export function setText(data) {     // 2
    console.log('setText');
    return async (dispatch) => {
        dispatch(changeText(data))
    }
}

export function setVideo(data) {    // 3
    console.log('setVideo');
    return async (dispatch) => {
        dispatch(changeVideo(data))
    }
}

export function setCategory(data) { // 6
    console.log('setCategory');
    return async (dispatch) => {
        dispatch(changeCategory(data))
    }
}

export function setCover(data) {    // 7
    console.log('setCover');
    return async (dispatch) => {
        dispatch(changeCover(data))
    }
}

export function addIngredient(data) {   // 8
    console.log('addIngredient');
    return async (dispatch) => {
        dispatch(pushIngredient(data))
    }
}

export function editIngredient(ingredientToChange, newQuantity) {   // 9
    console.log('editIngredient');
    return async (dispatch) => {
        const data = {
            'ingredientToChange': ingredientToChange,
            'newQuantity': newQuantity
        }
        dispatch(changeIngredient(data))
    }
}

export function deleteIngredient(data) {    // 10
    console.log('deleteIngredient');
    return async (dispatch) => {
        dispatch(removeIngredient(data))
    }
}

/* export function setIngredientsList(data) {  // 11
    console.log('setIngredientsList');
    return async (dispatch) => {
        dispatch(changeIngredientsList(data))
    }
} */

export function addIngredientToList(data) {     // 12
    console.log('addIngredientToList');
    return async (dispatch) => {
        dispatch(pushIngredientToList(data))
    }
}

export function setRecipesList(data) {  // 13
    console.log('setRecipesList');
    return async (dispatch) => {
        dispatch(changeRecipesList(data))
    }
}

export function addRecipeToList(data) {     // 14
    console.log('addRecipeToList');
    return async (dispatch) => {
        dispatch(pushRecipeToList(data))
    }
}

export function deleteAllIngredients() {    // 10
    console.log('deleteAllIngredient');
    return async (dispatch) => {
        dispatch(removeAllIngredients())
    }
}

const initialState = {
    name: null,
    text: null,
    video: null,
    pics: [],
    category: null,
    cover: null,
    ingredients: [],
    ingredientsList : [],
    recipesList : null
}

export default createReducer(initialState, (builder) =>
    builder
        .addCase(changeName, (draft, action) => {   // 1
            draft.name = action.payload
        })
        .addCase(changeText, (draft, action) => {   // 2
            draft.text = action.payload
        })
        .addCase(changeVideo, (draft, action) => {  // 3
            draft.video = action.payload
        })

        // 4

        // 5

        .addCase(changeCategory, (draft, action) => {   // 6
            draft.category = action.payload
        })
        .addCase(changeCover, (draft, action) => {      // 7
            draft.cover = action.payload
        })        
        .addCase(pushIngredient, (draft, action) => {               // 8. Ajoute un ingredient a la recette    
            draft.ingredients.push(action.payload.ingredient)
        })
        .addCase(changeIngredient, (draft, action) => {             // 9. Change la quantite d'un ingredient dans la recette
            draft.ingredients.forEach(ingredient => {
                if (ingredient.name === action.payload.ingredientToChange) {
                    ingredient.quantity = action.payload.newQuantity
                }
            })
        })
        .addCase(removeIngredient, (draft, action) => {             // 10. Supprime un ingredient dans la recette
            let newList = []
            draft.ingredients.forEach(ingredient => {
                if (ingredient.name !== action.payload.name) {
                    newList.push(ingredient)
                }
            })
            draft.ingredients = newList
        })
        .addCase(changeIngredientsList, (draft, action) => {        // 11. Enregistre la liste complete d'ingredients depuis la bdd vers le store
            draft.ingredientsList = action.payload
        })
        .addCase(pushIngredientToList, (draft, action) => {         // 12. Ajoute un ingredient a la liste du store
            draft.ingredientsList.push(action.payload)
        })
        
        .addCase(pushRecipeToList, (draft, action) => {         // 14. Ajoute une recette a la liste du store
            draft.recipesList.push(action.payload)
        })
        .addCase(removeAllIngredients, (draft, action) => {         // 14. Ajoute une recette a la liste du store
            draft.ingredients = []
        })
)