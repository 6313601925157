import { useState } from "react"
import styled from "styled-components"
import colors from "../components/UIKit/colors"

const Wrapper = styled.div`
    min-height:80vh;
    position: relative;
`
const Loading = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(250,250,250,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`

const Btn = styled.button`
    border : unset;
    padding : 0.5rem 1rem;
    font-size: 1.5rem;
    border-radius: 1rem;
    &.beige{
        background-color: ${colors.beige};
    }
    &.pink{
        background-color: ${colors.pink}
    }
`

export default function TrainingAdmin() {
    const [isLoading, setIsLoading] = useState(false)
    const [modale, setModale] = useState(false)

    

    return (
        <Wrapper>
           {isLoading && <Loading>Loading</Loading>}
           <h1>Training</h1>
            <Btn className="beige" onClick={() => setModale('NEW_TRAINING')}>Nouveau</Btn>
            <Btn className="pink" onClick={() => setModale('NEW_EXERCICE')}>Ajouter un exercice</Btn>
        </Wrapper>
    )
}