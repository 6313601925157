import axios from "axios"
import { useEffect, useState } from "react"
import { MdDelete } from "react-icons/md"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { setIngredientsList, setMealsList } from "../../../features/admin"
import { addIngredient, deleteAllIngredients, deleteIngredient, editIngredient, setName, setText } from "../../../features/recipeCreator"
import colors from "../../UIKit/colors"

const Wrapper = styled.div`

& .error{
    border: 1px solid red;
}

`
const TitleSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem; 
`
const StatusDisplay = styled.div`
    font-size: 1.2rem;
    text-align: center;
`
const NextBtn = styled.input`
    border: none;
    background-color: ${colors.beige};
    display: inline-block;
    padding: 0.4em 1em;
    font-size: 1.5rem;
    color: #f6f6f6;
    cursor: pointer;
`
const FormAddRecipe = styled.div`
    display: none; 
    flex-direction: column;
    align-items: center;
    gap: 1rem;   
`
const TitleRecipe = styled.div`

`
const Ingredient = styled.div`
    display: flex;
    align-items: center;
    justify-content : flex-end;
    margin : 0.5rem;
`
const IngredientName = styled.div`
    display: flex;
    flex: 1;
    word-break: break-word;
`
const IngredientData = styled.div`
    width : 15%;
    text-align: center;
`
const InputChangeQuantity = styled.input`
    width : 100%;
    border : unset;
    border-bottom : 1px solid black;
    background-color: rgba(200, 200, 200, 0.3);

    &:focus {
        outline: none;
    }
`
const FormChangeQuantity = styled.form`
    width: 15%;
    display: inline-block;

    & input[type=submit]{
        display: none;
    }
`
const DeleteIcon = styled(MdDelete)`
    display: flex;
    align-items; center;
    justify-content: center;
    width: 5%;
`


const InputWrapper = styled.div`
  position: relative;
  width: 80%;
  margin-bottom:20px;
`
const Input = styled.input`
  font-size: 18px;
  padding: 10px 0px 10px 0px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  &:focus {
    outline: none;
    border-bottom: 1px solid ${colors.beige};
  }
  &::placeholder{
    color: transparent;
  }
`
const Textarea = styled.textarea`
    font-size: 18px;
    padding: 10px 0px 10px 0px;
    display: block;
    width: 100%;
    height : 5rem;
    border: none;
    background-color: rgba(200,200,200,0.1);
    &:focus {
    outline: none;
    background-color: rgba(200,200,200,0.3);
    }
    &::placeholder{
    color: transparent;
    }
`
const Label = styled.label`
  color: #999;
  font-size: 1rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  ${Input}:focus ~ & {
    font-size: 1.2rem;
    color: ${colors.beige};
  }
  ${Input}:not(:placeholder-shown) ~ & {
    top: -5px;
    font-size: 1rem;
  }

  ${Textarea}:focus ~ & {
    font-size: 1.2rem;
    color: ${colors.beige};
  }
  ${Textarea}:not(:placeholder-shown) ~ & {
    top: -5px;
    font-size: 1rem;
  }
`

export const AddRecipe = () => {
    const adminData = useSelector((state) => state.adminData)
    const recipeCreator = useSelector((state) => state.recipeCreator)
    const [nameTemporary, setNameTemporary] = useState('')
    const [changeIngredient, setChangeIngredient] = useState(false)
    const [textRecipe, setTextRecipe] = useState('')
    const [categoryRecipe, setCategoryRecipe] = useState('')
    const dispatch = useDispatch()

    const handleSelectMeal = async (data) => {
        dispatch(deleteAllIngredients())
        adminData.mealsList.forEach(meal => {
            if (meal._id === data) {
                meal.ingredients.forEach(ingredient => {
                    dispatch(addIngredient({
                        'ingredient': {
                            name: ingredient.name,
                            quantity: ingredient.quantity,
                            cal: ingredient.cal,
                            fat: ingredient.fat,
                            carb: ingredient.carb,
                            prot: ingredient.prot,
                        }
                    }))
                    setTextRecipe(meal.recipe)
                });
            }
        })
    }
    const handleUpdateQuantity = (quantity, name) => {
        dispatch(editIngredient(name, quantity))
    }
    const handleDeleteIngredient = (name) => {
        dispatch(deleteIngredient(name))

    }

    const handleSaveRecipe = async () => {
        const data = {
            'name': recipeCreator.name,
            'text': textRecipe,
            'video': recipeCreator.video,
            'pics': recipeCreator.pics,
            'category': categoryRecipe,
            'cover': recipeCreator.cover,
            'ingredients': recipeCreator.ingredients
        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}api/diet/addRecipe`, data, { withCredentials: true })
        if (res.status === 201) {
            console.log('OK');
        } else { console.log('NOK'); }
    }

    useEffect(() => {
        const StatusDisplay = document.getElementById('StatusDisplay')
        const TitleSection = document.getElementById('TitleSection')
        const FormSection = document.getElementById('FormSection')
        const nameInput = document.getElementById('nameInput')

        const next1 = document.getElementById('next1')
        const prev2 = document.getElementById('prev2')

        next1.addEventListener("click", function (e) {
            e.preventDefault()
            if (nameTemporary !== null) {
                TitleSection.style.display = ("none")
                FormSection.style.display = ("flex")
                StatusDisplay.innerHTML = ""
            } else {
                nameInput.classList.add('error')
                StatusDisplay.innerHTML = "Titre invalide"
            }
        })

        prev2.addEventListener("click", function (e) {
            e.preventDefault()
            FormSection.style.display = ("none")
            TitleSection.style.display = ("flex")
        })

        
        if (adminData.ingredientsList.length === 0) dispatch(setIngredientsList())
        if (adminData.mealsList.length === 0) dispatch(setMealsList())
    })


    return (
        <Wrapper>
            <StatusDisplay id="StatusDisplay"></StatusDisplay>
            <TitleSection id="TitleSection">

                <InputWrapper>
                    <Input
                        onChange={(e) => setNameTemporary(e.target.value)}
                        type="text"
                        name="name"
                        id="nameInput"
                        placeholder="Titre"
                    />
                    <Label htmlFor="name">Titre</Label>
                </InputWrapper>
                <NextBtn id="next1" onClick={() => dispatch(setName(nameTemporary))} type="submit" value="Suivant"
                />
            </TitleSection>

            <FormAddRecipe id="FormSection">
                <TitleRecipe>{recipeCreator.name}</TitleRecipe>
                <select name="" id=""
                    onChange={(e) => { handleSelectMeal(e.target.value) }}>
                    {adminData.mealsList?.map((meal, index) => {
                        return (
                            <option
                                value={meal._id}
                                key={index}
                            >
                                {meal.name}
                            </option>
                        )
                    })}
                </select>

                {recipeCreator.ingredients?.map((ingredient, index) => {
                    return (
                        <Ingredient key={index}>
                            <IngredientName>{ingredient.name}</IngredientName>
                            {
                                changeIngredient === index ?
                                    <FormChangeQuantity onSubmit={() => setChangeIngredient(false)}>
                                        <input type="submit" value="" />
                                        <InputChangeQuantity autoFocus type="number" onChange={(e) => { handleUpdateQuantity(e.target.value, ingredient.name) }} />
                                    </FormChangeQuantity>
                                    :
                                    <IngredientData onClick={() => { setChangeIngredient(index) }}>{ingredient.quantity}</IngredientData>
                            }
                            <IngredientData>{ingredient.cal * ingredient.quantity / 100}</IngredientData>
                            <IngredientData>{ingredient.prot * ingredient.quantity / 100}</IngredientData>
                            <IngredientData>{ingredient.carb * ingredient.quantity / 100}</IngredientData>
                            <IngredientData>{ingredient.fat * ingredient.quantity / 100}</IngredientData>
                            <DeleteIcon onClick={() => handleDeleteIngredient(ingredient.name)} />
                        </Ingredient>
                    )
                })}

                <InputWrapper>
                    <Input
                        type="text"
                        name="text"
                        id="textRecipeInput"
                        placeholder="Recette"
                        onChange={(e) => setTextRecipe(e.target.value)}
                        value={textRecipe}
                    />
                    <Label htmlFor="name">Recette</Label>
                </InputWrapper>

                <InputWrapper>
                    <Input
                        type="text"
                        name="category"
                        id="categoryRecipeInput"
                        placeholder="Catégorie"
                        onChange={(e) => setCategoryRecipe(e.target.value)}
                        value={categoryRecipe}
                    />
                    <Label htmlFor="name">Catégorie</Label>
                </InputWrapper>

                <NextBtn type="submit" id="submit" value="Enregistrer" onClick={() => handleSaveRecipe()}/>

                <button id="prev2">Retour </button>
            </FormAddRecipe>
        </Wrapper>
    )
}
