import MainWidget from '../UIKit/mainWidget'
import pic from '../../images/dietBG.jpg'

const link = '/diet'
const title = 'Diet'

export default function TrainingWidget(){
    return (
            <MainWidget pic={pic} link={link} title={title} />
    )
   
}