import axios from "axios"
import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import styled from "styled-components"
import colors from "../../UIKit/colors"
import Delete from "./Delete"


const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;

    & h3 {
        color: ${colors.beige};
        font-size: 2rem;
        margin: 0px auto 20px;
    }

    & img{
        width:80%;
        margin-bottom: 20px;
    }

    @media (min-width: 780px) {
        & img{
            width: 30%;
        }
      }

`
const Head = styled.div`
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
`
const MemberLink = styled(Link)`
    font-size: 1.5rem;
`
const Poids = styled.div`
    margin-bottom:20px;
`
const Mensurations = styled.div`
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`
const InputWrapper = styled.div`
  position: relative;
  width: 40%;
  margin-bottom:20px;
`

const FeelingWrapper = styled.div`
width: 100%;
margin-bottom:20px;
overflow-x: hidden;
word-wrap: break-word;
`

const Label = styled.span`
    color: #757575;
`
const ImgContainer = styled.div`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
`

export default function BilanInfoAdmin() {
    const { bilanId } = useParams()
    const [bilan, setBilan] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [picFace, setPicFace] = useState(false)
    const [picProfil, setPicProfil] = useState(false)
    const [picBack, setPicBack] = useState(false)


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}api/bilan/${bilanId}`, { withCredentials: true })
            .then((res) => {
                const data = [res.data]
                setBilan(data)

                async function loadLinks(array) {

                    const res = await axios.post(`${process.env.REACT_APP_API_URL}api/bilan/images`, { keys: array }, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        withCredentials: true,
                    })
                    if (res.status === 201) {
                        const data = res.data
                        setPicFace(data[0])
                        setPicProfil(data[1])
                        setPicBack(data[2])

                    }
                }
                loadLinks([res.data.pictureFace, res.data.pictureSide, res.data.pictureBack])
            })
        setIsLoading(false)
    }, [bilanId])

    function formateDate(oldDate) {
        const date = new Date(oldDate)
        const day = date.getDate()
        const month = date.getMonth() + 1
        const result = day + " / " + month

        return result
    }
    console.log(bilan);

    return isLoading ? (
        <div>Loading</div>
    ) : (<>
        {bilan?.map((bilan, index) => (
        <Wrapper key={index}>
                    
                <Head>
                <MemberLink to={"/admin/" + bilan.userId} >Membre : {bilan?.userPseudo}</MemberLink>
                <Delete id={bilan?._id} />
            </Head>
            <h3>
                Bilan du  {formateDate(bilan?.createdAt)}
            </h3>
            <Poids><Label>Poids : </Label> {bilan?.weight}</Poids>
            <Mensurations>
                {bilan?.chest && <InputWrapper>
                    <Label>Poitrine : </Label> {bilan?.chest}
                </InputWrapper>}
                {bilan?.waist && <InputWrapper>
                    <Label>Taille : </Label> {bilan?.waist}
                </InputWrapper>}
                {bilan?.thigh && <InputWrapper>
                    <Label>Cuisse : </Label> {bilan?.thigh}
                </InputWrapper>}
                {bilan?.arm && <InputWrapper>
                    <Label>Bras : </Label> {bilan?.arm}
                </InputWrapper>}
                {bilan?.glutes && <InputWrapper>
                    <Label>Fesses : </Label> {bilan?.glutes}
                </InputWrapper>}
                {bilan?.calves && <InputWrapper>
                    <Label>Mollets : </Label> {bilan?.calves}
                </InputWrapper>}
                {bilan?.howWasTheWeek && <FeelingWrapper>
                    <Label>Déroulement de la semaine : </Label> {bilan?.howWasTheWeek}
                </FeelingWrapper>}
                {bilan?.modifMenuDone && <FeelingWrapper>
                    <Label>Modifications de menu : </Label> {bilan?.modifMenuDone}
                </FeelingWrapper>}
                {bilan?.outOfDiet && <FeelingWrapper>
                    <Label>Ecart : </Label> {bilan?.outOfDiet}
                </FeelingWrapper>}
                {bilan?.modifMenuWanted && <FeelingWrapper>
                    <Label>Modifications souhaitées : </Label> {bilan?.modifMenuWanted}
                </FeelingWrapper>}
                {(bilan?.wishes || bilan?.wishNextMenu) && <FeelingWrapper>
                    <Label>Envies : </Label> {bilan?.wishNextMenu ? bilan?.wishNextMenu : bilan?.wishes}
                </FeelingWrapper>}
                {bilan?.feeling && <FeelingWrapper>
                    <Label>Ressenti : </Label> {bilan?.feeling}
                </FeelingWrapper>}
                {bilan?.weeklySteps && <Poids><Label>Pas : </Label> {bilan?.weeklySteps}</Poids>}
                {bilan?.weeklyStepsDetails && <FeelingWrapper>
                    <Label>Détails au sujet des pas : </Label> {bilan?.weeklyStepsDetails}
                </FeelingWrapper>}
                {bilan?.weeklyWater && <Poids><Label>Eau : </Label> {bilan?.weeklyWater}</Poids>}
                {bilan?.weeklyWaterDetails && <FeelingWrapper>
                    <Label>Détails au sujet de l'eau : </Label> {bilan?.weeklyWaterDetails}
                </FeelingWrapper>}
                {bilan?.weeklyTrainings && <Poids><Label>Entrainements : </Label> {bilan?.weeklyTrainings}</Poids>}
                {bilan?.weeklyTrainingsDetails && <FeelingWrapper>
                    <Label>Détails au sujet des entrainements : </Label> {bilan?.weeklyTrainingsDetails}
                </FeelingWrapper>}
                {bilan?.weeklyCardio && <Poids><Label>Cardio : </Label> {bilan?.weeklyCardio}</Poids>}
                {bilan?.weeklyCardioDetails && <FeelingWrapper>
                    <Label>Détails au sujet du cardio : </Label> {bilan?.weeklyCardioDetails}
                </FeelingWrapper>}
                {(bilan?.extra || bilan?.anthgElse) && <FeelingWrapper>
                    <Label>Autre : </Label> {bilan?.anthgElse ? bilan?.anthgElse : bilan?.extra}
                </FeelingWrapper>}
            </Mensurations>
            <ImgContainer>
                {picFace
                    ? <><img src={picFace} alt="" /></>
                    : "No pic"
                }
                {picProfil
                    ? <><img src={picProfil} alt="" /></>
                    : "No pic"
                }
                {picBack
                    ? <><img src={picBack} alt="" /></>
                    : "No pic"
                }
            </ImgContainer>
        </Wrapper>
            ))}
            </>
        )
}