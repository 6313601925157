import { useState } from "react"
import { AiFillCloseCircle } from "react-icons/ai"
import styled from "styled-components"
import colors from "../../UIKit/colors"
import Exercices from "./Exercices"

const Wrapper = styled.div`
    position: fixed;
    top: 0px;
    width: 100%;
    height : 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(250,250,250,0.95);
    overflow-x: hidden;
    overflow-y: scroll;

    @media (min-width: 780px) {
        width: calc(100vw - 100px);
        max-width: calc(100vw - 100px);
    } 
`
const BtnClose = styled(AiFillCloseCircle)`
    display: block;
    color: rgba(250,250,250,0.7);
    background-color: ${colors.grey};
    width : 2rem;
    height : 2rem;
    border-radius: 50%; 
    margin : 1rem 1rem 1rem auto; 
`
const LoaderWrapper = styled.div`
    position: fixed;
    margin: 0px;
    background: radial-gradient(#CECECEdd, #ffffffdd);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
`
const ContainerLoader = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background: linear-gradient(165deg, rgba(255,255,255,1) 0%, rgb(220, 220, 220) 40%, rgb(170, 170, 170) 98%, rgb(10, 10, 10) 100%);
    position: relative;
`

const Loader =styled.div`
    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border-bottom: 0 solid #ffffff05;
        
        box-shadow: 
            0 -10px 20px 20px #ffffff40 inset,
            0 -5px 15px 10px #ffffff50 inset,
            0 -2px 5px #ffffff80 inset,
            0 -3px 2px #ffffffBB inset,
            0 2px 0px #ffffff,
            0 2px 3px #ffffff,
            0 5px 5px #ffffff90,
            0 10px 15px #ffffff60,
            0 10px 20px 20px #ffffff40;
        filter: blur(3px);
        animation: 2s rotate linear infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg)
        }
    }
`
export default function ModaleTraining({setModale}) {
    const [isLoading, setIsLoading] = useState(true)

    return(
        <Wrapper>
            {isLoading && <LoaderWrapper><ContainerLoader class="container"><Loader class="loader"></Loader></ContainerLoader></LoaderWrapper>}
            <BtnClose onClick={() => setModale(false)} />
            <Exercices setIsLoading={setIsLoading}/>
        </Wrapper>
    )
}