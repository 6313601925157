import { useState } from "react"
import {  useSelector } from "react-redux"
import styled from "styled-components"
import colors from "../UIKit/colors"
import Delete from "./DeleteAliment"
import FormAlimOk from "./FormAlimOk"


const ContainerPink = styled.div`
    background-color: ${colors.pink};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.2rem;
`
const Nutrition = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    color:  white;
    padding: 1.5rem 0px 1.5rem 0px;
`
const TitleSection = styled.div`
    color: white;
    width: 100%;
    font-size: 1.3rem;
    margin-bottom: 1rem;
`
const TitleAliments = styled.div`
    color: white;
    width: 100%;
    font-size: 1.1rem;
`
const Aliment = styled.div`
    width: 100%;
    margin-top: 0.7rem;
    padding : 0.3rem 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
`

const AddAliment = styled.div`
    width: 100%;
    margin-top: 0.7rem;
    padding : 0.3rem 0px;
    display: flex;
    color: rgba(255, 255, 255, 0.5);
`



export default function Aliments() {
    const userData = useSelector((state) => state.user)
    const [editOk, setEditOk] = useState(false)
    const [editNok, setEditNok] = useState(false)
    



    return (
        <ContainerPink>
            <Nutrition>
                <TitleSection>Mes gouts</TitleSection>
                <TitleAliments>Mes aliments préférés</TitleAliments>
                {userData.foodOk?.map((food, index) => (
                    <Aliment key={index}>
                        {food}
                        <Delete uid={userData._id} alimType={'removeAlimOk'} alim={food}/>
                    </Aliment>
                ))}
                {editOk ?
                    <FormAlimOk uid={userData._id} setEditOk={setEditOk} alimType={'addAlimOk'}/> :
                    <AddAliment onClick={() => setEditOk(true)}>Ajouter un aliment</AddAliment>
                }
            </Nutrition>
            <Nutrition>
                <TitleAliments>Aliments que je n'aime pas</TitleAliments>
                {userData.foodNok?.map((food, index) => (
                    <Aliment key={index}>
                        {food}
                        <Delete uid={userData._id} alimType={'removeAlimNok'} alim={food}/>
                    </Aliment>
                ))}
                {editNok ?
                    <FormAlimOk uid={userData._id} setEditOk={setEditNok} alimType={'addAlimNok'}/> :
                    <AddAliment onClick={() => setEditNok(true)}>Ajouter un aliment</AddAliment>
                }
            </Nutrition>
        </ContainerPink>
    )
}