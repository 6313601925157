import { useEffect, useState } from "react"
import { MdOutlineAddCircle } from "react-icons/md"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { addSets, addReps,  setNameDay, addNote } from "../../../features/trainingCreator"
import colors from "../../UIKit/colors"

const Wrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    background-color: rgba(200, 200, 200, 0.3);
    border-radius: 1rem;
    height: 90vh;
`
const InfosContainer = styled.div`
    margin: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`
const Info = styled.div`
`
const InputS = styled.input`
    width: 3rem;
    font-size: 1.2rem;
    background-color: rgba(256,256,256, 0.8);
    border: none;
    font-weight: light;

    &:focus {
        outline: none;
        border-bottom: 2px solid ${colors.beige};
    }
    &::placeholder{
        font-size: 1rem;
    }
`
const InputL = styled.input`
    width: 60%;
    font-size: 1.2rem;
    background-color: rgba(256,256,256, 0.8);
    border: none;
    font-weight: light;

    &:focus {
        outline: none;
        border-bottom: 2px solid ${colors.beige};
    }
    &::placeholder{
        font-size: 1rem;
    }
`
const TextArea = styled.textarea`
    font-size: 1rem;
    background-color: rgba(256,256,256, 0.8);
    border: none;
    font-weight: light;
    min-height: 4rem;

    &:focus {
        outline: none;
        border-bottom: 2px solid ${colors.beige};
    }
`
const Exercice = styled.div`
    margin: 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`
const DataExerciceContainer = styled.div`
    display: flex;
    gap: 1rem;
`
const OrderBtn = styled.div`
    
`

const BtnAddExercice = styled.button`
    background-color: ${colors.beige};
    padding: 0.5rem 0;
    margin-left: 1rem;
    display: flex;
    justify-content: space-around;
    font-size:1.2rem;
    align-items: center;
    border: none;
    width: 60%;
    color: ${colors.darkBrown};
    border-radius: 1rem;
`

export default function DayTraining({setModale}) {
    const selectedDay = useSelector((state) => state.trainingCreator.selectedDay)
    const day = useSelector((state) => state.trainingCreator.days[selectedDay])
    const [name, setName] = useState(day?.nameDay)
    const dispatch = useDispatch()

    const nameInput = document.querySelector("#nameInput")
    nameInput?.addEventListener("focusout", (e) => {
        dispatch(setNameDay(name))
        setName('')
    })

    if(day?.nameDay) console.log(day.nameDay);
    return !day ? (
        <>no day</>
    ) :
        (
            <Wrapper>
                <InfosContainer>
                    <InputL type="text" value={name} onChange={(e) => { dispatch(setNameDay(e.target.value)) }} placeholder="Nom du jour" />
                    <TextArea name="" id="" placeholder="Notes"></TextArea>
                </InfosContainer>
                {day?.exercices?.map((exercice, index)=>{
                    return(
                        <Exercice key={index}>
                            <div>{exercice.name}</div>
                            <DataExerciceContainer>
                                <InputS type="text"  value={exercice.sets} onChange={(e) => { dispatch(addSets({name: exercice.name, index : exercice.index, sets : e.target.value})) }} placeholder="Séries" />
                                x
                                <InputS type="text" value={exercice.reps} onChange={(e) => { dispatch(addReps({name: exercice.name, index : exercice.index, reps : e.target.value})) }} placeholder="Reps" />
                            </DataExerciceContainer>
                            <TextArea onChange={(e) => { dispatch(addNote({name: exercice.name, index : exercice.index, note : e.target.value})) }} placeholder="Notes"></TextArea>
                            <iframe width="560" height="315" 
                                src={exercice.video}
                                title="YouTube video player" frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; 
                                encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen>
                            </iframe>
                        </Exercice>
                    )
                })}

                <BtnAddExercice onClick={() => setModale(true)}>Ajouter un exercice<MdOutlineAddCircle /></BtnAddExercice>
            </Wrapper>
        )
}