import React, { useEffect } from "react";
import { useContext } from "react"
import { UidContext } from "../components/AppContext"
import { useDispatch, useSelector, } from "react-redux";
import { fetchOrUpdateUserBilans } from "../features/bilan";
import styled from 'styled-components'
import Tracking from '../components/widgets/tracking'
import ListeBilan from "../components/Bilan/ListeBilan";
import { checkSuivi } from "../components/Utils";
import colors from "../components/UIKit/colors";

const SuiviWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &>h2{
        color : ${colors.grey};
        margin-bottom: 1rem;
    }
`


export default function Suivi() {

    const { uid } = useContext(UidContext)
    const dispatch = useDispatch();


    useEffect(() => {
        if (uid) {
            dispatch(fetchOrUpdateUserBilans(uid))
        }
    }, [uid, dispatch])



    return (
        <SuiviWrapper>
            <h2>Mon suivi</h2>
            <Tracking />
            <ListeBilan />
        </SuiviWrapper>
    )

}