import axios from "axios"
import { useState } from "react"
import { useEffect } from "react"
import { Link } from 'react-router-dom'
import styled from "styled-components"
import Delete from "../Admin/Delete"
import colors from "../UIKit/colors";
import {  fetchOrUpdateUsers } from "../../features/admin"
import { useDispatch, useSelector } from "react-redux"

const ListeBilanWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    background-color: white;
    max-height: 300px;
    overflow-y: scroll;

@media (min-width: 780px) {
    width: 48%;
    max-height: 70vh;
    margin-bottom : 50px;
  } 
`

const LinkBilan = styled(Link)`
    width : 90%;
    border-bottom: 1px solid ${colors.beige};
    padding: 0.5rem 0px;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
`
const InfoBilan = styled.div`

`
const LoadingWrapper = styled.div`
    position: relative;
    margin: auto;
`

const SecondTitle = styled.div`
    color : ${colors.grey};
    font-size: 1.6rem;
    width: 100%;
    text-align: center;
    padding: 0.5rem 0px;
`
export default function UsersList(){
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch()
    const allUsers = useSelector((state) => state.adminData.users)

    useEffect(() => {
        async function fetchUsersAndBilans() {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}api/user`, { withCredentials: true })
            const data = await res.data
            dispatch(fetchOrUpdateUsers(data))
            setIsLoading(false)

        }
        fetchUsersAndBilans()

    }, [dispatch])

    return isLoading ? <LoadingWrapper>
        <div className="lds-ripple-s" >
                            <div></div>
                            <div></div>
                        </div>
    </LoadingWrapper> : (
        <ListeBilanWrapper>
            <SecondTitle>Membres</SecondTitle>
                {allUsers?.map((user, index) => (
                    <LinkBilan to={user._id} key={index}>
                        <InfoBilan>{user.pseudo}</InfoBilan>
                        <Delete type={'user'} id={user._id} />
                    </LinkBilan>
                ))}
            </ListeBilanWrapper>
    )
}