import Logout from "../components/Log/Logout"
import styled from "styled-components"
import Infos from "../components/profil/Infos";
import Aliments from "../components/profil/Aliments";
import Objectifs from "../components/profil/Objectifs";

const Wrapper = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export default function Profil(){

    return (
        <Wrapper>
            <Infos />
            <Aliments />
            <Objectifs />
            <Logout />
        </Wrapper>
    )
}