import React, { useEffect, useState } from "react";
import Routes from "./components/Routes";
import { UidContext } from "./components/AppContext";
import axios from "axios";
import { useDispatch } from "react-redux";
import { fetchOrUpdateUser } from "./features/user";
import { fetchOrUpdateUserBilans } from "./features/bilan";
import Loading from "./components/Admin/Loading";
import { setAuthToken } from "./components/Utils";
import Cookies from "js-cookie";


let didInit = false;

export default function App() {
  const [uid, setUid] = useState(null); //null
  const [pl, setPl] = useState(0) //0
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [user, setUser] = useState(false)



  useEffect(() => {

    async function fetchUser(uid) {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/user/${uid}`, { withCredentials: true })
      const data = await response.data
      if (data.permissionLevel) setPl(data.permissionLevel)
      dispatch(fetchOrUpdateUser(data))
      setUser(true)
    }

    const fetchToken = async () => {
      const token = Cookies.get('token')
      if (token) {
        setAuthToken(token);
        await axios({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}jwtid`,
          withCredentials: true,
        })
          .then((res) => {
            setUid(res.data)
          })
          .catch((err) => {
            console.log("No token")
            setIsLoading(false)
          })
      } else {
        console.log("No token")
        setIsLoading(false)
      }
    };

    if (!didInit) {
      didInit = true;
      fetchToken();
    }

    if (uid) {
      fetchUser(uid)
      dispatch(fetchOrUpdateUserBilans(uid))
      user && setIsLoading(false)
    }
  }, [uid, dispatch, user])


  return isLoading ? <Loading /> : (
    <UidContext.Provider value={{ uid, isLoading, pl }}>
      <Routes />
    </UidContext.Provider>
  )
}

