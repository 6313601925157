import { createAction, createReducer } from "@reduxjs/toolkit";
import axios from "axios"

export const getUserAdmin = createAction('GET_USER_ADMIN')
export const addDietAdmin = createAction('ADD_DIET_ADMIN')
export const addDocAdmin = createAction('ADD_DOC_ADMIN')
export const deleteDietAdmin = createAction('DELETE_DIET_ADMIN')
export const deleteDocAdmin = createAction('DELETE_DOC_ADMIN')
export const addSuiviAdmin = createAction('ADD_SUIVI_ADMIN')
export const addCheckUpAdmin = createAction('ADD_CHECKUP_ADMIN')
export const getUserDietsAdmin = createAction('GET_USER_DIETS')

export function fetchOrUpdateUserAdmin (uid) {
    return async (dispatch) => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/user/${uid}`, { withCredentials: true })
        const data = await response.data
        dispatch(getUserAdmin(data))
    }
}

export function uploadDiet (lastDiet) {
    return async (dispatch) => {
         dispatch(addDietAdmin(lastDiet))
    }
}

export function uploadDoc (lastDiet) {
    return async (dispatch) => {
         dispatch(addDocAdmin(lastDiet))
    }
}

export function deleteDiet (userId, data){
    return async (dispatch) => {
        console.log('deleteDiet data : ' + data);
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}api/user/diet/${userId}`, data, { withCredentials: true });
        if (res.status === 200 ) alert('Diet supprimée')
        dispatch(deleteDietAdmin(data))
    }
}

export function deleteDoc (data){
    return async (dispatch) => {
        dispatch(deleteDocAdmin(data))
    }
}

export function updateSuivi (formula) {
    return async (dispatch) => {
         dispatch(addSuiviAdmin(formula))
    }
}

export function updateNextCheckUp (formula) {
    return async (dispatch) => {
         dispatch(addCheckUpAdmin(formula))
    }
}

export function errorUploadDiet (error){
    return(dispatch) => {
        console.log('ERR DIET');
    }
}
export function fetchDietUser (data) {
    return async (dispatch) => {
            dispatch(getUserDietsAdmin(data))
    }
}

const initialState = {
    status : "",
    data : {
        _id : null,
        name : null,
        firstName : null,
        formula : null,
        diets : null,
        documents : null,
        dietsFMD: null
    }
};

export default createReducer(initialState, (builder) => 
    builder
    .addCase(getUserAdmin, (draft, action) => {
        const dietsCopy = [...action.payload.diets]
        const dietsReverse = dietsCopy.reverse() 

        draft.data._id = action.payload._id
        draft.data.name = action.payload.name
        draft.data.weight = action.payload.weight
        draft.data.alimOk = action.payload.foodOk
        draft.data.alimNok = action.payload.foodNok
        draft.data.name = action.payload.name
        draft.data.firstName = action.payload.firstName
        draft.data.formula = action.payload.formula
        draft.data.nextCheckUp = action.payload.nextCheckUp
        draft.data.diets = dietsReverse
        draft.data.documents = action.payload.documents
        return
    })  
    .addCase(addDietAdmin, (draft, action) => {
        draft.data.diets.push(action.payload)
        return
    })
    .addCase(addDocAdmin, (draft, action) => {
        draft.data.documents.push(action.payload)
        return
    })
    .addCase(deleteDietAdmin,  (draft, action) => {
        draft.data.diets = draft.data.diets.filter((diet) => diet._id !== action.payload.dietId)
        return
    })
    .addCase(deleteDocAdmin,  (draft, action) => {
        draft.data.documents = draft.data.documents.filter((doc) => doc._id !== action.payload.docId)
        return
    })
    .addCase(addSuiviAdmin, (draft, action) => {
        draft.data.formula = action.payload
        return
    })
    .addCase(addCheckUpAdmin, (draft, action) => {
        draft.data.nextCheckUp = action.payload
        return
    })
    .addCase(getUserDietsAdmin, (draft, action) => {
        draft.data.dietsFMD = action.payload
        return
    })  
)

