import styled from 'styled-components'
import { Link } from 'react-router-dom'
import colors from './colors'

const MainWidgetTitle = styled.h2`
        font-size: 2rem;
        color: #f6f6f6;
        font-family: Alata, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Oxygen, Cantarell, sans-serif;
    `

    const MainWidgetDiv = styled(Link)`
        display: flex;
        justify-content: center;
        align-items: center;
        
        background-size: 100% auto; 
        background-position: center;
        text-decoration: none;
        width: 100%;
        height: 120px;
        position: relative;
        overflow: hidden;
        margin-bottom: 10px;

        &:after{
            content:'';
            display: block;
            position: absolute;
            top: 0px;
            left:0px;
            width: 100%;
            height: 100%;
            background-color: ${colors.whiteCover};
        }

        @media (min-width: 780px) {
            width: 48%;
            height: 150px;
        }

    `


export default function MainWidget(props){
    const {pic, link, title} = props


    var style = {
        backgroundImage: `url(${pic})`
      };


    

    return(
        <MainWidgetDiv to={link} style={style} >
            <MainWidgetTitle>{title}</MainWidgetTitle>
        </MainWidgetDiv>
    )
}