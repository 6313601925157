import axios from "axios"
import { useState } from "react"
import { useEffect } from "react"
import { Link } from 'react-router-dom'
import styled from "styled-components"

import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import colors from "../UIKit/colors";
import {  fetchOrUpdateBilans } from "../../features/admin"
import { useDispatch, useSelector } from "react-redux"

const ListeBilanWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    background-color: white;
    max-height: 300px;
    overflow-y: scroll;
    margin-bottom : 100px;

  @media (min-width: 780px) {
    width: 48%;
    max-height: 70vh;
    margin-bottom : 50px;
  }  
`

const LinkBilan = styled(Link)`
    width : 90%;
    border-bottom: 1px solid ${colors.beige};
    padding: 0.5rem 0px;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
`
const InfoBilan = styled.div`

`
const Arrow = styled(HiOutlineArrowNarrowRight)`
    color: #d5d5d5;
`
const LoadingWrapper = styled.div`
    position: relative;
    margin: auto;
`
const SecondTitle = styled.div`
    color : ${colors.grey};
    font-size: 1.6rem;
    width: 100%;
    text-align: center;
    padding: 0.5rem 0px;
`
export default function BilansList(){
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch()
    const allBilans = useSelector((state) => state.adminData.bilans)

    useEffect(() => {
        async function fetchBilans() {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}api/bilan/admin/getBilans`, { withCredentials: true })
            const data = await res.data
            dispatch(fetchOrUpdateBilans(data))
            setIsLoading(false)

        }
        fetchBilans()

    }, [dispatch])

    function formateDate(oldDate) {
        const date = new Date(oldDate)
        const day = date.getDate()
        const month = date.getMonth() + 1
        const result = day + " / " + month

        return result
    }

    return isLoading ? <LoadingWrapper>
    <div className="lds-ripple-s" >
                        <div></div>
                        <div></div>
                    </div>
</LoadingWrapper>  : (
        <ListeBilanWrapper>
            <SecondTitle>Bilans</SecondTitle>
                {allBilans?.map((bilan, index) => (
                    <LinkBilan to={"/admin/bilan/" + bilan._id} key={index}>
                        <InfoBilan>
                            {formateDate(bilan.createdAt)}
                        </InfoBilan>
                        <InfoBilan>
                            {bilan.userPseudo}
                        </InfoBilan>
                        <Arrow />
                    </LinkBilan>
                )
                )}
            </ListeBilanWrapper>
    )
}