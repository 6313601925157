import React, { useState } from "react"
import axios from "axios"
import SignInForm from "./SignInForm";

import styled from 'styled-components';
import colors from '../UIKit/colors';

const Wrapper = styled.div`
  position: relative;
  margin-top: 10px;
`;

const Input = styled.input`
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 300px;
  border: none;
  border-bottom: 1px solid #757575;
  &:focus {
    outline: none;
    border-bottom: 2px solid ${colors.beige};
  }
  &::placeholder{
    color: transparent;
  }
`;

const Label = styled.label`
  z-index: 2;
  color: #999;
  font-size: 1rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  ${Input}:focus ~ & {
    top: -24px;
    font-size: 1.2rem;
    color: ${colors.beige};
  }
  ${Input}:not(:placeholder-shown) ~ & {
    top: -24px;
    font-size: 1.2rem;
    color: ${colors.beige};
  }
`;

const ChekboxInput = styled.input`
  position: relative;
  overflow: hidden;
  appearance: none;
  background-color: transparent;
  margin: 0;
  font: inherit;
  color: ${colors.darkBrown};
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid ${colors.darkBrown};
  border-radius: 0.15em;

  &::after{
    content: "";
    display: block;
    background-color: ${colors.beige};
    height: 0.7em;
    width: 0.7em;
    position: absolute;
    left: 50%;
    margin-left: -0.35em;
    top: 50%;
    margin-top: -0.35em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
}

&:checked::after {
    transform: scale(1);
  }

`

const SubmitBtn = styled.input`
  background-color: ${colors.beige};
  border: unset;
  padding: 5px 10px;
  font-size: 1.1rem;
  color: ${colors.darkBrown};
  margin: auto;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top:50px;
`
const PassErrorConf = styled.div`
  color: #ff6961;
`
const SignUpForm = (props) => {
    const { setIsLoading } = props
    const [formSubmit, setFormSubmit] = useState(false);
    const [pseudo, setPseudo] = useState("");
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [controlPassword, setControlPassword] = useState("");

    const handleRegister = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        const terms = document.getElementById("terms");
        const pseudoError = document.querySelector(".pseudo.error");
        const emailError = document.querySelector(".email.error");
        const passwordError = document.querySelector(".password.error");
        const passwordConfirmError = document.querySelector(".password-confirm.error");
        const termsError = document.querySelector(".terms.error");
        const passwordConf = document.getElementById('password-conf')

        passwordConfirmError.innerHTML = "";
        termsError.innerHTML = "";

        if (password !== controlPassword/*  || !terms.checked */) {
            if (password !== controlPassword) {
                passwordConf.style.borderBottom = "2px solid #ff6961"
                passwordConf.style.backgroundColor = "#ff69615e"
                passwordConfirmError.innerHTML =
                    "Les mots de passe ne correspondent pas";
            }
            /* if (!terms.checked)
                termsError.innerHTML = "Veuillez valider les conditions générales"; */
            setIsLoading(false)
        } else {
            await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}api/user/register`,
                data: {
                    pseudo,
                    email,
                    firstName,
                    name,
                    password,
                },
            })
                .then((res) => {
                    setIsLoading(false)
                    console.log(res);
                    if (res.status === 201) {
                        setFormSubmit(true);
                    }
                })
                .catch((err) => {
                    if (err.response.data) {
                        pseudoError.innerHTML = err.response.data.errors.pseudo
                        emailError.innerHTML = err.response.data.errors.email
                        passwordError.innerHTML = err.response.data.errors.password
                    } else {
                        termsError.innerHTML = "Malheureusement une erreur s'est produite. Merci de réessayer plus tard."
                    }
                    setIsLoading(false)
                });
        }
    }

    return (
        <>
            {formSubmit ? (
                <>
                    <SignInForm setIsLoading={setIsLoading} />
                    <span></span>
                    <h4 className="success">
                        Enregistrement réussi, veuillez-vous connecter
                    </h4>
                </>
            ) : (
                <Form action="" onSubmit={handleRegister} id="sign-up-form">
                    <Wrapper>
                        <Input
                            type="text"
                            name="firstName"
                            id="firstName"
                            placeholder="Prénom"
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName}
                        />
                        <Label htmlFor="pseudo">Prénom</Label>
                    </Wrapper>
                    <br />
                    <Wrapper>
                        <Input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Nom"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                        <Label htmlFor="pseudo">Nom</Label>
                    </Wrapper>
                    <br />
                    <Wrapper>
                        <Input
                            type="text"
                            name="pseudo"
                            id="pseudo"
                            placeholder="Pseudo"
                            onChange={(e) => setPseudo(e.target.value)}
                            value={pseudo}
                        />
                        <Label htmlFor="pseudo">Pseudo</Label>
                    </Wrapper>
                    <br />
                    <div className="pseudo error"></div>
                    <Wrapper>
                        <Input
                            type="text"
                            id="email"
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            placeholder="email"
                            key={`inputEmail`}
                        />
                        <Label htmlFor="email">Email</Label>
                    </Wrapper>
                    <div className="email error"></div>
                    <br />
                    <Wrapper>
                        <Input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            key={`inputPass`}
                        />
                        <Label htmlFor="password">Mot de passe</Label>
                    </Wrapper>
                    <div className="password error"></div>
                    <br />
                    <Wrapper id='password-conf-wrapper'>
                        <Input
                            type="password"
                            id="password-conf"
                            name="password-conf"
                            placeholder="Confirmer mot de passe"
                            onChange={(e) => setControlPassword(e.target.value)}
                            value={controlPassword}
                            key={`inputPass`}
                        />
                        <Label htmlFor="password-conf">Confirmer mot de passe</Label>
                    </Wrapper>

                    <PassErrorConf className="password-confirm error"></PassErrorConf>
                    <br />
                    {/* <div>
                        <ChekboxInput type="checkbox" id="terms" />
                        <label htmlFor="terms">
                            J'accepte les{" "}
                            <a href="/" target="_blank" rel="noopener noreferrer">
                                conditions générales
                            </a>
                        </label>
                    </div> */}
                    <div className="terms error"></div>
                    <br />
                    <SubmitBtn type="submit" value="Valider inscription" />
                </Form>
            )}
        </>
    )

}

export default SignUpForm;