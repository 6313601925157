import React, { useState } from "react";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";
import logo from "../../images/logo.png"
import styled from "styled-components";
import colors from "../UIKit/colors";

import Loading from "./Loading";

const LogDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100%;
`

const Logo = styled.img`
    margin: 2rem auto;
    width: 150px;
`

const NavContainerUl = styled.ul`
  background-color: rgba(219,146,187,0.3);
  margin-top : 10px;
    list-style:none;
    display: flex;
    gap: 1rem;
    justify-content: space-around;
    border-radius: 30px;
    overflow: hidden;

    & .activeLog{
      background-color: ${colors.pink};
    }
`

const NavLogBtn = styled.li`
    color: white;
    padding: 5px 30px;
    border-radius: 30px;
  `
  const FormContainer = styled.div`
    position: relative;
  `

const Log = (props) => {
  const [signUpModal, setSignUpModal] = useState(props.signup);
  const [signInModal, setSignInModal] = useState(props.signin);

  const [isLoading, setIsLoading] = useState(false)

  const handleModals = (e) => {
    if (e.target.id === "register") {
      setSignInModal(false);
      setSignUpModal(true);
    } else if (e.target.id === "login") {
      setSignUpModal(false);
      setSignInModal(true);
    }
  };

  return (
      <LogDiv>
        <NavContainerUl>
          <NavLogBtn
            onClick={handleModals}
            id="register"
            className={signUpModal ? "activeLog" : null}
          >
            S'inscrire
          </NavLogBtn>
          <NavLogBtn
            onClick={handleModals}
            id="login"
            className={signInModal ? "activeLog" : null}
          >
            Se connecter
          </NavLogBtn>
        </NavContainerUl>
        <Logo src={logo} alt="Logo donuts musclé" />
        <FormContainer>
          {isLoading && <Loading />}
          {signUpModal && <SignUpForm setIsLoading={setIsLoading} />}
          {signInModal && <SignInForm setIsLoading={setIsLoading} justValided={props.justValided} />}
        </FormContainer>
      </LogDiv>
  );
};

export default Log;