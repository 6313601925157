import axios from "axios"
import { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";
import styled from "styled-components"
import Loading from "../components/Admin/Loading";
import colors from "../components/UIKit/colors";

const Wrapper = styled.div`
    min-height: 100vh;
`

const Diet = styled.div`
    margin: auto;
    margin-bottom: 100px;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items; center;
    position: relative;
`
const DietTitle = styled.div`
    margin: 1rem auto;
    text-align: center;
    font-size: 1.5rem;

`
const Meal = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items; center;
    border : 2px solid ${colors.beige};
    border-radius : 1rem;
    overflow: hidden;
    margin: 0.5rem 0px;

    & .MealInfos{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: ${colors.beige};
        color : white;
        font-size : 1.5rem;
        padding : 0.2rem 0.5rem;
    }

    &:nth-child(odd) {
        border : 2px solid ${colors.cyan};
    }

    &:nth-child(odd) .MealInfos{
        background-color: ${colors.cyan};
    }
`
const TotalMaccros = styled.div`
    justify-content: center;
    display: flex;
    gap: 1rem;
    border-radius: 1rem;
    overflow: hidden;
    background-color: white;
    width: 100%;
    margin: 0 auto 1rem;
`
const Maccro = styled.div`

    padding: 0.5rem 1rem; 
    border-left: 1px solid ${colors.beige};
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;

    &:nth-child(1){
        border-left: none; 
    }

    &.MaccroCal{
        background-color: white;
    }

    & label{
        color: rgba(155,155,155,1);
    }
`
const MealName = styled.div`

`
const Ingredient = styled.div`
    display: flex;
    align-items: center;
    justify-content : flex-end;
    margin : 0.5rem;
`
const IngredientDemo = styled.div`
    display: flex;
    align-items; center;
    justify-content : flex-end;
    margin : 0.5rem;
    color: rgb(100,100,100);
    border-bottom: 1px solid ${colors.beige};

    &.total{
        border-bottom: unset;
        border-top: 1px solid ${colors.beige};
    }
`
const IngredientName = styled.div`
    display: flex;
    flex: 1;
    word-break: break-word;
`
const IngredientData = styled.div`
    width : 15%;
    text-align: center;

    & span{
        color: rgba(1,1,1,0.6);
        font-size: 0.9rem;
    }
`
const OptionsRow = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin: 0.2rem 0px;
    font-size: 2rem;
    color: ${colors.grey};
`
const BtnRecpe = styled.div`

`
const Recipe = styled.div`
    color: rgb(100,100,100);
    margin: 0.5rem;

    & h3{
        margin-bottom: 0.5rem;
    }
`

export default function DietDisplay() {
    const [showRecipe, setShowRecipe] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [diet, setDiet] = useState(null)
    let { dietId } = useParams();

    const fetchDiet = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}api/diet/getOneDiet/${dietId}`, { withCredentials: true })
        const data = await res.data[0]
        console.log(res);
        console.log(data);
        setDiet(data)
        setIsLoading(false)
    }
    if (diet === null) fetchDiet()

    if (isLoading) return <Loading />

    let cal = 0
    diet?.meals.forEach(meal => {
        meal.ingredients.forEach(ingredient => {
            cal += ingredient.cal * ingredient.quantity / 100
        });
    });

    let prot = 0
    diet.meals.forEach(meal => {
        meal.ingredients.forEach(ingredient => {
            prot += ingredient.prot * ingredient.quantity / 100
        });
    });
    let carbs = 0
    diet.meals.forEach(meal => {
        meal.ingredients.forEach(ingredient => {
            carbs += ingredient.carb * ingredient.quantity / 100
        });
    });
    let fat = 0
    diet.meals.forEach(meal => {
        meal.ingredients.forEach(ingredient => {
            fat += ingredient.fat * ingredient.quantity / 100

        });
    });
    return (
        <Wrapper>
            <Diet>
                <DietTitle>{diet?.title}</DietTitle>
                <TotalMaccros>
                    <Maccro className="MaccroCal">
                        <span>{parseFloat((cal).toFixed(1))}</span>
                        <label>Kcal</label>
                        </Maccro>
                    <Maccro>
                        <span>{parseFloat((prot).toFixed(1))}</span>
                        <label>Prot</label>
                    </Maccro>
                    <Maccro>
                        <span>{parseFloat((carbs).toFixed(1))}</span>
                        <label>Glu</label>
                    </Maccro>
                    <Maccro>
                        <span>{parseFloat((fat).toFixed(1))}</span>
                        <label>Gra</label>
                    </Maccro>
                </TotalMaccros>
                {diet?.meals.map((meal, index) => {
                    let cal = 0
                    meal.ingredients.forEach(ingredient => {
                        cal += ingredient.cal * ingredient.quantity / 100
                    });

                    let prot = 0
                    meal.ingredients.forEach(ingredient => {
                        prot += ingredient.prot * ingredient.quantity / 100
                    });

                    let carbs = 0
                    meal.ingredients.forEach(ingredient => {
                        carbs += ingredient.carb * ingredient.quantity / 100
                    });
                    
                    let fat = 0
                    meal.ingredients.forEach(ingredient => {
                        fat += ingredient.fat * ingredient.quantity / 100

                    });
                    return (
                        <Meal key={index}>
                            <div className="MealInfos">
                                <MealName>{meal.name}</MealName>
                                <OptionsRow>
                                    <BtnRecpe onClick={() => setShowRecipe(!showRecipe)}>< FaInfoCircle /></BtnRecpe>
                                </OptionsRow>
                            </div>
                            <IngredientDemo>
                                <IngredientName></IngredientName>
                                <IngredientData>Qtt</IngredientData>
                                <IngredientData>Kcal</IngredientData>
                                <IngredientData>Prot</IngredientData>
                                <IngredientData>Glu</IngredientData>
                                <IngredientData>Gra</IngredientData>
                            </IngredientDemo>
                            {meal.ingredients?.map((ingredient, index) => {
                                return <Ingredient key={index}>
                                    <IngredientName>{ingredient.name}</IngredientName>
                                    {
                                        !ingredient.number || ingredient.number === "" || ingredient.number === " " ?
                                            <IngredientData>{ingredient.quantity}gr</IngredientData>
                                        :
                                            <IngredientData>{ingredient.number} <span>({ingredient.quantity}gr)</span></IngredientData>
                                    }
                                    <IngredientData>{ingredient.cal * ingredient.quantity / 100}</IngredientData>
                                    <IngredientData>{ingredient.prot * ingredient.quantity / 100}</IngredientData>
                                    <IngredientData>{ingredient.carb * ingredient.quantity / 100}</IngredientData>
                                    <IngredientData>{ingredient.fat * ingredient.quantity / 100}</IngredientData>
                                </Ingredient>
                            })}
                            <IngredientDemo className="total">
                                <IngredientName>TOTAL</IngredientName>
                                <IngredientData> </IngredientData>
                                <IngredientData>{parseFloat((cal).toFixed(1))}</IngredientData>
                                <IngredientData>{parseFloat((prot).toFixed(1))}</IngredientData>
                                <IngredientData>{parseFloat((carbs).toFixed(1))}</IngredientData>
                                <IngredientData>{parseFloat((fat).toFixed(1))}</IngredientData>
                            </IngredientDemo>
                            {showRecipe &&
                                <Recipe>
                                    <h3>Recette :</h3>
                                    <>
                                        <p>
                                            {meal.recipe}
                                        </p>
                                    </>
                                </Recipe>}
                        </Meal>
                    )
                })}
            </Diet>
        </Wrapper>
    )
}