import axios from "axios"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { addNewIngredient } from "../../../../features/dietCreator"

const MealsContainer = styled.div`
    width: 95%;
    max-height: 50vh;
    overflow-y: scroll;
`
const Meal = styled.div`
   padding: 0.5rem 0px;

`

export default function MealList({setModale}){
    const [meals, setMeals] = useState(false)
    const dispatch = useDispatch()
    const selectedMeal = useSelector((state) => state.dietCreator.selectedMeal)


    useEffect(()=>{
        fetchMeals()

        async function fetchMeals(){
            const res = await axios.get(`${process.env.REACT_APP_API_URL}api/diet/getAllMeals`,  { withCredentials: true })
            if (res.status === 200) {
                const data = res.data
                if (data.length > 0){
                    setMeals(data)
                    console.log(data);
                }
            } else {
                console.log('fail');
            }
        }
    },[])

    const handleAddMeal = (meal) => {
        const ingredients = meal.ingredients
        const index = selectedMeal

        ingredients.forEach(element => {
            dispatch(addNewIngredient({
                'index': index,
                'ingredient': {
                    name: element.name,
                    quantity: element.quantity,
                    cal: element.cal,
                    fat: element.fat,
                    carb: element.carb,
                    prot: element.prot,
                }
            }))
        });
        setModale(false)
    }

    return meals ? (
        <MealsContainer>
        {meals?.map((meal, index) => {
            return <Meal onClick={() => handleAddMeal(meal)} key={index}>
                <span>{meal.name}</span>
            </Meal>
        })}
        </MealsContainer>
    ) : (
        <>No meal</>
    )
}