import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components'
import colors from "../../UIKit/colors";
import { useEffect } from "react";
import { useState } from "react";
import { deleteDoc } from "../../../features/selectedUser";
import { AiOutlineDelete } from "react-icons/ai";
import axios from "axios";
import Loading from "../Loading";

const ListeBilanWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    background-color: white;
    max-height: 170px;
    overflow-y: scroll;
    border : 2px solid ${colors.beige};
    position: relative;
`
const DietDiv = styled.div`
    width : 90%;
    display : flex;
    justify-content: space-between;
    align-items:center;
    border-bottom: 1px solid ${colors.beige};
    margin-top: 1rem;
    font-size:1.7rem;
`
const LinkBilan = styled.a`
    display: flex;
    flex:1;

`
const InfoBilan = styled.div`
    font-size:1.2rem;
`

const Delete = styled.div`
    
`
const SecondTitle = styled.div`
    color : ${colors.beige};
    font-size: 1.6rem;
    width: 90%;
    padding: 0.5rem 0px 0px;
`



export default function DocsListUser({ userId }) {
    const dispatch = useDispatch()
    const userDocs = useSelector((state) => state.selectedUser.data.documents)
    const [docLinks, setDocLinks] = useState([])
    const [isLoading, setIsLoading] = useState(true)



    const handleDelete = async (docId, docLink) => {
        setIsLoading(true)
        const data = {
            "docId": docId,
            "docLink": docLink
        }
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}api/user/doc/${userId}`, data, { withCredentials: true });
        if (res.status !== 200) {
            alert('Failed to delete doc')
        } else {
            dispatch(deleteDoc(data))
            setIsLoading(false)
        }
    }
    
    useEffect(() => {
        let links = []
        async function loadLinks(array) {
            if (array) {
                await array.forEach(element => {
                    links.push(element.docLink)
                });
            

                const res = await axios.post(`${process.env.REACT_APP_API_URL}api/bilan/images`, { keys: links }, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true,
                })
                if (res.status === 201) {
                    const data = res.data
                    setDocLinks(data)

                }
            }
        }
        loadLinks(userDocs)
        setIsLoading(false)
    }, [userDocs])

    return (
        <ListeBilanWrapper>
            {isLoading && <Loading />}
            <SecondTitle>Documents</SecondTitle>
            {userDocs?.map((doc, index) => {
                return (
                    <DietDiv key={index}>
                        <LinkBilan target="_blank" href={docLinks[index]}>
                            <InfoBilan>
                                {doc.docName}
                            </InfoBilan>
                        </LinkBilan>
                        <Delete className="delete-icon" onClick={() => handleDelete(doc._id, doc.docLink)}>
                            <AiOutlineDelete />
                        </Delete>
                    </DietDiv>
                )
            })}
        </ListeBilanWrapper>
    )

}