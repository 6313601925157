import { useSelector } from "react-redux";
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import colors from "../UIKit/colors";
import { useContext, useEffect } from "react";
import { UidContext } from "../AppContext";
import { useDispatch } from "react-redux";
import { fetchOrUpdateUserBilans } from "../../features/bilan";
import styled from 'styled-components'
import { useState } from "react";
import { Link } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const EmptyBilans = styled.div`
  width : 90%;
  margin: auto;
  text-align:center;
  color: ${colors.cyan}
`
const Wrapper = styled.div`
  width:100%;
  max-width : 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
`
const LinkSuiviContainer = styled.div`
  display: flex;
  justify-content: center;
`
const LinkSuivi = styled(Link)`
  background-color: ${colors.cyan};
  color: white;
  padding : 0.5rem 1rem;
  margin: 1rem;
`
const TitleWidget = styled.span`
  background-color : ${colors.cyan};
  color : white;
  font-size : 1.2rem;
  padding : 0.3em;
  text-align: center;
`
const LineContainer = styled.div`
  width: 90%;
  margin: 1rem auto 0px;
`
const Select = styled.select`
  background-color: unset;
  border: unset;
  color : white;
  font-size : 1.2rem;

  &:focus{
    outline:unset;
  }

  &>*{
    color:black;
  }
`


export default function Tracking() {
  const bilans = useSelector((state) => state.bilans);
  const { uid } = useContext(UidContext)
  const dispatch = useDispatch();
  const [dataSelected, setDataSelected] = useState('weight')


  useEffect(() => {
    if (uid) {
      dispatch(fetchOrUpdateUserBilans(uid))
    }
  }, [uid, dispatch])

  let userWeights = []

  switch (dataSelected) {
    case 'chest':
      userWeights = []
      for (let index = 0; index < bilans.length; index++) {
        const bilan = bilans[index]
        userWeights.push(bilan.chest)
      }
      break;
    case 'waist':
      userWeights = []
      for (let index = 0; index < bilans.length; index++) {
        const bilan = bilans[index]
        userWeights.push(bilan.waist)
      }
      break;
    case 'thigh':
      userWeights = []
      for (let index = 0; index < bilans.length; index++) {
        const bilan = bilans[index]
        userWeights.push(bilan.thigh)
      }
      break;
    case 'arm':
      userWeights = []
      for (let index = 0; index < bilans.length; index++) {
        const bilan = bilans[index]
        userWeights.push(bilan.arm)
      }
      break;
    case 'glutes':
      userWeights = []
      for (let index = 0; index < bilans.length; index++) {
        const bilan = bilans[index]
        userWeights.push(bilan.glutes)
      }
      break;
    case 'calves':
      userWeights = []
      for (let index = 0; index < bilans.length; index++) {
        const bilan = bilans[index]
        userWeights.push(bilan.calves)
      }
      break;

    default:
      userWeights = []
      if (bilans.length > 0){
        console.log('yes');
        for (let index = 0; index < bilans.length; index++) {
          const bilan = bilans[index]
          userWeights.push(bilan.weight)
        }
      }
      break;
  }


  const options = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: '',
      },
      legend: {
        display: false
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        }
      },
      y: {
        grid: {
          display: false,
          drawBorder: false
        }
      }
    }
  };
  let labels = []
  for (let i = 0; i < userWeights.length; i++) {
    const index = i + 1
    labels.push(index)
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'Data',
        data: userWeights,
        borderColor: colors.cyan,
        backgroundColor: 'white',
      }
    ],
  }

  const optionsSelect = [
    {
      label: "du poids",
      value: "weight",
    },
    {
      label: "de la taille",
      value: "waist",
    },
    {
      label: "de la poitrine",
      value: "chest",
    },
    {
      label: "de la poitrine",
      value: "thigh",
    },
    {
      label: "du bras",
      value: "arm",
    },
    {
      label: "des fesses",
      value: "glutes",
    },
    {
      label: "du mollet",
      value: "calves",
    },
  ];


  if (!userWeights) {
    return <EmptyBilans>Publie tes premier bilans pour tracer ton évolution</EmptyBilans>
  }

  return userWeights.length === 0 ?
    (
      <EmptyBilans>Publie tes premier bilans pour tracer ton évolution</EmptyBilans>
    ) :
    (
      <Wrapper>
        <TitleWidget>
          Evolution
          <Select onChange={(e) => setDataSelected(e.target.value)}>
            {optionsSelect.map((option, index) => (
              <option key={index} value={option.value}>{option.label}</option>
            ))}
          </Select>
        </TitleWidget>
        <LineContainer>
          <Line options={options} data={data} />
        </LineContainer>
        
      </Wrapper>
    )

}






