import { useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import AddNewIngredient from "../components/Admin/diet/modaleMenu-content/AddNewIngredient"
import colors from "../components/UIKit/colors"
import TitlePage from "../components/UIKit/title/titlePage"

const Wrapper = styled.div`
    width : 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
`
const WrapAddNewIngredient = styled.div`
    max-width: 95%;
    overflow-x: hidden;
    border-radius: 1rem;
    padding 1rem 0.5rem;
`
const DoubleBtn = styled.div`
    margin: auto;
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin-bottom:1rem;

    &>div{
        background-color: ${colors.beige};
    }

    &>div:nth-child(2){
        background-color: ${colors.pink};
    }
`
const BtnWrapper = styled.div`
    width: 48%;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const BtnLink = styled(Link)`
    text-align: center;
    padding: 0.3em 1em;
    font-size: 1.5rem;
    color: white; 
    width: 100%;

    &:nth-child(2){
        background-color: ${colors.pink};
    }
`

export default function DietAdmin() {
    const [addNewIngredient, setAddNewIngredient] = useState(false)

    return (
        <Wrapper>
            <TitlePage title="Diet Manager" />
            <DoubleBtn>
                <BtnWrapper>
                    <BtnLink to="/admin/newDiet">Diets</BtnLink>
                </BtnWrapper>
                <BtnWrapper>
                    <BtnLink to="/admin/recipe">Recettes</BtnLink>
                </BtnWrapper>
            </DoubleBtn>
            <div onClick={() => setAddNewIngredient(true)}>Enregistrer un aliment</div>
            {addNewIngredient &&
                <WrapAddNewIngredient>
                    <AddNewIngredient setAddNewIngredient={setAddNewIngredient} />
                </WrapAddNewIngredient>
            }
        </Wrapper>
    )
}