import styled from "styled-components"
import "../../styles/index.css"

export default function Loading(){
    const Wrapper = styled.div`
        position: absolute;
        width:100%;
        height:100%;
        background-color: white;
        z-index:2;
        display: flex;
        justify-content: center;
        align-items: center;
    `
    return(
        <Wrapper>
            <div className="lds-ripple" >
                    <div></div>
                    <div></div>
                </div>
        </Wrapper>
    )
}