import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../features/user'
import bilanReducer from '../features/bilan'
import selectedUser from '../features/selectedUser'
import selectedBilans from '../features/selectedBilans'
import admin from '../features/admin'
import dietCreator from '../features/dietCreator'
import trainingCreator from '../features/trainingCreator'
import recipeCreator from '../features/recipeCreator'

export default configureStore({
    reducer: {
        user: userReducer,
        bilans: bilanReducer,
        selectedUser: selectedUser,
        selectedBilans : selectedBilans,
        adminData : admin,
        dietCreator : dietCreator,
        trainingCreator : trainingCreator,
        recipeCreator : recipeCreator
    },
})