import axios from "axios"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import colors from "../components/UIKit/colors"
import { changeStartWeight } from "../features/user"

const Wrapper = styled.div`
    width : 100%;
    overflow-x: hidden;
`
const Form = styled.form`
    margin: auto;
    width : 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size : 1.2rem;
`
const TextQuestion = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${colors.beige};

    & input {
        border : unset;
        background-color : rgba(150,150,150,0.2);
        font-size : 1.2rem;
        padding : 0.5rem 1rem;
    }

    & label span {
        color : ${colors.beige}
    }
`
const SelectQuestion = styled.div`
    display: flex;
    flex-direction: column;
    gap:1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${colors.beige};

    & span {
        color: grey;
    }
`
const OptionsContainer = styled.div`
    display: flex;
    justify-content: space-around;
    margin : 0.5rem 0px;
`
const Submit = styled.input`
    background-color: ${colors.beige};
    display: inline-block;
    padding: 8px 24px;
    font-size: 2rem;
    color: #f6f6f6;
    margin-bottom: 2rem;
    border : unset;
`

const DivStatus = styled.div`
    position : fixed;
    top:0px;
    width: 100vw;
    height: 100vh;
    background-color: ${colors.pink};
    color: white;
    right: 0;
    text-align: center;
    z-index: 3;
    justify-content: center;
    align-items: center;
    display: none;
    flex-direction:column;

`

const LoadingWrapper = styled.div`
    position: absolute;
    width:100%;
    height:100%;
    z-index:2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    & .lds-ripple div {
        position: absolute;
        border: 4px solid white;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1.2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }
`


export default function Formulaire() {
    const [weight, setWeight] = useState(null)
    const [age, setAge] = useState(null)
    const [height, setHeight] = useState(null)
    const [allergies, setAllergies] = useState(null)
    const [medicalTreatment, setMedicalTreatment] = useState(null)
    const [injuries, setInjuries] = useState(null)
    const [foodHabits, setFoodHabits] = useState(null)
    const [water, setWater] = useState(null)
    const [schedule, setSchedule] = useState(null)
    const [activityLevel, setActivityLevel] = useState(2)
    const [trainingsWeek, setTrainingsWeek] = useState(null)
    const [trainingTime, setTrainingTime] = useState(null)
    const [goal, setGoal] = useState(null)

    const [isLoading, setIsLoading] = useState(false)
    const userData = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const Options = styled.div`
    display: flex;
    flex:1;
    justify-content: center;
    border-left : 1px solid black;

    &:nth-child(${activityLevel + 1}) {
        background-color: rgba(237, 207, 176, 0.3);
    }
    &:nth-child(5) {
        border-right : 1px solid black;
    }
    `

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (userData._id && weight && age && height && foodHabits && schedule && activityLevel) {
            setIsLoading(true)
            const statusBilanSelected = document.getElementById("statusForm")
            const statusBilanMessage = document.getElementById("statusFormMessage")

            statusBilanSelected.style.display = "flex"
            const data = {
                'userId': userData._id,
                'userPseudo': userData.pseudo,
                'weight': weight,
                'age': age,
                'height': height,
                'allergies': allergies,
                'medicalTreatment': medicalTreatment,
                'injuries': injuries,
                'foodHabits': foodHabits,
                'water': water,
                'schedule': schedule,
                'activityLevel': activityLevel,
                'trainingsWeek': trainingsWeek,
                'trainingTime': trainingTime,
                'goal': goal,
            }

            await axios
                .post(`${process.env.REACT_APP_API_URL}api/user/form`, data, { withCredentials: true })
                .then(async (res) => {
                    if (res.status === 201) {
                        const dataWeight = {
                            weight: weight
                        }
                        const resWeight = await axios.patch(`${process.env.REACT_APP_API_URL}api/user/startWeight/${userData._id}`, dataWeight, { withCredentials: true })
                        if (resWeight.status === 201) {
                            setIsLoading(false)
                            statusBilanMessage.innerHTML = "Formulaire envoyé avec succes"
                            setTimeout(() => {
                                dispatch(changeStartWeight(dataWeight)) 
                                navigate("/", { replace: true }) 
                            }, 3000);
                        } else {
                            setIsLoading(false)
                            statusBilanMessage.innerHTML = "Erreur de poids"
                            setTimeout(() => { navigate("/", { replace: true }) }, 3000);
                        }
                    } else {
                        setIsLoading(false)
                        statusBilanMessage.innerHTML = "Erreur"
                        setTimeout(() => { navigate("/", { replace: true }) }, 3000);
                    }
                });

        } else {
            alert("Veuillez remplir tous les champs.")
        }
    }
    const optionsX = [
        {
            value: "1",
            label: "Sédentaire (pas d'activé physique, marche légère, travail sédentaire)"
        },
        {
            value: "2",
            label: "Légèrement actif (sport 1 à 3x semaine & travail sédentaire ou pas d'activité et travail un peu plus actif)"
        },
        {
            value: "3",
            label: "Actif (sport 3 à 5x semaine & travail sédentaire ou pas d'activité mais travail très intense)"
        },
        {
            value: "4",
            label: "Très actif (sport 3 à 5x semaine & travail très intense)"
        },
        {
            value: "5",
            label: "Extrêmement actif (sport + de 5x semaine & travail très intense)"
        },
    ]
    return userData.start ? <div>Formulaire deja remplis</div> : (
        <Wrapper>
            <DivStatus id="statusForm">
                {isLoading &&
                    <LoadingWrapper>
                        <span>Envoie en cours</span>
                        <div className="lds-ripple" >
                            <div></div>
                            <div></div>
                        </div>
                    </LoadingWrapper>
                }
                <span id="statusFormMessage"></span>
            </DivStatus>
            <Form onSubmit={handleSubmit}>
                <TextQuestion>
                    <label htmlFor="">Objectif <span>*</span></label>
                    <input required type="text" name="" onChange={(e) => setGoal(e.target.value)} />
                </TextQuestion>

                <TextQuestion>
                    <label htmlFor="">Age <span>*</span></label>
                    <input required type="number" name="" onChange={(e) => setAge(e.target.value)} />
                </TextQuestion>

                <TextQuestion>
                    <label htmlFor="">Poids <span>*</span></label>
                    <input required type="number" name="" onChange={(e) => setWeight(e.target.value)} />
                </TextQuestion>

                <TextQuestion>
                    <label htmlFor="">Taille <span>*</span></label>
                    <input required type="text" name="" onChange={(e) => setHeight(e.target.value)} />
                </TextQuestion>

                <TextQuestion>
                    <label htmlFor="">Allergies, intolérances</label>
                    <input type="text" name="" onChange={(e) => setAllergies(e.target.value)} />
                </TextQuestion>

                <TextQuestion>
                    <label htmlFor="">Traitement médical</label>
                    <input type="text" name="" onChange={(e) => setMedicalTreatment(e.target.value)} />
                </TextQuestion>

                <TextQuestion>
                    <label htmlFor="">Consomation d'eau moyenne</label>
                    <input type="number" name="" onChange={(e) => setWater(e.target.value)} />
                </TextQuestion>

                <TextQuestion>
                    <label htmlFor="">Habitudes alimentaires <span>*</span></label>
                    <input required type="text" name="" onChange={(e) => setFoodHabits(e.target.value)} />
                </TextQuestion>

                <TextQuestion>
                    <label htmlFor="">Emploie du temps <span>*</span></label>
                    <input required type="text" name="" onChange={(e) => setSchedule(e.target.value)} />
                </TextQuestion>

                <TextQuestion>
                    <label htmlFor="">Blessures, douleurs</label>
                    <input type="text" name="" onChange={(e) => setInjuries(e.target.value)} />
                </TextQuestion>




                <SelectQuestion>
                    <label>Niveau d'activité physique quotidien <span>*</span></label>
                    <OptionsContainer>
                        {optionsX.map((option, index) => (
                            <Options key={index} onClick={() => setActivityLevel(index)}>{option.value}</Options>
                        ))}
                    </OptionsContainer>
                    <span>{optionsX[activityLevel].label}</span>
                </SelectQuestion>

                <TextQuestion>
                    <label htmlFor="">Nombre d'entrainement par semaine</label>
                    <input type="text" name="" onChange={(e) => setTrainingsWeek(e.target.value)} />
                </TextQuestion>

                <TextQuestion>
                    <label htmlFor="">Moment de l'entrainemnet</label>
                    <input type="text" name="" onChange={(e) => setTrainingTime(e.target.value)} />
                </TextQuestion>

                <Submit type="submit" value="Envoyer" />

            </Form>
        </Wrapper>
    )
}