import axios from "axios"
import { useEffect, useState } from "react"
import { BiSearch } from "react-icons/bi"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import styled from "styled-components"
import colors from "../components/UIKit/colors"

const Wrapper = styled.div`
    width:100%;
    margin:auto;
    background-color: ${colors.beigeBG};
`
const Search = styled.div`
    background-color: white;
    display: flex;
    align-items: center;
    height: 2rem;
    flex: 0;
    width: 90%;
    margin: auto;
    padding 0.3rem 0;
    border-radius: 1rem;
`
const SearchBtn = styled(BiSearch)`
    margin: 0 1rem;
`
const Title = styled.div`
    font-size: 1.5rem;
    font-weight: bolder;    
    font-family: 'Manrope', sans-serif;
    margin-bottom: 1rem; 
    margin-left: 5%;
`
const SubTitle = styled.div`
    font-size: 1.2rem;
    font-weight: bold;    
    font-family: 'Manrope', sans-serif;
    margin: 1rem 0; 
    margin-left: 5%;
`
const CategoriesSection = styled.div`
    display: flex;
    gap: 1rem;
    overflow-x: scroll;
    width: 100%;
    padding-left: 5%;
    margin: 1rem 0;

    &::-webkit-scrollbar {
        display: none;
      }
`
const Category = styled.div`
    font-size: 0.9rem;
    min-width: 5rem;
    padding: 0.5rem 0;
    background-color: white;
    border-radius: 2rem;
    border: 1px solid black;
    text-align: center;

    &.selectedCategoryColored{
        background-color: ${colors.beige};
    }
`

const RecipesSection = styled.div`
    display: flex;
    gap: 1rem;
    overflow-x: scroll;
    width: 100%;
    padding-left: 5%;
    margin: 1rem 0;

    &::-webkit-scrollbar {
        display: none;
      }
`
const Recipe = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
`
const RecipeCover = styled.img`
    width: 90%;
    border-radius: 1rem;
    filter: brightness(70%);
`
const RecipeName = styled.div`
    font-size: 1.5rem;
    margin-top: auto;
    color: white;
    z-index: 1;
`
const RecipeBy = styled.div`
    font-size: 0.85rem;
    opacity: 0.8;
    margin-bottom: 0.5rem;
    z-index: 1;
`

const RecipeOfTheWeekSection = styled.div`

`
const RecipeOfTheWeekContainer = styled.div`
    margin-left: 5%;
`
const RecipeOfTheWeekCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 250px;
    margin-bottom: 1rem;
    border-radius: 2rem;
    position: relative;
    overflow: hidden;

    &::before {
        content: "";
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background-image: url('../img/recipes/${({cover}) => cover}');
        filter: brightness(70%);
        background-position: center;
        background-size: 100%;
        z-index: 0;
      }

`

export default function Recettes() {
    const [isLoading, setIsLoading] = useState(false)
    const [categories, setCategories] = useState(null)
    const [recipesOfTheWeek, setRecipesOfTheWeek] = useState(null)
    const [recipes, setRecipes] = useState(null)
    const [error, setError] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState('all')
    const dispatch = useDispatch()



    useEffect(() => {
        const isOlderThanOneWeek = (date) => {

            const pastTime = new Date(date);
            const now = new Date();

            const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;

            const timeDiffInMs = now.getTime() - pastTime.getTime();

            if (timeDiffInMs >= sevenDaysInMs) {
                return true
            } else {
                return false
            }
        }

        const sortNewRecipes = (recipes) => {
            setIsLoading(true)
            let recipesOfTheWeekList = []
            recipes.forEach(recipe => {
                if (!isOlderThanOneWeek(recipe.updatedAt)) {
                    recipesOfTheWeekList.push(recipe)
                }
            })
            setRecipesOfTheWeek(recipesOfTheWeekList)
            setIsLoading(false)
        }
        const sortCategories = (recipes) => {
            setIsLoading(true)
            let allCategories = []
            recipes.forEach(element => {
                if (!allCategories.includes(element.category)) {
                    allCategories.push(element.category)
                }
            })
            setCategories(allCategories)
            setIsLoading(false)
        }

        const fetchRecipe = async () => {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}api/diet/getAllRecipes`, { withCredentials: true })
            if (res.status === 200) {
                const data = await res.data
                setRecipes(data)
                sortCategories(data)
                sortNewRecipes(data)
            } else {
                setError('FAILED_TO_LOAD_RECIPES_LIST')
            }
            setIsLoading(false)
        }
        console.log(recipesOfTheWeek);
        if (!recipes) fetchRecipe()
    }, [recipes, recipesOfTheWeek])

    return (
        <Wrapper>
            <Title>
                Que vas-tu cuisiner aujourd'hui ?
            </Title>
            <Search>
                <SearchBtn />
                Cherche une recette
            </Search>
            <CategoriesSection>
                {selectedCategory === 'all' ?
                    <Category onClick={(e) => setSelectedCategory('all')} className="selectedCategoryColored">
                        Toutes
                    </Category> :
                    <Category onClick={(e) => setSelectedCategory('all')}>
                        Toutes
                    </Category>
                }
                {
                    categories?.map((category, index) => {
                        if (category === selectedCategory) {
                            return (
                                <Category className="selectedCategoryColored" key={index} onClick={(e) => setSelectedCategory(category)}>
                                    {category}
                                </Category>
                            )
                        } else {
                            return (
                                <Category key={index} onClick={(e) => setSelectedCategory(category)}>
                                    {category}
                                </Category>
                            )
                        }
                    })
                }
            </CategoriesSection>
            <SubTitle>Recettes</SubTitle>
            <RecipesSection>
                {
                    recipes?.map((recipe, index) => {
                        return selectedCategory !== 'all' ?
                            selectedCategory === recipe.category ?
                                <Recipe to={`/recipe/${recipe._id}`} key={index}>
                                    {recipe.cover && <RecipeCover src={`../img/recipes/${recipe?.cover}`} alt="Cover recipe" />}
                                    <RecipeName>{recipe.name}</RecipeName>
                                    <RecipeBy>Par Ali.diet_ethique</RecipeBy>
                                </Recipe>
                                : null
                            :
                            <Recipe to={`/recipe/${recipe._id}`} key={index}>
                                {recipe.cover && <RecipeCover src={`../img/recipes/${recipe?.cover}`} alt="Cover recipe" />}
                                <RecipeName>{recipe.name}</RecipeName>
                                <RecipeBy>Par Ali.diet_ethique</RecipeBy>
                            </Recipe>
                    })
                }
            </RecipesSection>
            {recipesOfTheWeek?.length > 0 &&
                <RecipeOfTheWeekSection>
                    <SubTitle>Recette de la semaine</SubTitle>
                    <RecipeOfTheWeekContainer>
                        {recipesOfTheWeek?.map((recipe, index) => {
                            return (
                                <RecipeOfTheWeekCard key={index} cover={recipe?.cover}>
                                    {/* {recipe.cover && <RecipeCover src={`../img/recipes/${recipe?.cover}`} alt="Cover recipe" />} */}
                                    <RecipeName>{recipe.name}</RecipeName>
                                    <RecipeBy>Par Ali.diet_ethique</RecipeBy>
                                </RecipeOfTheWeekCard>
                            )
                        })}
                    </RecipeOfTheWeekContainer>
                </RecipeOfTheWeekSection>
            }
        </Wrapper>
    )
}