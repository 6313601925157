import { useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { deleteIngredient, deleteMeal, editIngredient, editIngredientNumber, setRecipe, setSelectedMeal } from "../../../features/dietCreator"
import colors from "../../UIKit/colors"
import { MdDelete } from 'react-icons/md'
import { BsFillPlusCircleFill } from "react-icons/bs"
import { IoIosSave } from 'react-icons/io'
import axios from "axios"
import { AiOutlineCloseCircle } from "react-icons/ai"


const Wrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items; center;
    border : 2px solid ${colors.beige};
    border-radius : 1rem;
    overflow: hidden;
    margin: 0.5rem 0px;
    background-color: white;
`
const DeleteIcon = styled(MdDelete)`
    display: flex;
    align-items; center;
    justify-content: center;
    width: 5%;
    font-size : 1.5rem;
`
const MealInfos = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.beige};
    color : white;
    font-size : 1.5rem;
    padding : 0.1rem 0.5rem;
`
const MealName = styled.div`
   
`
const Ingredient = styled.div`
    display: flex;
    align-items: center;
    justify-content : flex-end;
    margin : 0.5rem 0;
    padding: 0 0.5rem;
`
const IngredientDemo = styled.div`
    display: flex;
    align-items; center;
    justify-content : flex-end;
    padding : 0 0.5rem;
    color: rgb(100,100,100);
    border-bottom: 1px solid ${colors.beige};

    & ${DeleteIcon} {
        opacity: 0;
    }
`
const IngredientTotal = styled.div`
    display: flex;
    align-items; center;
    justify-content : flex-end;
    margin : 0.5rem;
    color: rgb(100,100,100);

    & ${DeleteIcon} {
        opacity: 0;
    }
`

const IngredientName = styled.div`
    display: flex;
    flex: 1;
    word-break: break-word;
`
const IngredientData = styled.div`
    width : 15%;
    text-align: center;
`
const InputChangeQuantity = styled.input`
    width : 100%;
    border : unset;
    border-bottom : 1px solid black;
    background-color: rgba(200, 200, 200, 0.3);

    &:focus {
        outline: none;
    }
`
const FormChangeQuantity = styled.form`
    width: 15%;
    display: inline-block;

    & input[type=submit]{
        display: none;
    }
`
const OptionsRow = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin: 0.2rem 0px;
    font-size: 1.5rem;
    color: ${colors.grey};
`
const BtnRecpe = styled.div`

`
const Recipe = styled.div`
    color: rgb(100,100,100);
    margin: 0.5rem;

    & h3{
        margin-bottom: 0.5rem;
    }
`
const ModifRecipe = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    & textarea{
        width : 100%;
        height: 100px;
    }
        
    & button{
        background-color: ${colors.beige};
        border: none;
        width: 16px;
        height: 16px;
        border-radius: 50%;
    }
`
const DeleteSpace = styled.div`
    width: 1rem;
`


export default function Meal(props) {
    const { meal, setModale } = props
    const [changeIngredient, setChangeIngredient] = useState(false)
    const [changeNumber, setChangeNumber] = useState(false)
    const [showRecipe, setShowRecipe] = useState(false)
    const [modifRecipe, setModifRecipe] = useState(false)
    const [recipeContent, setRecipeContent] = useState(null)
    const dispatch = useDispatch()

    const clickAddIngredient = (meal) => {
        const data = {
            'index': meal.index
        }
        dispatch(setSelectedMeal(data))
        setModale(true)
    }

    let cal = 0
    meal.ingredients.forEach(ingredient => {
        cal += ingredient.cal * ingredient.quantity / 100
    });

    let prot = 0
    meal.ingredients.forEach(ingredient => {
        prot += ingredient.prot * ingredient.quantity / 100
    });
    let carbs = 0
    meal.ingredients.forEach(ingredient => {
        carbs += ingredient.carb * ingredient.quantity / 100
    });
    let fat = 0
    meal.ingredients.forEach(ingredient => {
        fat += ingredient.fat * ingredient.quantity / 100

    });

    const handleUpdateQuantity = (quantity, name) => {
        dispatch(editIngredient(meal.index, name, quantity))
    }
    const handleUpdateNumber = (number, name) => {
        dispatch(editIngredientNumber(meal.index, name, number))
    }
    const handleDeleteIngredient = (name) => {
        dispatch(deleteIngredient(meal.index, name))

    }
    const handleSaveMeal = async () => {
        const data = {
            "name": meal.name,
            "recipe": "meal.recipe",
            "ingredients": meal.ingredients
        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}api/diet/newMeal`, data, { withCredentials: true })
    }
    const handleSetRecipe = () => {
        dispatch(setRecipe(meal.index, recipeContent))
        setModifRecipe(false)
    }

    const handleDeleteMeal = (index) => {
        dispatch(deleteMeal(index))
    }
    return (
        <Wrapper>
            <MealInfos>
                <MealName>{meal.name}</MealName>
                <OptionsRow>
                    <BsFillPlusCircleFill onClick={(e) => clickAddIngredient(meal)} />
                    <IoIosSave onClick={() => handleSaveMeal()} />
                    <BtnRecpe onClick={() => setShowRecipe(!showRecipe)}>Recette</BtnRecpe>
                    <MdDelete onClick={() => handleDeleteMeal(meal.index)} />
                </OptionsRow>
            </MealInfos>
            <IngredientDemo>
                <IngredientName></IngredientName>
                <IngredientData>Qtt</IngredientData>
                <IngredientData>Num</IngredientData>
                <IngredientData>Kcal</IngredientData>
                <IngredientData>Prot</IngredientData>
                <IngredientData>Glu</IngredientData>
                <IngredientData>Gra</IngredientData>
                <DeleteSpace></DeleteSpace>
            </IngredientDemo>
            {meal.ingredients?.map((ingredient, index) => {
                return <Ingredient>
                    <IngredientName>{ingredient.name}</IngredientName>
                    {
                        changeIngredient === index ?
                            <FormChangeQuantity onSubmit={() => setChangeIngredient(false)}>
                                <input type="submit" value="" />
                                <InputChangeQuantity autoFocus placeholder="Poids" type="number" onChange={(e) => { handleUpdateQuantity(e.target.value, ingredient.name) }} />
                            </FormChangeQuantity>
                            :
                            <IngredientData onClick={() => { setChangeIngredient(index) }}>{ingredient.quantity}</IngredientData>
                    }
                            {
                                changeNumber === index ?
                                <FormChangeQuantity onSubmit={() => setChangeNumber(false)}>
                                    <input type="submit" value="" />
                                    <InputChangeQuantity autoFocus placeholder="Nombre" type="text" onChange={(e) => { handleUpdateNumber(e.target.value, ingredient.name) }} />
                                </FormChangeQuantity>
                                :
                                <IngredientData onClick={() => { setChangeNumber(index) }}>{ingredient.number}</IngredientData>
                            }
                    <IngredientData>{parseFloat((ingredient.cal * ingredient.quantity / 100).toFixed(1))}</IngredientData>
                    <IngredientData>{parseFloat((ingredient.prot * ingredient.quantity / 100).toFixed(1))}</IngredientData>
                    <IngredientData>{parseFloat((ingredient.carb * ingredient.quantity / 100).toFixed(1))}</IngredientData>
                    <IngredientData>{parseFloat((ingredient.fat * ingredient.quantity / 100).toFixed(1))}</IngredientData>
                    <DeleteSpace>
                        <AiOutlineCloseCircle onClick={() => handleDeleteIngredient(ingredient.name)} />
                    </DeleteSpace>
                </Ingredient>
            })}
            <IngredientTotal>
                <IngredientName>TOTAL</IngredientName>
                <IngredientData> </IngredientData>
                <IngredientData> </IngredientData>
                <IngredientData>{parseFloat((cal).toFixed(1))}</IngredientData>
                <IngredientData>{parseFloat((prot).toFixed(1))}</IngredientData>
                <IngredientData>{parseFloat((carbs).toFixed(1))}</IngredientData>
                <IngredientData>{parseFloat((fat).toFixed(1))}</IngredientData>
                <DeleteSpace></DeleteSpace>
            </IngredientTotal>
            {showRecipe &&
                <Recipe>
                    <h3>Recette :</h3>
                    {modifRecipe || meal.recipe === null ?
                        <ModifRecipe>
                            <textarea onChange={(e) => setRecipeContent(e.target.value)}></textarea>
                            <button onClick={() => handleSetRecipe()}>V</button>
                        </ModifRecipe>
                        : <>
                            <p>
                                {meal.recipe}
                            </p>
                            <button onClick={() => setModifRecipe(true)}>Modifier</button>
                        </>
                    }
                </Recipe>}
        </Wrapper>
    )
}