import axios from "axios";
import { AiOutlineDelete } from "react-icons/ai";

//For bilan and user only

const Delete = (props) => {
    const { id, type } = props
  const handleDelete = () => {
    axios.delete(`${process.env.REACT_APP_API_URL}api/${type}/${id}`, { withCredentials: true });
  };
  return (
    <div className="delete-icon" onClick={() => handleDelete()}>
      <AiOutlineDelete />
    </div>
  );
};

export default Delete;