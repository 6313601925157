import styled from 'styled-components'
import { NavLink, useLocation} from 'react-router-dom'
import colors from '../colors'

const NavIconLink = styled(NavLink)`
width: 20%;
height: 100%;
display: flex;
align-items: center;
justify-content: center;

@media (min-width: 780px) {
    width: 75px;
    height: 75px;
}
`


export default function DocNavSvg(props){
    const {link} =props
    
    //colors navIcon
    const path = useLocation().pathname
    let fill = ''
    let stroke = colors.darkBrown
    if (path === `/${link}`) {
        fill = colors.pink
    }

    return(
        <NavIconLink to={`/${link}`}>
            <svg width="28" height="38" viewBox="0 0 28 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.0992 4.91481L20.2162 5.25H20.5712H24.5C26.148 5.25 27.5 6.61212 27.5 8.3125V34.4375C27.5 36.1379 26.148 37.5 24.5 37.5H3.5C1.85216 37.5 0.5 36.1365 0.5 34.4375V8.3125C0.5 6.61212 1.85202 5.25 3.5 5.25H7.43021H7.78541L7.90234 4.91459C8.79969 2.34055 11.1865 0.5 14 0.5C16.8135 0.5 19.2004 2.34054 20.0992 4.91481ZM16.8333 7.125C16.8333 5.54579 15.5729 4.25 14 4.25C12.4246 4.25 11.1667 5.5461 11.1667 7.125C11.1667 8.70674 12.4274 10 14 10C15.5729 10 16.8333 8.70421 16.8333 7.125ZM8.16667 17.125H19.8333C20.7594 17.125 21.5 16.3584 21.5 15.4375C21.5 14.5166 20.7594 13.75 19.8333 13.75H8.16667C7.24055 13.75 6.5 14.5166 6.5 15.4375C6.5 16.3584 7.24055 17.125 8.16667 17.125Z"
                    stroke={stroke}
                    fill={fill}    
                />
            </svg>
        </NavIconLink>
    )
}