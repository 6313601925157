import { useState } from "react"
import { MdOutlineAddCircle } from "react-icons/md"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { addNewDay, setSelectedDay } from "../../../features/trainingCreator"
import colors from "../../UIKit/colors"
import DayTraining from "./DayTraining"
import ModaleTraining from "./ModaleTraining"

const Wrapper = styled.div`
    min-height:80vh;
    position: relative;

    &>h2{
        font-weight: normal;
        text-align: center;
        margin: 0.5rem 0 1rem;;
    }
`
const DaysList = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
`
const Day = styled.div`
    background-color: ${colors.beige};
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &.selected{
        width: unset;
        padding: 0 1rem;
    }
`
const AddDayBtn = styled(MdOutlineAddCircle)`
    color: ${colors.pink};
    font-size: 2.5rem;
`


export default function NewTraining() {
    const training = useSelector((state) => state.trainingCreator)
    const selectedDay = useSelector((state) => state.trainingCreator.selectedDay)
    const [modale, setModale] = useState(false)
    const dispatch = useDispatch()

    const addDay = () => {
        const data = {
            nameDay: null,
            noteDay: null
        }
        dispatch(addNewDay(data))
    }


    return (
        <Wrapper>
            <h2>Nouvel entrainement</h2>
            <DaysList>
                {training.days?.length > 0 && training.days.map((day, index) => {
                    if (day.indexDay === selectedDay) {
                        return (
                            <Day className="selected">Jour {day.indexDay + 1}</Day>
                        )
                    } else {
                        return (
                            <Day onClick={() => { dispatch(setSelectedDay(index)) }}>{day.indexDay + 1}</Day>
                        )
                    }
                })}
                <AddDayBtn onClick={() => { addDay() }}>+</AddDayBtn>
            </DaysList>
            <DayTraining setModale={setModale} />
            {modale && <ModaleTraining setModale={setModale} />}
        </Wrapper>
    )
}