import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { setRecipesList } from "../../../features/admin"
import colors from "../../UIKit/colors"

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const ErrorWrapper = styled.div`

`
const RecetteLine = styled.li`

`
const Categories = styled.select`
    background-color: #b1b1b11e;
    font-size: 1.2rem;
    padding: 0.3em;
    width: 80%;
    color: #464646ce;
`
const Category = styled.option`
    background-color: #b1b1b11e;
    padding: 0.5rem;
    width: 50%;
`
const RecipesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`
const Recipe = styled(Link)`
    width : 90%;
    border-bottom: 1px solid ${colors.beige};
    padding: 0.5rem 0px;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
`

export const RecettesListe = () => {
    const recipesList = useSelector((state) => state.adminData.recipesList)
    const error = useSelector((state) => state.adminData.error)
    const [isLoading, setIsLoading] = useState(false)
    const [categories, setCategories] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState('all')
    const dispatch = useDispatch()


    useEffect(() => {

        const sortCategories = (recipes) => {
            setIsLoading(true)
            let allCategories = []
            recipes.forEach(element => {
                if (!allCategories.includes(element.category)) {
                    allCategories.push(element.category)
                }
            })
            setCategories(allCategories)
            setIsLoading(false)
        }

        const fetchRecipe = async () => {
            setIsLoading(true)
            dispatch(setRecipesList())
            setIsLoading(false)
        }

        if (recipesList.length === 0) fetchRecipe()
        if (recipesList.length > 0) sortCategories(recipesList)
    }, [dispatch, recipesList, setCategories])

    switch (error) {
        case 'FAILED_TO_LOAD_RECIPES_LIST':
            return (
                <ErrorWrapper>
                    Impossible de charger les recettes pour le moment.
                </ErrorWrapper>
            )

        default:
            return (
                <Wrapper>
                    <Categories onChange={(e) => setSelectedCategory(e.target.value)}>
                        <Category value='all'>Toutes</Category>
                        <Category value='eui'>Aucune</Category>
                        {
                            categories?.map((category, index) => {
                                return (
                                    <Category value={category} key={index}>
                                        {category}
                                    </Category>
                                )
                            })
                        }
                    </Categories>
                    <RecipesContainer>
                        {
                            recipesList?.map((recipe, index) => {
                                return selectedCategory !== 'all' ?
                                    selectedCategory === recipe.category ?
                                        <Recipe to={`/recipe/${recipe._id}`} key={index}>
                                            {recipe.name}
                                        </Recipe>
                                        : null
                                    :
                                    <Recipe to={`/recipe/${recipe._id}`} key={index}>
                                        {recipe.name}
                                    </Recipe>
                            })
                        }
                    </RecipesContainer>
                </Wrapper>
            )
    }

}