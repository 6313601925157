import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import Loading from "../components/Admin/Loading";
import colors from "../components/UIKit/colors";
import axios from "axios";

const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.2rem;

    & h3 {
        color: ${colors.beige};
        font-size: 2rem;
        margin: 0px auto 20px;
    }

    & img{
        width:80%;
        margin-bottom: 20px;
    }

`
const Poids = styled.div`
    margin-bottom:20px;
`
const Mensurations = styled.div`
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
`
const InputWrapper = styled.div`
  position: relative;
  width: 40%;
  margin-bottom:20px;
`

const FeelingWrapper = styled.div`
width: 100%;
margin-bottom:20px;
overflow-x: hidden;
word-wrap: break-word;
`

const Label = styled.span`
    color: #757575;
`
const PicLoading = styled.div`
    width: 40%;
    height: 150px;
    position: relative;
    margin: auto;
`
export default function BilanInfo() {
    const [isLoading, setIsLoading] = useState(true)
    const [picFace, setPicFace] = useState(false)
    const [picProfil, setPicProfil] = useState(false)
    const [picBack, setPicBack] = useState(false)


    let { bilanId } = useParams();
    const bilans = useSelector((state) => state.bilans);
    const bilan = bilans[bilanId]

    useEffect(() => {

        async function loadLinks(array) {

            const res = await axios.post(`${process.env.REACT_APP_API_URL}api/bilan/images`, {keys: array}, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            if (res.status === 201) {
                const data = res.data
                setPicFace(data[0])
                setPicProfil(data[1])
                setPicBack(data[2])

            }
        }

        loadLinks([bilan.pictureFace, bilan.pictureSide, bilan.pictureBack])
        setIsLoading(false)
    }, [bilan])

    const date = new Date(bilan.createdAt)
    const day = date.getDate()
    const month = date.getMonth() + 1


    return (
        <Wrapper>
            {isLoading && <Loading />}
            <h3>
                Bilan du  {day} / {month}
            </h3>
            <Poids><Label>Poids : </Label> {bilan.weight}</Poids>
            <Mensurations>
                <InputWrapper>
                <Label>Poitrine : </Label> {bilan.chest}
                </InputWrapper>
                <InputWrapper>
                <Label>Taille : </Label> {bilan.waist}
                </InputWrapper>
                <InputWrapper>
                <Label>Cuisse : </Label> {bilan.thigh}
                </InputWrapper>
                <InputWrapper>
                <Label>Bras : </Label> {bilan.arm}
                </InputWrapper>
                <InputWrapper>
                <Label>Fesses : </Label> {bilan.glutes}
                </InputWrapper>
                <InputWrapper>
                <Label>Mollets : </Label> {bilan.calves}
                </InputWrapper>
                <FeelingWrapper>
                </FeelingWrapper>
            </Mensurations>
            {picFace 
                ? <><img src={picFace} alt="" /></> 
                : <PicLoading><Loading /></PicLoading>
            }
            {picProfil 
                ? <><img src={picProfil} alt="" /></> 
                : <PicLoading><Loading /></PicLoading>
            }
            {picBack 
                ? <><img src={picBack} alt="" /></> 
                : <PicLoading><Loading /></PicLoading>
            }
        </Wrapper>
    )
}