import styled from "styled-components"
import colors from "../components/UIKit/colors";
import UsersList from "../components/Admin/UsersList"
import BilansList from "../components/Admin/BilansList"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height:100vh;
`
const Title = styled.div`
    color : ${colors.grey};
    font-size: 2rem;
    width: 100%;
    text-align: center;
    padding: 0.5rem 0px;
`
const ListesContainer = styled.div`
    display: flex;
    width:100%;
    margin: auto;
    flex-direction: column;
    align-items: center;

    @media (min-width: 780px) {
        width: 90%;
        align-items: flex-start;
        flex-direction: row;
    }
`

export default function AdminDashnoard() {

    return (
        <Wrapper>
            <Title>Fit my Donut Coaching</Title>
            <ListesContainer>
                <UsersList />
                <BilansList />
            </ListesContainer>
        </Wrapper>
    )
}