import { createAction, createReducer } from "@reduxjs/toolkit";
import axios from "axios";

//Actions
export const getUsersAdmin = createAction('GET_USERS_ADMIN')
export const getBilansAdmin = createAction('GET_BILANS_ADMIN')
export const pushIngredientToList = createAction('PUSH_INGREDIENT_TO_LIST')
export const changeIngredientsList = createAction('CHANGE_INGREDIENT_LIST')
export const changeMealsList = createAction('CHANGE_MEALS_LIST')
export const changeRecipesList = createAction('CHANGE_RECIPES_LIST')  

export const setError = createAction('SET_ERROR')

export function fetchOrUpdateUsers(users) {
    const usersCopy = [...users]
    const usersReverse = usersCopy.reverse()
    return async (dispatch) => {
        dispatch(getUsersAdmin(usersReverse))
    }
}
export function fetchOrUpdateBilans(bilans) {
    const bilansCopy = [...bilans]
    const bilansReverse = bilansCopy.reverse()
    return async (dispatch) => {
        dispatch(getBilansAdmin(bilansReverse))
    }
}
export function addIngredientToList(data) {
    console.log('addIngredientToList');
    return async (dispatch) => {
        dispatch(pushIngredientToList(data))
    }
}
export function setIngredientsList() {
    console.log('setIngredientsList');
    return async (dispatch) => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}api/diet/getAllIngredients`, { withCredentials: true })
        const data = await res.data
        if (res.status == 200) {
            dispatch(changeIngredientsList(data))
            dispatch(setError(null))
        } else {
            dispatch(setError('CANNOT_FETCH_INGREDIENTS'))
        }
    }
}
export function setMealsList() {
    console.log('setMealsList');
    return async (dispatch) => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}api/diet/getAllMeals`, { withCredentials: true })
        if (res.status == 200) {
            const data = await res.data
            dispatch(changeMealsList(data))
            dispatch(setError(null))
        } else {
            dispatch(setError('CANNOT_FETCH_MEALS'))
        }
    }
}
export function setRecipesList() {  // 13
    console.log('setRecipesList');
    return async (dispatch) => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}api/diet/getAllRecipes`, { withCredentials: true })
        if (res.status === 200) {
            const data = await res.data
            dispatch(changeRecipesList(data))
            dispatch(setError(null))
        } else {
            dispatch(setError('FAILED_TO_LOAD_RECIPES_LIST'))
        }
    }
}

const initialState = {
    users: null,
    bilans: null,
    ingredientsList: [],
    mealsList: [],
    recipesList: [],
    error: null
};

export default createReducer(initialState, (builder) =>
    builder
        .addCase(getUsersAdmin, (draft, action) => {
            draft.users = action.payload
            return
        })
        .addCase(getBilansAdmin, (draft, action) => {
            draft.bilans = action.payload
            return
        })
        .addCase(pushIngredientToList, (draft, action) => {         // Ajoute un ingredient a la liste du store
            draft.ingredientsList.push(action.payload)
        })
        .addCase(changeIngredientsList, (draft, action) => {
            draft.ingredientsList = action.payload
        })
        .addCase(changeMealsList, (draft, action) => {
            draft.mealsList = action.payload
        })
        .addCase(setError, (draft, action) => {
            draft.error = action.payload
        })
        .addCase(changeRecipesList, (draft, action) => {        // Enregistre la liste complete de recettes depuis la bdd vers le store
            draft.recipesList = action.payload
        })
)