import React from "react"

import logo from "../images/logo.png"
import styled from 'styled-components'
import FreeTraining from '../components/widgets/freeTraining'
import MainBtn from "../components/UIKit/btn/MainBtn"
import TextBtn from "../components/UIKit/btn/TextBtn"
import colors from "../components/UIKit/colors"


const StartDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    font-family: Alata, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Oxygen, Cantarell, sans-serif;

    & h3{
        color: ${colors.pink};
        font-size: 1.8rem;
        margin-bottom: 0.3rem;
    }

    & h4{
        color: rgb(60,60,60);
        font-size: 1.4rem;
        margin-bottom: 0.3rem;
    }

    & p{
        color: rgb(80,80,80);
        margin-bottom: 0.5rem;
        font-size: 1.1rem;
    }
`

const TextStart = styled.div`
    width: 80%;
    margin: auto;
    text-align:justify;
`

const LineOne = styled.div`
    display: flex;
`

const Logo = styled.img`
    margin: 2rem auto;
    width: 150px;
`

const BtnContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem auto;
`


export default function Start() {

    return (
        <StartDiv>
            <LineOne>
                <Logo src={logo} alt="Logo donuts musclé" />
            </LineOne>
            <TextStart>
                <h3>
                    Bienvenue sur Fit my Donut
                </h3>
                <p>
                    Nouveau ? Rejoins-nous pour commencer ton suivi 100% personnalisé.
                </p>
                <h4>
                    Débute ton évolution en quelques clicks.
                </h4>
                <p>
                    Choisis la formule adaptée a tes besoins parmis nos différentes offres.
                </p>
                <p>
                    Ensuite tu recevras toutes les infos nécessaire afin de commencer ce travail ensemble.
                </p>
            </TextStart>
            <BtnContainerDiv>
                <MainBtn text="Rejoins-nous" link="/register" />
                <TextBtn text="Déja membre ? Connecte-toi ici" link="/login" />
            </BtnContainerDiv>
            <FreeTraining />
        </StartDiv>
    )
    
}