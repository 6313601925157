import { createAction, createReducer } from "@reduxjs/toolkit";

//Action
export const getUserBilansAdmin = createAction('GET_BILAN_ADMIN');
export const removeBilanAdmin = createAction('DELETE_BILAN_ADMIN')

export function fetchOrUpdateUserBilansAdmin(data) {
  const dataCopy = [...data]
    const dataReverse = dataCopy.reverse()
  return async (dispatch) => {
    dispatch(getUserBilansAdmin(dataReverse));
  }
}

export function deleteBilanAdmin(bilanId) {
  return async (dispatch) => {
    dispatch(removeBilanAdmin(bilanId))
  }
}

const initialState = []

export default createReducer(initialState, (builder) =>
  builder
    .addCase(getUserBilansAdmin, (state, action) => {
      return action.payload
    })
    .addCase(removeBilanAdmin, (draft, action) => {
      draft = draft.filter((bilan) => bilan._id !== action.payload)
    })
)