import Log from "../components/Log";
import styled from "styled-components"

const LogPageDiv = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    font-family: Alata, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Oxygen, Cantarell, sans-serif;
`



export default function LogPage(props){
    const {signup, justValided} = props

    return (
        <LogPageDiv>
            <Log signup={signup ? true : ''} signin={signup ? '' : true} justValided={justValided}/>
        </LogPageDiv>
    )
}