import { useEffect, useState } from "react"
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import AddDiet from "../diet/AddDiet"
import colors from "../../UIKit/colors"
import { BsPlusCircleFill } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { fetchOrUpdateUserAdmin } from "../../../features/selectedUser"
import BilansListeUser from "./BilansListeUser"
import DietsListUser from "./DietsListUser"
import StatusSuivi from "./StatusSuivi"
import AddSuivi from "./AddSuivi"
import { selectedUser } from '../../../utils/selectors'
import Loading  from '../Loading'
import AddDoc from "../docs/AddDoc"
import DocsListUser from "../docs/DocsListUser"
import StatusNextCheckUp from "./StatusNextCheckUp"
import SetNextCheckUp from "./SetNextCheckUp"



const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
`
const Name = styled.div`
    margin-top : 1rem;
    width: 100%;
    text-align: center;
    font-size : 2rem;
    color : ${colors.beige}
`
const DoubleBtn = styled.div`
    margin: 2rem auto 0px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin-bottom:1rem;

    &>.pinkBtnDoubleBtn{
        background-color: ${colors.pink};
    }
`
const DietBtn = styled.button`
    width: 48%;
    display: flex;
    justify-content: space-around;
    align-items: center; 
    color: white;
    background-color: ${colors.beige};
    border : unset;
    font-size: 1.2rem;
    padding: 0.3em 0px;
`
const DocBtn = styled.button`
    width: 48%;
    display: flex;
    justify-content: space-around;
    align-items: center; 
    color: white;
    background-color: ${colors.pink};
    border : unset;
    font-size: 1.2rem;
    padding: 0.3em 0px;
`
const InfosUser = styled.div`

`


export default function AdminDashnoardUser() {
    const [isLoading, setIsLoading] = useState(true)
    const [showAddDiet, setShowAddDiet] = useState(false)
    const [showAddDoc, setShowAddDoc] = useState(false)
    const [showSuivi, setShowSuivi] = useState(false)
    const [showNextCheckUp, setShowNextCheckUp] = useState(false)
    const { userId } = useParams();
    const dispatch = useDispatch()
    const userData = useSelector(selectedUser)
    const formula = userData?.formula
    const nextCheckUp = userData?.nextCheckUp
    
    useEffect(() => {
        dispatch(fetchOrUpdateUserAdmin(userId))
        setIsLoading(false)

    }, [dispatch, userId])

    if (userData === null) return <Loading />
    return isLoading ? (
        <div>Loading</div>
    ) : (
        <Wrapper>
            <Name>
                {userData?.firstName && userData?.firstName} {userData?.name && userData?.name}
            </Name>
            <StatusSuivi setShowSuivi={setShowSuivi} end={formula ? formula.end : null} status={formula ? formula.status : false} />
            <StatusNextCheckUp setShowNextCheckUp={setShowNextCheckUp} nextCheckUp={nextCheckUp} />
            <InfosUser>
                <Link to={`/admin/form/${userId}`}>Voir le formulaire</Link>
            </InfosUser>
            <DoubleBtn>
                <DietBtn onClick={() => setShowAddDiet(true)}><span>Diet</span><span><BsPlusCircleFill /></span></DietBtn>
                <DocBtn onClick={() => setShowAddDoc(true)}><span>Document</span><span><BsPlusCircleFill /></span></DocBtn>
            </DoubleBtn>
            <DietsListUser userId={userId} />
            <br />
            <BilansListeUser userId={userId} />
            <br />
            <DocsListUser userId={userId} />
            {showAddDiet && <AddDiet userId={userId} setShowAddDiet={setShowAddDiet} />}
            {showAddDoc && <AddDoc userId={userId} setShowAddDoc={setShowAddDoc} />}
            {showSuivi && <AddSuivi userId={userId} setShowSuivi={setShowSuivi} />}
            {showNextCheckUp && <SetNextCheckUp userId={userId} setShowNextCheckUp={setShowNextCheckUp} />}
        </Wrapper>
    )

}