import axios from "axios";
import { AiOutlineDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

//For bilan only

const Delete = (props) => {
  const { id } = props
  const navigate = useNavigate();

  const handleDelete = async () => {
    const res = await axios.delete(`${process.env.REACT_APP_API_URL}api/bilan/${id}`, { withCredentials: true });
    if (res.status === 200) {
      navigate(-1)
    }
  };
  return (
    <div className="delete-icon" onClick={() => handleDelete()}>
      <AiOutlineDelete />
    </div>
  );
};

export default Delete;