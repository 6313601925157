import { formateDate } from "../../../utils/utils"
import styled from "styled-components"

const Wrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction : column;
`

const BtnSuivi = styled.button`
    margin-top: 0.5rem;
    background-color: unset;
    border : unset;
    text-decoration: underline;
    font-size : 1rem;
`

export default function StatusSuivi({setShowSuivi, end, status}){

    function isBeforeToday(date) {
        const today = new Date()
        const end = new Date(date)

        return end.getTime() > today.getTime()
    }

    return (
        <Wrapper>
            <span>{status ?
                    end &&
                        isBeforeToday(end) ?
                            "Suivi valide jusqu'au : " + formateDate(end) :
                            "Suivi terminé depuis le : " + formateDate(end)
                : "AUCUN SUIVI"}</span>
                <BtnSuivi  onClick={() => setShowSuivi(true)}>Modifier le suivi</BtnSuivi>
        </Wrapper>
    )

}