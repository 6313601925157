import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { updateNextCheckUp, updateSuivi } from "../../../features/selectedUser";
import colors from '../../UIKit/colors';
import Loading from '../Loading'


const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    left 0px;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
`

const Form = styled.form`
    width: 90%;
    padding: 50px 0px;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background-color: ${colors.beige};
    position: relative;
`
const CloseBtn = styled.div`
    position: absolute;
    top: 5px;
    right:10px;
`

export default function SetNextCheckUp({userId, setShowNextCheckUp}) {
    const [date, setDate] = useState(new Date())
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    const handleDate = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const data = {
            'nextCheckUp': date
        }

        const res = await axios.patch(`${process.env.REACT_APP_API_URL}api/user/nextCheckUp/${userId}`, data, { withCredentials: true })
        
        if (res.status === 201) {
            const nextCheckUp = res.data.nextCheckUp
            dispatch(updateNextCheckUp(nextCheckUp))
            setShowNextCheckUp(false)
            setIsLoading(false)
        } else {
            setIsLoading(false)
            alert("Erreur lors de l'ajout de la date du bilan")
        }
    }

    return (
        <Wrapper>
            {isLoading && <Loading />}
            <Form onSubmit={handleDate}>
            <CloseBtn onClick={() => setShowNextCheckUp(false)}>X</CloseBtn>

                <label>
                    Date du prochain bilan :
                    <input type="date" className="startDate" id="startDate" onChange={(e) => setDate(e.target.value)} />
                </label>
    
                <p><button>Valider</button></p>
            </Form>
        </Wrapper>
    )
}