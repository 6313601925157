import { useState } from "react"
import colors from "../../../UIKit/colors"
import styled from "styled-components"
import { AiFillCloseCircle } from 'react-icons/ai'
import { AddRecipe } from "../AddRecipe"

const BgWrapper = styled.div`
    position: absolute;
    top: 0px;
    width: 100vw;
    height : 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(250,250,250,0.9);
    overflow: hidden;

    @media (min-width: 780px) {
        width: calc(100vw - 150px);
        max-width: calc(100vw - 150px);
    }   
`
const Wrapper = styled.div`
    z-index: 2;
    background-color: #edceb083;
    background-color: rgba(250,250,250,0);
    width: 100%;
    max-height: calc(100vh - 70px);
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
`
const BtnCloseContainer = styled.div`
    padding : 2rem 2rem;
    align-self: flex-end;
`
const BtnClose = styled(AiFillCloseCircle)`
    color: rgba(250,250,250,0.7);
    background-color: ${colors.grey};
    width : 2rem;
    height : 2rem;
    border-radius: 50%;  
`
const BtnContainer = styled.div`
    display: flex;
    margin: 1rem auto;
    gap : 1rem;
`

export default function ModaleRecipe({ setModale }) {
    const [modaleContent, setModaleContent] = useState('addRecipe')
  

    return (
        <BgWrapper>
            <BtnCloseContainer>
                    <BtnClose onClick={() => setModale(false)} />
                </BtnCloseContainer>
            <Wrapper>
                <BtnContainer>
                </BtnContainer>
                {(modaleContent === 'addRecipe' || modaleContent === '' || modaleContent === null) &&
                    <AddRecipe setAddRecipe={setModaleContent}/>
                }
            </Wrapper>
        </BgWrapper>
    )
}