import { useSelector } from "react-redux";
import styled from 'styled-components'
import {Link} from 'react-router-dom'

import {HiOutlineArrowNarrowRight} from 'react-icons/hi'
import colors from "../UIKit/colors";

const ListeBilanWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
`

const LinkBilan = styled(Link)`
    width : 100%;
    border-bottom: 1px solid ${colors.beige};
    padding: 0.5rem 0px;
    margin-top: 1rem;
    display: flex;
`

const Arrow = styled(HiOutlineArrowNarrowRight)`
    color: #d5d5d5;
`



export default function ListeBilan() {

    function formateDate(oldDate) {
        const date = new Date(oldDate)
        const day = date.getDate()
        const month = date.getMonth() + 1
        const result = day + " / " + month

        return result
    }

    
    const bilans = useSelector((state) => state.bilans);

    let links = []

    for (let i = 0; i < bilans.length; i++) {
        const bilan = bilans[i]
        links.push({date : formateDate(bilan.createdAt), link: "" + i, id: bilan._id})
        
    }

    return (
        <ListeBilanWrapper>
            {links.map((bilan) => {
                return (
                <LinkBilan to={bilan.link} key={bilan.id}>
                    

                    {bilan.date} <Arrow/>
                </LinkBilan>
                )
            })}
        </ListeBilanWrapper>
    )

}