import { createAction, createReducer } from "@reduxjs/toolkit";

//Action
export const changeSelectedDay = createAction('CHANGE_SELECTED_DAY');
export const addDay = createAction('ADD_DAY');
export const removeDay = createAction('REMOVE_DAY');
export const postExercice = createAction('ADD_EXERCICE');
export const postSets = createAction('ADD_SETS');
export const postReps = createAction('ADD_REPS');
export const postNote = createAction('ADD_NOTE');
export const changeOrder = createAction('CHANGE_ORDER');
export const changeNameDay = createAction('CHANGE_NAME_DAY');

export function setSelectedDay(data) {
    console.log('setSelectedDay');
    return async (dispatch) => {
        dispatch(changeSelectedDay(data))
    }
}

export function addNewDay(data) {
    console.log('addNewDay');
    return async (dispatch) => {
        dispatch(addDay(data))
    }
}

export function deleteDay(data) {
    console.log('deleteDay');
    return async (dispatch) => {
        dispatch(removeDay(data))
    }
}
export function setNameDay(data) {
    console.log('setNameDay');
    return async (dispatch) => {
        dispatch(changeNameDay(data))
    }
}

export function addExercice(data) {
    console.log('addExercice');
    return async (dispatch) => {
        await dispatch(postExercice(data))
    }
}

export function addSets(data) {
    console.log('addSets');
    return async (dispatch) => {
        await dispatch(postSets(data))
    }
}

export function addReps(data) {
    console.log('addReps');
    return async (dispatch) => {
        await dispatch(postReps(data))
    }
}

export function addNote(data) {
    console.log('addNote');
    return async (dispatch) => {
        await dispatch(postNote(data))
    }
}

export function setOrder(data) {
    console.log('setOrder');
    return async (dispatch) => {
        await dispatch(changeOrder(data))
    }
}

const initialState = {
    selectedTraining: null,
    selectedDay: null,
    name: 'New training',
    note: null,
    days: [/* 
        {
            nameDay: null,
            indexDay: 0,
            noteDay: null,
            exercices: [
                {
                    nameExercice: null,
                    setsExercice: null,
                    repsExercice: null,
                    restExercice: null,
                    supersetWithExercice: [],
                    noteExercice: null,
                }
            ]
        } */
    ]
}

export default createReducer(initialState, (builder) =>
    builder
        .addCase(changeSelectedDay, (draft, action) => {
            draft.selectedDay = action.payload
        })
        .addCase(addDay, (draft, action) => {
            draft.days.push({
                nameDay: action.nameDay,
                indexDay: draft.days.length,
                noteDay: action.noteDay,
                exercices: []
            })
        })
        .addCase(removeDay, (draft, action) => {
            draft.days = draft.days.filter((day) => day.indexDay !== action.payload)
        })
        .addCase(changeNameDay, (draft, action) => {
            draft.days.forEach(day => {
                if (day.indexDay === draft.selectedDay) {
                    day.nameDay = action.payload
                }
            });
        })
        .addCase(postExercice, (draft, action) => {
            draft.days.forEach(day => {
                if (day.indexDay === draft.selectedDay) {
                    day.exercices.push({
                        name: action.payload.name,
                        video: action.payload.video,
                        muscle: action.payload.muscle,
                        index: day.exercices.length,
                        sets : '',
                        reps: '',
                        note: ''
                    })
                }
            });
        })
        .addCase(postSets, (draft, action) => {
            draft.days.forEach(day => {
                if (day.indexDay === draft.selectedDay) {
                    day.exercices.forEach(exercice => {
                        if(exercice.name === action.payload.name && exercice.index === action.payload.index){
                            exercice.sets = action.payload.sets
                        }
                    });
                }
            });
        })
        .addCase(postReps, (draft, action) => {
            draft.days.forEach(day => {
                if (day.indexDay === draft.selectedDay) {
                    day.exercices.forEach(exercice => {
                        if(exercice.name === action.payload.name && exercice.index === action.payload.index){
                            exercice.reps = action.payload.reps
                        }
                    });
                }
            });
        })
        .addCase(postNote, (draft, action) => {
            draft.days.forEach(day => {
                if (day.indexDay === draft.selectedDay) {
                    day.exercices.forEach(exercice => {
                        if(exercice.name === action.payload.name && exercice.index === action.payload.index){
                            exercice.note = action.payload.note
                        }
                    });
                }
            });
        })
)