import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import colors from "../../UIKit/colors"
import AddMealForm from "./modaleMenu-content/AddMealForm"
import Meal from "./Meal"
import { BiCheckDouble } from 'react-icons/bi'
import { updateTitle } from "../../../features/dietCreator"
import ModaleMeal from "./ModaleMeal"
import Loading from "../Loading"
import { useEffect } from "react"
import ModaleDiet from "./ModaleDiet"
import { useParams } from "react-router-dom"
import axios from "axios"

const Wrapper = styled.div`
    min-height: 100vh;
    background-color: rgb(245, 245, 250);
`

const Diet = styled.div`
    margin: auto;
    margin-bottom: 100px;
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items; center;
    position: relative;
`
const Titre = styled.div`
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: rgb(10, 12, 16);
`
const WrapInputTitle = styled.form`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem auto;
    width: 100%;
    position: relative;
`
const InputTitle = styled.input`
    display: block;
    border: unset;
    font-size: 2rem;
    max-width: 80%;
    color: rgb(155,155,155);
    background-color: unset;
    font-weight: bold;  
    
    &:focus {
        outline: none;
    }
`

const TotalMaccros = styled.div`
    justify-content: center;
    display: flex;
    gap: 1rem;
    border-radius: 1rem;
    overflow: hidden;
    background-color: white;
    width: 100%;
    margin: 0 auto 1rem;
`
const Maccro = styled.div`

    padding: 0.5rem 1rem; 
    border-left: 1px solid ${colors.beige};
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;

    &:nth-child(1){
        border-left: none; 
    }

    &.MaccroCal{
        background-color: white;
    }

    & label{
        color: rgba(155,155,155,1);
    }
`
const BtnSave = styled.div`
    margin: 0 auto;
    background-color : ${colors.beige};
    border-radius : 1rem;
    margin-top: 0.7rem;
    padding : 0.3rem 0px;
    display: flex;
    border : unset;
    color: #455e52;
    font-size : 1.2rem;
    padding : 0.5rem;
    width: 45%;
    box-shadow: 0px 0px 16px 2px ${colors.beige};
    text-align: center;
    justify-content: center;
`
export default function NewDiet() {
    const diet = useSelector((state) => state.dietCreator)
    const meals = useSelector((state) => state.dietCreator.meals)
    const [addMeal, setAddMeal] = useState(false)
    const [modale, setModale] = useState(false)
    const [modaleDiet, setModaleDiet] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [showUpdatedTitle, setShowUpdatedTitle] = useState(false)
    const nbOfMeals = meals.length
    const dispatch = useDispatch()

    useEffect(() => {
        const WrapperNewDiet = document.getElementById("WrapperNewDiet")

        const handleClick = (e) => {
            console.log(e.target);
            if (e) {
                if (e.target) {
                    if (e.target.id !== 'inputTitleNewDiet') setShowUpdatedTitle(false)
                }
            }
        }

        WrapperNewDiet.addEventListener("click", (e) => handleClick(e))
    }, [])


    const handleUpdateTitle = (e) => {
        e.preventDefault()
        setShowUpdatedTitle(false)
    }

    const updateDiet = async (diet) => {
        const data = {
            userId: diet.userId,
            title: diet.title,
            meals: diet.meals
        }
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}api/diet/updateDiet/${diet.dietId}`, data, { withCredentials: true })
        if (res.status !== 201) {
            console.log('Enable to save diet');
        }
    }

    const handleUpdateDiet = (e) => {
        updateDiet(diet)
    }


    let cal = 0
    meals.forEach(meal => {
        meal.ingredients.forEach(ingredient => {
            cal += ingredient.cal * ingredient.quantity / 100
        });
    });

    let prot = 0
    meals.forEach(meal => {
        meal.ingredients.forEach(ingredient => {
            prot += ingredient.prot * ingredient.quantity / 100
        });
    });
    let carbs = 0
    meals.forEach(meal => {
        meal.ingredients.forEach(ingredient => {
            carbs += ingredient.carb * ingredient.quantity / 100
        });
    });
    let fat = 0
    meals.forEach(meal => {
        meal.ingredients.forEach(ingredient => {
            fat += ingredient.fat * ingredient.quantity / 100

        });
    });

    return (<Wrapper id="WrapperNewDiet">
        {isSaving && <Loading />}
        <Diet>
            {showUpdatedTitle ?
                <WrapInputTitle onSubmit={(e) => handleUpdateTitle(e)}>
                    <InputTitle
                        id="inputTitleNewDiet" autoFocus type="text" defaultValue={diet?.title} onChange={(e) => { dispatch(updateTitle(e.target.value)) }} />
                </WrapInputTitle>
                :
                <WrapInputTitle>
                    <Titre onClick={() => setShowUpdatedTitle(true)}>{diet?.title}</Titre>
                </WrapInputTitle>
            }

            <TotalMaccros>
                <Maccro className="MaccroCal">
                    <span>{parseFloat((cal).toFixed(1))}</span>
                    <label>Kcal</label>
                </Maccro>
                <Maccro>
                    <span>{parseFloat((prot).toFixed(1))}</span>
                    <label>Prot</label>
                </Maccro>
                <Maccro>
                    <span>{parseFloat((carbs).toFixed(1))}</span>
                    <label>Glu</label>
                </Maccro>
                <Maccro>
                    <span>{parseFloat((fat).toFixed(1))}</span>
                    <label>Gra</label>
                </Maccro>
            </TotalMaccros>
            {meals?.map((meal, index) => {
                return <Meal meal={meal} setModale={setModale} key={index} />
            })}
            <AddMealForm uid={diet?.userId} setAddMeal={setAddMeal} index={nbOfMeals} />
            {diet.modif ?
            <>
                <BtnSave
                    onClick={(e) => {
                        handleUpdateDiet(e)
                    }}
                >
                    Modifier
                </BtnSave>
                <BtnSave
                onClick={() => {
                    setModaleDiet(true)
                }}
                >
                Enregistrer
                </BtnSave>
            </>
                :
                <BtnSave
                    onClick={() => {
                        setModaleDiet(true)
                    }}
                >
                    Enregistrer
                </BtnSave>
            }
        </Diet>
        {modale &&
            <ModaleMeal setModale={setModale} />
        }
        {modaleDiet &&
            <ModaleDiet setModaleDiet={setModaleDiet} setIsSaving={setIsSaving} />
        }
    </Wrapper>
    )
}

