import styled from 'styled-components'
import { NavLink, useLocation} from 'react-router-dom'

const NavIconLink = styled(NavLink)`
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-20px);
    
    @media (min-width: 780px) {
        width: 100px;
        height: 100px;
        transform: translateX(0px) translateY(0px);
      }
`


export default function BilanNavSVG(props){
    const {link} =props

    //colors navIcon
    let fill = 'white'
    let stroke = 'white'
   

    return(
        <NavIconLink to={`/${link}`}>
           <svg width="63" height="63" viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="31.5" cy="31.5" r="31.5" fill="#EDCFB0"/>
                <path d="M9.50943 32.0943C9.50943 33.9141 10.9835 35.3871 12.8012 35.3871H27.6139V50.1999C27.6139 52.0196 29.088 53.4906 30.9057 53.4906C32.7233 53.4906 34.1974 52.0196 34.1974 50.1999V35.3871H49.0102C50.8278 35.3871 52.3019 33.9141 52.3019 32.0943C52.3019 30.2746 50.8278 28.8036 49.0102 28.8036H34.1974V13.9909C34.1974 12.1712 32.7233 10.6981 30.9057 10.6981C29.088 10.6981 27.6139 12.1712 27.6139 13.9909V28.8036H12.8012C10.9804 28.8026 9.50943 30.2736 9.50943 32.0943Z" 
                    stroke={stroke}
                    fill={fill}    
                />
            </svg>
        </NavIconLink>
    )
}