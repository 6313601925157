import styled from "styled-components"
import colors from "../colors";

const Wrapper = styled.div`
    color : ${colors.grey};
    font-size: 2rem;
    width: 100%;
    text-align: center;
    padding: 0.5rem 0px;
`

export default function TitlePage({title}){

    return(
        <Wrapper>
            {title}
        </Wrapper>
    )
}