import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { updateSuivi } from "../../../features/selectedUser";
import colors from '../../UIKit/colors';
import Loading from '../Loading'


const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    left 0px;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
`

const Form = styled.form`
    width: 90%;
    padding: 50px 0px;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background-color: ${colors.beige};
    position: relative;
`
const CloseBtn = styled.div`
    position: absolute;
    top: 5px;
    right:10px;
`

export default function AddSuivi({userId, setShowSuivi}) {
    const [startDate, setStartDate] = useState(new Date())
    const [nbWeeks, setNbWeeks] = useState(12)
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    function addWeeks(numOfWeeks, start) {
        const date = new Date(start)
        date.setDate(date.getDate() + numOfWeeks * 7);

        return date;
    }
    const handleDate = async (e) => {
        e.preventDefault()

        const data = {
            'status': true,
            'end': addWeeks(nbWeeks, startDate)
        }

        const res = await axios.patch(`${process.env.REACT_APP_API_URL}api/user/suivi/${userId}`, data, { withCredentials: true })
        
        if (res.status !== 201) alert("Erreur lors de l'ajout du suivi")
        if (res.status === 201) {
            const suivi = res.data.formula
            console.log(suivi);
            dispatch(updateSuivi(suivi))
            setShowSuivi(false)
        }
    }

    return (
        <Wrapper>
            {isLoading && <Loading />}
            <Form onSubmit={handleDate}>
            <CloseBtn onClick={() => setShowSuivi(false)}>X</CloseBtn>

                <label>
                    Date de début :
                    <input type="date" className="startDate" id="startDate" onChange={(e) => setStartDate(e.target.value)} />
                </label>

                <label >
                    Nombre de semaines :
                    <input type="number" onChange={(e) => setNbWeeks(e.target.value)} defaultValue="12" />
                </label>

                <p><button>Submit</button></p>
            </Form>
        </Wrapper>
    )
}