import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import colors from "../components/UIKit/colors";
import { BsArrowLeftShort, BsCheckAll } from 'react-icons/bs'
import { BiImageAdd } from 'react-icons/bi'
import axios from "axios";
import { useNavigate } from "react-router-dom"
import "../styles/index.css"
import { changeWeight } from "../features/user";
import Loading from "../components/Admin/Loading";

const BilanWrapper = styled.form`
    &>div{
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }


    & h3{
        color: ${colors.beige};
        font-size: 2rem;
        width: 90%;
        margin: 0px auto 20px;
        text-align: center;
    }
`
const Mensurations = styled.div`
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`
const InputWrapper = styled.div`
  position: relative;
  width: 40%;
  margin-bottom:20px;
`
const InputWrapperText = styled.div`
    position: relative;
    width: 80%;
    margin-bottom:1rem;

    &.optionalQuestion{
        margin-top: 1rem;
    }
`
const Input = styled.input`
  font-size: 18px;
  padding: 10px 0px 10px 0px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid #757575;
  &:focus {
    outline: none;
    border-bottom: 1px solid ${colors.beige};
  }
  &::placeholder{
    color: transparent;
  }
`
const Textarea = styled.textarea`
    font-size: 18px;
    padding: 10px 0px 10px 0px;
    display: block;
    width: 100%;
    height : 5rem;
    border: none;
    background-color: rgba(200,200,200,0.1);
    &:focus {
    outline: none;
    background-color: rgba(200,200,200,0.3);
    }
    &::placeholder{
    color: transparent;
    }
`
const Question = styled.label`
    color: #999;
    font-size: 1rem;
    font-weight: normal;
    pointer-events: none;
    transition: 0.2s ease all;
`
const Label = styled.label`
  color: #999;
  font-size: 1rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  ${Input}:focus ~ & {
    font-size: 1.2rem;
    color: ${colors.beige};
  }
  ${Input}:not(:placeholder-shown) ~ & {
    top: -5px;
    font-size: 1rem;
  }

  ${Textarea}:focus ~ & {
    font-size: 1.2rem;
    color: ${colors.beige};
  }
  ${Textarea}:not(:placeholder-shown) ~ & {
    top: -5px;
    font-size: 1rem;
  }
`
const RespectWeekContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
`
const RespectWeekBtn = styled.div`
  border-radius: 0.5rem;
  border: 1px solid ${colors.beige};
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &>span{
    margin: 0.5rem 1rem;
  }
`
const DietRespected = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${colors.beige};
    z-index:-1;
`
const ContainerOutOfDiet = styled.div`
position: relative;
width: 100%;
margin-bottom:20px;
`
const InputFile = styled.label`
   background-color: ${colors.grey};
   border: 2px solid white;
   color: white;
   font-size: 1.5rem;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 80%;
   height: 150px;
   cursor: pointer;

   & span{
    font-size:3rem;
   }

   & p{
    font-size: 1rem;
   }

   & input{
    display: none
   }
`
const ValidSpan = styled.span`
   display: none;
`
const NextBtn = styled.div`
    background-color: ${colors.beige};
    display: inline-block;
    padding: 8px 24px;
    font-size: 2rem;
    color: #f6f6f6;
    cursor: pointer;
`
const PrevBtn = styled.div`
    display: inline-block;
    color: #666666;
    text-decoration: underline;
    cursor: pointer;
`
const NumbersForm = styled.div`
display: flex;
`
const PicForm = styled.div`
    display: none;
`
const TextForm = styled.div`
display: none;
`
const DivStatusBilan = styled.div`
    position : fixed;
    top:0px;
    width: 100vw;
    height: 100vh;
    background-color: ${colors.pink};
    color: white;
    right: 0;
    text-align: center;
    z-index: 3;
    justify-content: center;
    align-items: center;
    display: none;
    flex-direction:column;

`

const LoadingWrapper = styled.div`
    position: absolute;
    width:100%;
    height:100%;
    z-index:2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    & .lds-ripple div {
        position: absolute;
        border: 4px solid white;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1.2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }
`
export default function Bilan() {
    const [isLoading, setIsLoading] = useState(false)
    const [weight, setWeight] = useState("")
    const [chest, setChest] = useState("")
    const [waist, setWaist] = useState("")
    const [thigh, setThigh] = useState("")
    const [arm, setArm] = useState("")
    const [glutes, setGlutes] = useState("")
    const [calves, setCalves] = useState("")
    const [howWasTheWeek, setHowWasTheWeek] = useState("")
    const [modifMenuDone, setModifMenuDone] = useState("")
    const [outOfDiet, setOutOfDiet] = useState("")
    const [modifMenuWanted, setModifMenuWanted] = useState("")
    const [wishes, setWishes] = useState("")
    const [feeling, setFeeling] = useState("")
    const [weeklySteps, setWeeklySteps] = useState("")
    const [weeklyStepsDetails, setWeeklyStepsDetails] = useState("")
    const [weeklyWater, setWeeklyWater] = useState("")
    const [weeklyWaterDetails, setWeeklyWaterDetails] = useState("")
    const [weeklyTrainings, setWeeklyTrainings] = useState("")
    const [weeklyTrainingsDetails, setWeeklyTrainingsDetails] = useState("")
    const [weeklyCardio, setWeeklyCardio] = useState("")
    const [weeklyCardioDetails, setWeeklyCardioDetails] = useState("")
    const [extra, setExtra] = useState("")
    const [fileFront, setFileFront] = useState("")
    const [fileSide, setFileSide] = useState("")
    const [fileRear, setFileRear] = useState("")

    const [respectOfDiet, setRespectOfDiet] = useState(null)
    const [cheat, setCheat] = useState(null)
    const [questionModifMenuWanted, setQuestionModifMenuWanted] = useState(null)
    const [questionWishes, setQuestionWishes] = useState(null)

    let navigate = useNavigate();
    const dispatch = useDispatch()

    const userData = useSelector((state) => state.user)

    const handleBilan = async (e) => {
        e.preventDefault()
        if (userData._id && weight && fileFront && fileSide && fileRear) {
            setIsLoading(true)
            const statusBilanSelected = document.getElementById("statusBilan")
            const statusBilanMessage = document.getElementById("statusBilanMessage")

            statusBilanSelected.style.display = "flex"
            const data = new FormData()
            data.append('userId', userData._id)
            data.append('userPseudo', userData.pseudo)
            data.append('weight', weight)
            data.append('chest', chest)
            data.append('waist', waist)
            data.append('thigh', thigh)
            data.append('arm', arm)
            data.append('glutes', glutes)
            data.append('calves', calves)
            data.append('howWasTheWeek', howWasTheWeek)
            data.append('modifMenuDone', modifMenuDone)
            data.append('outOfDiet', outOfDiet)
            data.append('modifMenuWanted', modifMenuWanted)
            data.append('wishes', wishes)
            data.append('feeling', feeling)
            data.append('weeklySteps', weeklySteps)
            data.append('weeklyStepsDetails', weeklyStepsDetails)
            data.append('weeklyWater', weeklyWater)
            data.append('weeklyWaterDetails', weeklyWaterDetails)
            data.append('weeklyTrainings', weeklyTrainings)
            data.append('weeklyTrainingsDetails', weeklyTrainingsDetails)
            data.append('weeklyCardio', weeklyCardio)
            data.append('weeklyCardioDetails', weeklyCardioDetails)
            data.append('extra', extra)
            data.append('facePic', fileFront)
            data.append('profilPic', fileSide)
            data.append('backPic', fileRear)

            /* await axios
                .post(`${process.env.REACT_APP_API_URL}api/bilan`, data, { withCredentials: true })
                .then(async (res) => {
                    if (res.status === 201) {
                        const dataWeight = {
                            weight: weight
                        }
                        const resWeight = await axios.patch(`${process.env.REACT_APP_API_URL}api/user/weight/${userData._id}`, dataWeight, { withCredentials: true })
                        if (resWeight.status === 201) {
                            setIsLoading(false)
                            statusBilanMessage.innerHTML = "Bilan envoyé avec succes"
                            dispatch(changeWeight(dataWeight))
                            setTimeout(() => { navigate("/suivi", { replace: true }) }, 3000);
                        } else {
                            setIsLoading(false)
                            statusBilanMessage.innerHTML = "Erreur de poids"
                            setTimeout(() => { navigate("/", { replace: true }) }, 3000);
                        }
                    } else {
                        setIsLoading(false)
                        statusBilanMessage.innerHTML = "Erreur"
                        setTimeout(() => { navigate("/", { replace: true }) }, 3000);
                    }
                }); */

                const res = await axios.post(`${process.env.REACT_APP_API_URL}api/bilan`, data, { withCredentials: true })
                const status = res.status
                if (status === 201) {
                    console.log('Bilan uploaded');
                    console.log('Now uploading current weight');
                    const dataWeight = {
                        weight: weight
                    }
                    const resWeight = await axios.patch(`${process.env.REACT_APP_API_URL}api/user/weight/${userData._id}`, dataWeight, { withCredentials: true })
                    if (resWeight.status === 201) {
                        console.log('Weight uploaded');
                        setIsLoading(false)
                        statusBilanMessage.innerHTML = "Bilan envoyé avec succes"
                        dispatch(changeWeight(dataWeight))
                        setTimeout(() => { navigate("/suivi", { replace: true }) }, 3000);
                    } else {
                        setIsLoading(false)
                        statusBilanMessage.innerHTML = "Erreur de poids"
                        setTimeout(() => { navigate("/", { replace: true }) }, 3000);
                    }
                } else {
                    setIsLoading(false)
                    statusBilanMessage.innerHTML = "Erreur"
                    setTimeout(() => { navigate("/", { replace: true }) }, 3000);
                }

        } else {
            alert("Veille à remplir au minimum le poids et les photos. Nous avons besoin d'un bilan le plus complet possible afin d'optimiser ton suivi.")
        }
    }

    const handleFiles = async (e) => {
        let message = ""
        const MessageTarget = document.getElementById(e.target.id + "Message")
        const inputSelected = document.getElementById(e.target.id)
        const spanIconSelected = document.getElementById(e.target.id + "Span")
        const spanIconValid = document.getElementById(e.target.id + "SpanValid")

        if (e.target.files[0].size < 5000000) {
            if (e.target.files[0].type !== "image/jpg" &&
                e.target.files[0].type !== "image/png" &&
                e.target.files[0].type !== "image/jpeg") {
                inputSelected.parentElement.style.backgroundColor = "#ff6961"
                spanIconSelected.style.display = "block"
                spanIconValid.style.display = "none"
                inputSelected.value = null
                message = "Format incompatible"
            } else {
                switch (e.target.id) {
                    case 'fileFront':
                        setFileFront(e.target.files[0])
                        break;
                    case 'fileSide':
                        setFileSide(e.target.files[0])
                        break;
                    case 'fileRear':
                        setFileRear(e.target.files[0])
                        break;

                    default:
                        break;
                }
                //setFiles([...files, e.target.files[0]])
                inputSelected.parentElement.style.backgroundColor = colors.grey
                spanIconSelected.style.display = "none"
                spanIconValid.style.display = "block"
                message = e.target.files[0].name
            }
        } else {
            inputSelected.parentElement.style.backgroundColor = "#ff6961"
            spanIconSelected.style.display = "block"
            spanIconValid.style.display = "none"
            inputSelected.value = null
            //alert('image trop volumineuse')
            message = "Taille de l'image supérieure a 5Mo"

        }
        MessageTarget.innerHTML = message

    }


    //Gestion pages formulaire Bilan
    useEffect(() => {
        const textForm = document.querySelector(".divText")
        const imageForm = document.querySelector(".divImage")
        const questionForm = document.querySelector(".divQuestion")

        const next1 = document.getElementById('next-1')
        const next2 = document.getElementById('next-2')
        const previous2 = document.getElementById('previous-2')
        const previous3 = document.getElementById('previous-3')

        next1.addEventListener("click", function (e) {
            e.preventDefault()
            textForm.style.display = ("none")
            questionForm.style.display = ("flex")
        })

        next2.addEventListener("click", function (e) {
            e.preventDefault()
            questionForm.style.display = ("none")
            imageForm.style.display = ("flex")
        })

        previous2.addEventListener("click", function (e) {
            e.preventDefault()
            questionForm.style.display = ("none")
            textForm.style.display = ("flex")
        })

        previous3.addEventListener("click", function (e) {
            e.preventDefault()
            imageForm.style.display = ("none")
            questionForm.style.display = ("flex")
        })

    }, [])

    return (
        <BilanWrapper id='Bilan' action="" method="post" enctype="multipart/form-data">

            <h3>Nouveau bilan</h3>
            <DivStatusBilan id="statusBilan">
                {isLoading &&
                    <LoadingWrapper>
                        <span>Envoie en cours</span>
                        <div className="lds-ripple" >
                            <div></div>
                            <div></div>
                        </div></LoadingWrapper>}
                <span id="statusBilanMessage"></span>
            </DivStatusBilan>
            <NumbersForm className="divText">
                <InputWrapper>
                    <Input
                        type="number"
                        name="weight"
                        id="weight"
                        placeholder="Poids"
                        onChange={(e) => setWeight(e.target.value)}
                        value={weight}
                        required
                    />
                    <Label htmlFor="">
                        Poids
                    </Label>
                </InputWrapper>
                <Mensurations>
                    <InputWrapper>
                        <Input
                            type="number"
                            name=""
                            id=""
                            placeholder="Poitrine"
                            onChange={(e) => setChest(e.target.value)}
                            value={chest}
                            required
                        />
                        <Label htmlFor="">
                            Poitrine
                        </Label>
                    </InputWrapper>
                    <InputWrapper>
                        <Input
                            type="number"
                            name=""
                            id=""
                            placeholder="Taille"
                            onChange={(e) => setWaist(e.target.value)}
                            value={waist}
                            required
                        />
                        <Label htmlFor="">
                            Taille
                        </Label>
                    </InputWrapper>
                    <InputWrapper>
                        <Input
                            type="number"
                            name=""
                            id=""
                            placeholder="Cuisse"
                            onChange={(e) => setThigh(e.target.value)}
                            value={thigh}
                            required
                        />
                        <Label htmlFor="">
                            Cuisse
                        </Label>
                    </InputWrapper>
                    <InputWrapper>
                        <Input
                            type="number"
                            name=""
                            id=""
                            placeholder="Bras"
                            onChange={(e) => setArm(e.target.value)}
                            value={arm}
                            required
                        />
                        <Label htmlFor="">
                            Bras
                        </Label>
                    </InputWrapper>
                    <InputWrapper>
                        <Input
                            type="number"
                            name=""
                            id=""
                            placeholder="Fesses"
                            onChange={(e) => setGlutes(e.target.value)}
                            value={glutes}
                            required
                        />
                        <Label htmlFor="">
                            Fesses
                        </Label>
                    </InputWrapper>
                    <InputWrapper>
                        <Input
                            type="number"
                            name=""
                            id=""
                            placeholder="Mollet"
                            onChange={(e) => setCalves(e.target.value)}
                            value={calves}
                            required
                        />
                        <Label htmlFor="">
                            Mollets
                        </Label>
                    </InputWrapper>
                </Mensurations>
                <NextBtn className='btn-bilan-next' id="next-1">Suivant</NextBtn>
            </NumbersForm>

            <TextForm className="divQuestion">
                <InputWrapperText className="labelInput">
                    <Question htmlFor="howWasTheWeek">Comment s'est déroulée ta semaine ?</Question>
                    <Textarea
                        name="howWasTheWeek"
                        id=""
                        onChange={(e) => setHowWasTheWeek(e.target.value)}
                        value={howWasTheWeek}
                        required
                        wrap="hard"
                    ></Textarea>
                </InputWrapperText>

                <InputWrapperText className="labelInput">
                    <Question htmlFor="">As-tu respecté ton plan alimentaire ?</Question>
                    <RespectWeekContainer>
                        <RespectWeekBtn onClick={() => { setRespectOfDiet(true) }}>
                            {respectOfDiet === true && <DietRespected />}
                            <span>Oui</span>
                        </RespectWeekBtn>
                        <RespectWeekBtn onClick={() => { setRespectOfDiet(false) }} >
                            {respectOfDiet === false && <DietRespected />}
                            <span>Non</span>
                        </RespectWeekBtn>
                    </RespectWeekContainer>
                    {respectOfDiet === false &&
                        <InputWrapperText className="labelInput optionalQuestion">
                        <Question htmlFor="modifMenuDone">Quelles modifications as-tu faites ?</Question>
                        <Textarea
                                name="modifMenuDone"
                                id=""
                                onChange={(e) => setModifMenuDone(e.target.value)}
                                value={modifMenuDone}
                                required
                                wrap="hard"
                            ></Textarea>
                        </InputWrapperText>
                    }
                </InputWrapperText>

                <InputWrapperText className="labelInput">
                    <Question htmlFor="">As-tu fais des écarts ? </Question>
                    <RespectWeekContainer>
                        <RespectWeekBtn onClick={() => { setCheat(true) }}>
                            {cheat === true && <DietRespected />}
                            <span>Oui</span>
                        </RespectWeekBtn>
                        <RespectWeekBtn onClick={() => { setCheat(false) }} >
                            {cheat === false && <DietRespected />}
                            <span>Non</span>
                        </RespectWeekBtn>
                    </RespectWeekContainer>
                    {cheat === true &&
                        <InputWrapperText className="labelInput optionalQuestion">
                        <Question htmlFor="outOfDiet">Qu'as-tu mangé' ?</Question>
                        <Textarea
                                name="outOfDiet"
                                id=""
                                onChange={(e) => setOutOfDiet(e.target.value)}
                                value={outOfDiet}
                                required
                                wrap="hard"
                            ></Textarea>
                        </InputWrapperText>
                    }
                </InputWrapperText>

                <InputWrapperText className="labelInput">
                    <Question htmlFor="">Souhaites-tu modifier ton menu ? </Question>
                    <RespectWeekContainer>
                        <RespectWeekBtn onClick={() => { setQuestionModifMenuWanted(true) }}>
                            {questionModifMenuWanted === true && <DietRespected />}
                            <span>Oui</span>
                        </RespectWeekBtn>
                        <RespectWeekBtn onClick={() => { setQuestionModifMenuWanted(false) }} >
                            {questionModifMenuWanted === false && <DietRespected />}
                            <span>Non</span>
                        </RespectWeekBtn>
                    </RespectWeekContainer>
                    {questionModifMenuWanted === true &&
                        <InputWrapperText className="labelInput optionalQuestion">
                        <Question htmlFor="modifMenuWanted">Qu'aimerais-tu modifier ? </Question>
                        <Textarea
                                name="modifMenuWanted"
                                id=""
                                onChange={(e) => setModifMenuWanted(e.target.value)}
                                value={modifMenuWanted}
                                required
                                wrap="hard"
                            ></Textarea>
                        </InputWrapperText>
                    }
                </InputWrapperText>

                <InputWrapperText className="labelInput">
                    <Question htmlFor="">As-tu des envies particulières pour le prochain menu ? </Question>
                    <RespectWeekContainer>
                        <RespectWeekBtn onClick={() => { setQuestionWishes(true) }}>
                            {questionWishes === true && <DietRespected />}
                            <span>Oui</span>
                        </RespectWeekBtn>
                        <RespectWeekBtn onClick={() => { setQuestionWishes(false) }} >
                            {questionWishes === false && <DietRespected />}
                            <span>Non</span>
                        </RespectWeekBtn>
                    </RespectWeekContainer>
                    {questionWishes === true &&
                        <InputWrapperText className="labelInput optionalQuestion">
                        <Question htmlFor="wishes">Que souhaites-tu ?</Question>
                        <Textarea
                                name="wishes"
                                id=""
                                onChange={(e) => setWishes(e.target.value)}
                                value={wishes}
                                required
                                wrap="hard"
                            ></Textarea>
                        </InputWrapperText>
                    }
                </InputWrapperText>

                <InputWrapperText className="labelInput">
                    <Question htmlFor="ressenti">Décris ton ressenti par rapport à cette semaine (Sommeil, digestion, énergie, satiété,…)</Question>
                    <Textarea
                        name="ressenti"
                        id=""
                        onChange={(e) => setFeeling(e.target.value)}
                        value={feeling}
                        required
                        wrap="hard"
                    ></Textarea>
                </InputWrapperText>

                <InputWrapperText className="labelInput">
                    <Question htmlFor="">Quelle est ta moyenne hebdomadaire du nombre de pas par jour ?</Question>
                    <RespectWeekContainer>
                    <InputWrapper>
                        <Input
                            type="number"
                            name=""
                            id=""
                            placeholder="Pas"
                            onChange={(e) => setWeeklySteps(e.target.value)}
                            value={weeklySteps}
                            required
                        />
                        <Label htmlFor="">
                            Pas
                        </Label>
                    </InputWrapper>
                    </RespectWeekContainer>
                    {weeklySteps &&
                        <InputWrapperText className="labelInput optionalQuestion">
                        <Question htmlFor="weeklyStepsDetails">Veux-tu ajouter un détail ?</Question>
                        <Textarea
                                name="weeklyStepsDetails"
                                id=""
                                onChange={(e) => setWeeklyStepsDetails(e.target.value)}
                                value={weeklyStepsDetails}
                                required
                                wrap="hard"
                            ></Textarea>
                        </InputWrapperText>
                    }
                </InputWrapperText>


                <InputWrapperText className="labelInput">
                    <Question htmlFor="">Quelle est ta consomation moyenne d'eau par jour ?</Question>
                    <RespectWeekContainer>
                    <InputWrapper>
                        <Input
                            type="number"
                            name=""
                            id=""
                            placeholder="Litres"
                            onChange={(e) => setWeeklyWater(e.target.value)}
                            value={weeklyWater}
                            required
                        />
                        <Label htmlFor="">
                        Litres
                        </Label>
                    </InputWrapper>
                    </RespectWeekContainer>
                    {weeklyWater && 
                        <InputWrapperText className="labelInput optionalQuestion">
                        <Question htmlFor="weeklyWaterDetails">Veux-tu ajouter un détail ?</Question>
                        <Textarea
                                name="weeklyWaterDetails"
                                id=""
                                onChange={(e) => setWeeklyWaterDetails(e.target.value)}
                                value={weeklyWaterDetails}
                                required
                                wrap="hard"
                            ></Textarea>
                        </InputWrapperText>
                    }
                </InputWrapperText>

                <InputWrapperText className="labelInput">
                    <Question htmlFor="">Combien de fois t'es-tu entrainé cette semaine ?</Question>
                    <RespectWeekContainer>
                    <InputWrapper>
                        <Input
                            type="number"
                            name=""
                            id=""
                            placeholder="Entrainements"
                            onChange={(e) => setWeeklyTrainings(e.target.value)}
                            value={weeklyTrainings}
                            required
                        />
                        <Label htmlFor="">
                        Entrainements
                        </Label>
                    </InputWrapper>
                    </RespectWeekContainer>
                    {weeklyTrainings && 
                        <InputWrapperText className="labelInput optionalQuestion">
                        <Question htmlFor="weeklyTrainingsDetails">Veux-tu ajouter un détail ?</Question>
                        <Textarea
                                name="weeklyTrainingsDetails"
                                id=""
                                onChange={(e) => setWeeklyTrainingsDetails(e.target.value)}
                                value={weeklyTrainingsDetails}
                                required
                                wrap="hard"
                            ></Textarea>
                        </InputWrapperText>
                    }
                </InputWrapperText>

                <InputWrapperText className="labelInput">
                    <Question htmlFor="">Combien de temps as-tu fais du cardio ?</Question>
                    <RespectWeekContainer>
                    <InputWrapper>
                        <Input
                            type="number"
                            name=""
                            id=""
                            placeholder="Minutes"
                            onChange={(e) => setWeeklyCardio(e.target.value)}
                            value={weeklyCardio}
                            required
                        />
                        <Label htmlFor="">
                        Minutes
                        </Label>
                    </InputWrapper>
                    </RespectWeekContainer>
                    {weeklyCardio && 
                        <InputWrapperText className="labelInput optionalQuestion">
                        <Question htmlFor="weeklyCardioDetails">Veux-tu ajouter un détail ?</Question>
                        <Textarea
                                name="weeklyCardioDetails"
                                id=""
                                onChange={(e) => setWeeklyCardioDetails(e.target.value)}
                                value={weeklyCardioDetails}
                                required
                                wrap="hard"
                            ></Textarea>
                        </InputWrapperText>
                    }
                </InputWrapperText>


                <InputWrapperText className="labelInput">
                    <Question htmlFor="extra">As-tu des remarques à partager ? </Question>
                    <Textarea
                        name="extra"
                        id=""
                        onChange={(e) => setExtra(e.target.value)}
                        value={extra}
                        required
                        wrap="hard"
                    ></Textarea>
                </InputWrapperText>

                <NextBtn className='btn-bilan-next' id="next-2">
                    Suivant
                </NextBtn>
                <PrevBtn className='btn-bilan-previous' id="previous-2">
                    <BsArrowLeftShort />
                    Retour
                </PrevBtn>
            </TextForm>
            <PicForm className="divImage">
                <InputFile htmlFor="fileFront">
                    Photo de face
                    <br />
                    <span id="fileFrontSpan">
                        <BiImageAdd />
                    </span>
                    <ValidSpan id="fileFrontSpanValid">
                        <BsCheckAll />
                    </ValidSpan>
                    <p id="fileFrontMessage"></p>
                    <input
                        type="file"
                        id="fileFront"
                        name="fileFront"
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => handleFiles(e)}
                        required
                    />
                </InputFile>
                <InputFile htmlFor="fileSide">
                    Photo de profil
                    <br />
                    <span id="fileSideSpan">
                        <BiImageAdd />
                    </span>
                    <ValidSpan id="fileSideSpanValid">
                        <BsCheckAll />
                    </ValidSpan>
                    <p id="fileSideMessage"></p>
                    <input
                        className="image1"
                        type="file"
                        id="fileSide"
                        name="fileSide"
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => handleFiles(e)}
                        required
                    />
                </InputFile>
                <InputFile htmlFor="fileRear">
                    Photo de dos
                    <br />
                    <span id="fileRearSpan">
                        <BiImageAdd />
                    </span>
                    <ValidSpan id="fileRearSpanValid">
                        <BsCheckAll />
                    </ValidSpan>
                    <p id="fileRearMessage"></p>
                    <input
                        type="file"
                        id="fileRear"
                        name="fileRear"
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => handleFiles(e)}
                        required
                    />
                </InputFile>

                <NextBtn
                    className='btn-bilan-submit'
                    id="submit-bilan"
                    onClick={(e) => handleBilan(e)}
                >Envoyer mon bilan
                </NextBtn>
                <PrevBtn className='btn-bilan-previous' id="previous-3">
                    <BsArrowLeftShort />
                    Retour
                </PrevBtn>

            </PicForm>
        </BilanWrapper>
    )
}

