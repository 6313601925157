import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import LogPage from "./LogPage";


const  Confirm = () => {
    const navigate = useNavigate()
    const verifyUser = async (code) => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/user/confirm/${code}`);
        return response;
    }

    const code = useParams().code
    if (code) {
        verifyUser(code)
        setTimeout(() => {
          navigate("/login", { replace: true }) 
      }, 0);
    }

    return <LogPage signin="true" signup="" justValided={true} />   

}

export default Confirm