import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { BiSearchAlt } from "react-icons/bi"
import colors from "../../../UIKit/colors"
import { addNewIngredient, deleteIngredientFromList, getAllIngredients, removeIngredientFromList, setAlternativeIngredient } from "../../../../features/dietCreator"
import axios from "axios"
import { AiOutlineCloseCircle } from "react-icons/ai"
//import { alim } from "../dataAlim"

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const SearchInputContainer = styled.div`
    background-color: white;
    border-radius: 3rem;
    padding : 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
`
const SearchInputLabel = styled.label`
    margin-top: 0.5rem;
    color: ${colors.grey};
    font-size: 1.5rem;
`
const SearchInput = styled.input`
    border : unset;
    color: ${colors.grey};
    font-size: 1.2rem;
    
    
    &::placeholder{
        text-align: center;
        color: ${colors.grey};
        font-size: 1.2rem;
      }

      &:focus {
        outline: none;
        
    }
`
const AlimListe = styled.div`
    width: 95%;
    max-width: 95%;
    max-height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
`
const Alim = styled.div`
    display: none;
    justify-content: flex-end;
    padding: 0.5rem 0px 0.1rem;
    font-size: 1.2rem;
    border-bottom: 1px solid #887a7194;

    &:nth-child(1){
        margin-top: 1rem;
    }
`
const AlimTitle = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 0px 0.1rem;
    font-size: 1.2rem;
    color : #887a71;
    border-bottom: 2px solid #887a7194;
    width: 95%;
`
const AlimData = styled.div`
    width : 13%;
`
const DeleteAlimBtn = styled.div`
    width: 5%;
`
const AlimName = styled.div`
    flex: 1;
`
const AddQuantity = styled.div`

    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;


 & input{
    border : unset;
    color: ${colors.grey};
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border-radius: 1.6rem;
    
    
    &::placeholder{
        text-align: center;
        color: ${colors.grey};
        font-size: 1.2rem;
      }

      &:focus {
        outline: none;
        
    }
 }

`
const InfoContainer = styled.div`
    display: flex;
    width : 90%;
    justify-content: space-around;
`

const Info = styled.div`
    text-align: center;
`

const InfoName = styled.div`
    width: 100%;
    text-align: center;
`

const ValidQuantityBtn = styled.input`
    margin: 0 auto 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    border: 2px ${colors.grey} solid;
    border-radius: 50%;
    color: ${colors.grey};
    font-size: 1.2rem;
`



export default function AddAlternativeIngredient(props) {
    const selectedMeal = useSelector((state) => state.dietCreator.selectedMeal)
    const selectedMealIngredients = useSelector((state) => state.dietCreator.meals[selectedMeal].ingredients)
    const alim = useSelector((state) => state.dietCreator.ingredientList)
    const [showQuantity, setShowQuantity] = useState(false)
    const [alimentData, setAlimentData] = useState(null)
    const [number, setNumber] = useState('')
    const [ingredientSelected, setIngredientSelected] = useState(false)
    const dispatch = useDispatch()

    const fetchIngredients = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}api/diet/getAllIngredients`, { withCredentials: true })
        const data = await res.data
        if (res.status == 200) {
            dispatch(getAllIngredients(data))
        }
    }

    const handleDeleteIngredient = async (id) => {
        const res = await axios.delete(`${process.env.REACT_APP_API_URL}api/diet/deleteIngredient/${id}`, { withCredentials: true })
        if (res.status == 200) {
            dispatch(deleteIngredientFromList(id))
        }
    }
    if (!alim) {
        console.log('need to fetch alim');
        fetchIngredients()
    }


    const [calculatedValue, setCalculatedValue] = useState({
        quantity: null,
        cal: null,
        fat: null,
        carb: null,
        prot: null,
    })

    const search = (value) => {
        const allDiv = document.querySelectorAll('.alimentListe')

        for (let i = 0; i < alim.length; i++) {
            const element = alim[i];
            const name = element.name.toUpperCase()
            if (name.indexOf(value.toUpperCase()) > -1) {
                allDiv[i].style.display = "flex";
            } else {
                allDiv[i].style.display = "none";
            }

        }
    }

    const handleAddAlim = (aliment) => {
        setAlimentData({
            'index': selectedMeal,
            'ingredient': {
                name: aliment.name,
                quantity: 100,
                cal: aliment.cal,
                fat: aliment.fat,
                carb: aliment.carb,
                prot: aliment.prot,
            }
        })
        setCalculatedValue({ quantity: 100 })
        setShowQuantity(true)
    }

    const handleChangeQtt = (quantity) => {
        setCalculatedValue({ quantity: quantity })
    }

    const handleValidQuantity = () => {
        dispatch(setAlternativeIngredient({
            'index': selectedMeal,
            'ingredientToChange': ingredientSelected,
            'alternative': {
                name: alimentData.ingredient.name,
                quantity: calculatedValue.quantity,
                cal: alimentData.ingredient.cal,
                fat: alimentData.ingredient.fat,
                carb: alimentData.ingredient.carb,
                prot: alimentData.ingredient.prot,
                number: number,
            }
        }))
        setShowQuantity(false)
    }

    return (
        <Wrapper>
            <>
                {selectedMealIngredients?.map((ingredient, index) => (
                    <div onClick={() => {setIngredientSelected(ingredient.name)}}>
                        {ingredient.name}
                    </div>
                    ))}
                </>
            <SearchInputContainer>
                <SearchInputLabel htmlFor="searchInput">
                    <BiSearchAlt />
                </SearchInputLabel>
                <SearchInput
                    id="searchInput"
                    autoFocus
                    type="text"
                    placeholder="Rechercher un aliment"
                    onChange={(e) => {
                        search(e.target.value)
                    }} />
            </SearchInputContainer>
            <AlimTitle>
                <AlimName>Nom</AlimName>
                <AlimData>Kcal</AlimData>
                <AlimData>Prot</AlimData>
                <AlimData>Glu</AlimData>
                <AlimData>Gra</AlimData>
                <DeleteAlimBtn></DeleteAlimBtn>
            </AlimTitle>
            <AlimListe>
                {alim?.map((aliment, index) => (
                    <Alim
                        className="alimentListe"
                        key={index}
                        onClick={(e) => {
                            handleAddAlim(aliment)
                        }}>
                        <AlimName>{aliment.name}</AlimName>
                        <AlimData>{aliment.cal}</AlimData>
                        <AlimData>{aliment.prot}</AlimData>
                        <AlimData>{aliment.carb}</AlimData>
                        <AlimData>{aliment.fat}</AlimData>
                        <DeleteAlimBtn><AiOutlineCloseCircle onClick={() => handleDeleteIngredient(aliment._id)}/></DeleteAlimBtn>
                    </Alim>
                ))}
            </AlimListe>
            {showQuantity &&
                <form>
                    <InfoName>{alimentData.ingredient.name}</InfoName>
                    <InfoContainer>
                        <Info>Kcal</Info>
                        <Info>Prot</Info>
                        <Info>Glu</Info>
                        <Info>Gra</Info>
                    </InfoContainer>
                    <InfoContainer>
                        <Info>
                            {alimentData.ingredient.cal * calculatedValue.quantity / 100}
                        </Info>
                        <Info>
                            {alimentData.ingredient.prot * calculatedValue.quantity / 100}
                        </Info>
                        <Info>
                            {alimentData.ingredient.carb * calculatedValue.quantity / 100}
                        </Info>
                        <Info>
                            {alimentData.ingredient.fat * calculatedValue.quantity / 100}
                        </Info>
                    </InfoContainer>
                    <AddQuantity>
                        <input type="number" placeholder="Poids :" onChange={(e) => handleChangeQtt(e.target.value)} />
                        <input type="text" placeholder="Nombre :" onChange={(e) => setNumber(e.target.value)} />
                    </AddQuantity>
                    <ValidQuantityBtn
                        type="submit"
                        value="Ajouter"
                        onClick={(e) => handleValidQuantity()}
                    />
                </form>
            }
        </Wrapper>
    )
}