import colors from "../colors"
import styled from "styled-components"
import { Link } from "react-router-dom"

const MainBtnLink = styled(Link)`
    background-color: ${colors.beige};
    display: inline-block;
    padding: 8px 24px;
    font-size: 2rem;
    color: #f6f6f6;
`

export default function MainBtn(props) {
    const {text, link} = props

    return(
        <MainBtnLink to={link}>
                {text}
        </MainBtnLink>
    )
}