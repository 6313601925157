import styled from "styled-components"
import colors from "../UIKit/colors"
import { useSelector } from "react-redux"

export default function DoubleWidget(){
    const Wrapper = styled.div`
        margin: auto;
        width: 90%;
        display: flex;
        justify-content: space-between;
        margin-bottom:1rem;

        &>div{
            background-color: ${colors.beige};
        }

        &>.pinkBtnDoubleBtn{
            background-color: ${colors.pink};
        }
    `
    const BtnWrap = styled.div`
        width: 48%;
        display: flex;
        flex-direction: column;
        align-items: center; 
        color: white;
    `

    const MainTxt = styled.div`
        font-size: 2rem;

        & span{
            font-size:1rem;
        }
    `

    const Label = styled.div`
        font-family: 'Oswald', sans-serif;
    `

    const userData = useSelector((state) => state.user)


    return(
        <Wrapper>
            <BtnWrap>
                <MainTxt>
                {userData.start 
                        ? userData.start + " kg"
                        : <><span>Définir...</span></>
                        }
                </MainTxt>
                <Label>
                    Poids de départ
                </Label>
            </BtnWrap>
            <BtnWrap className="pinkBtnDoubleBtn">
                <MainTxt>
                    {userData.weight 
                        ? userData.weight + " kg"
                        : <><span>Définir...</span></>
                        }
                </MainTxt>
                <Label>
                    Poids actuel
                </Label>
            </BtnWrap>
        </Wrapper>
    )
}