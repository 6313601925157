import React from "react";
import { TbLogout } from "react-icons/tb";
import styled from "styled-components";
import colors from "../UIKit/colors";
import Cookies from "js-cookie";

const Wrapper = styled.div`
  display : flex;
  align-items: center;
  color: ${colors.grey};
  font-size: 1.2rem;
  padding: 0.2rem; 
  margin-bottom: 1rem;
  gap : 0.5rem;
`

const Logout = () => {
  

  const logout = async () => {
    Cookies.remove('token')
    
    window.location = "/";
  };

  return (
    <Wrapper onClick={logout}>
        <TbLogout /> <>Déconnexion</>
    </Wrapper>
  );
};

export default Logout;