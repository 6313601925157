import styled from 'styled-components'


import BilanNav from "./UIKit/nav/BilanNavSVG"
import DietNav from "./UIKit/nav/DietNavSvg"
import DocNav from "./UIKit/nav/DocNavSvg"
import HomeNav from "./UIKit/nav/HomeNavSvg"
import TrainingNav from "./UIKit/nav/TrainingNavSvg"

const dataNav = {
    "link1": "",
    "link2": "training",
    "link3": "diet",
    "link4": "documents",
    "linkCenter": "bilan"
}

const Nav = styled.nav`
    z-index: 2;
    position: fixed;
    bottom: 0px;
    display: flex;
    width: 100%;
    height: 70px;
    align-items: center;
    justify-content: space-around;

    & * {
        z-index:2;
    }

    @media (min-width: 780px) {
        bottom: unset;
        top: 0px;
        left: 0px;
        width: unset;
        flex-direction: column;
        height: 100%;
      }
`
const Blur = styled.div`
    background: rgba(255, 255, 255, 0.2); // Make sure this color has an opacity of less than 1
    backdrop-filter: blur(8px); // This be the blur
    height: 100%;
    width: 100%;
    position:absolute;
    z-index:0;
`

const navBar = () => {
    const { link1, link2, link3, link4, linkCenter } = dataNav

    return (
        <Nav>
            <Blur></Blur>
            <HomeNav link={link1} />
            <TrainingNav link={link2} />
            <BilanNav link={linkCenter} />
            <DietNav link={link3} />
            <DocNav link={link4} />
        </Nav>
    )
}

export default navBar
