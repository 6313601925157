import MainWidget from '../UIKit/mainWidget'
import pic from '../../images/trainingBG.jpg'

const link = '/training'
const title = 'Entrainement'

export default function TrainingWidget(){
    return (
            <MainWidget pic={pic} link={link} title={title} />
    )
   
}