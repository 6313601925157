import styled from 'styled-components'
import { NavLink, useLocation} from 'react-router-dom'
import colors from '../colors'

const NavIconLink = styled(NavLink)`
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 780px) {
        width: 75px;
        height: 75px;
    }
`


export default function HomeNavSvg(props){
    const {link} =props
    
    //colors navIcon
    const path = useLocation().pathname
    let fill = ''
    let stroke = colors.darkBrown
    if (path === `/${link}`) {
        fill = colors.pink
    }

    return(
        <NavIconLink to={`/${link}`}>
            <svg width="45" height="39" viewBox="0 0 45 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.74016 7.81476L7.16704 8.0751L7.42738 7.64822L11.1491 1.54574C11.4831 0.99803 12.1832 0.830026 12.7003 1.14534L15.4593 2.828C15.9786 3.14472 16.1478 3.84208 15.8134 4.39038L6.07973 20.3507C5.74248 20.9037 5.04904 21.0685 4.52857 20.7511L1.76952 19.0684C1.25135 18.7524 1.07849 18.0585 1.41541 17.5061L1.41758 17.5024L5.08756 11.3684L5.3421 10.943L4.91883 10.6848C4.20667 10.2505 3.94435 9.22156 4.44287 8.40413C4.93743 7.59322 5.96824 7.34399 6.74016 7.81476ZM26.9732 20.1542L27.4001 20.4146L27.6604 19.9877L33.6724 10.1298C34.0075 9.58037 34.7082 9.41519 35.2235 9.72945L37.9826 11.4121C38.5025 11.7292 38.6725 12.4238 38.3367 12.9745L24.0225 36.4455C23.687 36.9956 22.9889 37.1616 22.4713 36.8459L19.7123 35.1633C19.1947 34.8476 19.0227 34.151 19.3581 33.6009L25.3701 23.7431L25.6305 23.3162L25.2036 23.0558L17.8461 18.5688L17.4192 18.3084L17.1589 18.7353L11.1469 28.5931C10.8097 29.1461 10.1162 29.311 9.59575 28.9935L6.8367 27.3109C6.32083 26.9963 6.14603 26.3003 6.48257 25.7485L20.7968 2.27745C21.1319 1.72798 21.8327 1.56279 22.348 1.87706L25.107 3.55971C25.6269 3.87678 25.797 4.57144 25.4612 5.12209L19.4492 14.9799L19.1888 15.4068L19.6157 15.6672L26.9732 20.1542ZM39.6822 27.3194L39.4219 27.7463L39.8487 28.0066C40.6221 28.4783 40.8711 29.5077 40.3764 30.3189C39.8828 31.1283 38.8508 31.3789 38.0791 30.9082L37.6522 30.6479L37.3919 31.0748L33.6702 37.1773C33.3347 37.7273 32.6366 37.8933 32.119 37.5777L29.36 35.895C28.8424 35.5793 28.6704 34.8827 29.0059 34.3326L38.7396 18.3723C39.0732 17.8252 39.776 17.658 40.2907 17.9719L43.0498 19.6546C43.5691 19.9713 43.7383 20.6686 43.4039 21.2169L39.6822 27.3194Z"
                    stroke={stroke}
                    fill={fill}    
                />
            </svg>
        </NavIconLink>
    )
}