import axios from "axios";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components"
import Loading from "../components/Admin/Loading";
import colors from "../components/UIKit/colors";
import logo from "../images/logo.png"


const Body = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Logo = styled.img`
    margin: 2rem auto;
    width: 150px;
`
const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-top:50px;
    position: relative;
`

const Wrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 300px;
  border: none;
  border-bottom: 1px solid #757575;
  &:focus {
    outline: none;
    border-bottom: 2px solid ${colors.beige};
  }
  &::placeholder{
    color: transparent;
  }
`;

const Label = styled.label`
  color: #999;
  font-size: 1rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  ${Input}:focus ~ & {
    top: -18px;
    font-size: 1.2rem;
    color: ${colors.beige};
  }
  ${Input}:not(:placeholder-shown) ~ & {
    top: -18px;
    font-size: 1.2rem;
    color: ${colors.beige};
  }
`;
const SubmitBtn = styled.input`
  background-color: ${colors.beige};
  border: unset;
  padding: 5px 10px;
  font-size: 1.1rem;
  color: ${colors.darkBrown};
  margin: auto;
`

export default function RestorePassword() {
    const [password, setPassword] = useState("");
    const [controlPassword, setControlPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const code = useParams().code

    const handleRestore = async (e) => {
        if (!code) {
            alert('Lien invalide')
        }
        e.preventDefault();
        setIsLoading(true)
        const passwordError = document.querySelector(".password.error");
        const passwordConfirmError = document.querySelector(
            ".password-confirm.error"
        );

        passwordConfirmError.innerHTML = "";

        if (password !== controlPassword) {
            passwordConfirmError.innerHTML = "Les mots de passe ne correspondent pas";
            setIsLoading(false)
        }
        else {
            await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_URL}api/user/changePass/${code}`,
                data: {
                    "password" : password
                },
            })
                .then((res) => {
                    setIsLoading(false)
                    console.log(res);
                    if (res.status === 201) {
                        navigate("/", { replace: true })
                    }
                })
                .catch((err) => {
                    if (err.response.data) {
                        console.log(err.response);
                        passwordError.innerHTML = err.response.data.errors.password
                    } else {
                        passwordError.innerHTML = "Malheureusement une erreur s'est produite. Merci de réessayer plus tard."
                    }
                    setIsLoading(false)
                });
        }
    }


    return (
        <Body>
            <Logo src={logo} alt="Logo donuts musclé" />
                {isLoading && <Loading />}
            <Form action="" onSubmit={handleRestore} id="sign-up-form">
                <Wrapper>
                    <Input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        key={`inputPass`}
                    />
                    <Label htmlFor="password">Mot de passe</Label>
                </Wrapper>
                <div className="password error"></div>
                <br />
                <Wrapper>
                    <Input
                        type="password"
                        id="password-conf"
                        name="password-conf"
                        placeholder="Confirmer mot de passe"
                        onChange={(e) => setControlPassword(e.target.value)}
                        value={controlPassword}
                        key={`inputPass`}
                    />
                    <Label htmlFor="password-conf">Confirmer mot de passe</Label>
                </Wrapper>
                <br />
                <div className="password-confirm error"></div>
                <br />
                <SubmitBtn type="submit" value="Valider" />
            </Form>
        </Body>
    )
}