import styled from 'styled-components'
import { NavLink, useLocation} from 'react-router-dom'
import colors from '../colors'

const NavIconLink = styled(NavLink)`
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 780px) {
        width: 75px;
        height: 75px;
    }
`


export default function HomeNavSvg(props){
    const {link } =props

    //colors navIcon
    const path = useLocation().pathname
    let fill = ''
    let stroke = colors.darkBrown
    if (path === `/${link}`) {
        fill = colors.pink
    }

    return(
        <NavIconLink to={`/${link}`}>
             <svg width="44" height="39" viewBox="0 0 44 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M43.4884 19.4011L43.4847 19.4314V19.4619C43.4847 20.5475 42.5722 21.407 41.5403 21.407H39.0958H38.5936L38.5958 21.9092L38.6493 34.1021C38.6493 34.1026 38.6493 34.103 38.6493 34.1034C38.6492 34.2916 38.6358 34.4697 38.6142 34.6638L38.6111 34.6914V34.7191V35.9531C38.6111 37.359 37.469 38.5 36.0556 38.5H34.8735C34.8614 38.4966 34.841 38.491 34.8178 38.4857C34.772 38.4753 34.6912 38.4605 34.5935 38.4704C34.5869 38.4711 34.5802 38.4719 34.5736 38.4728C34.4731 38.4602 34.3878 38.4714 34.3332 38.4811C34.3093 38.4853 34.2878 38.49 34.2731 38.4932C34.257 38.4968 34.25 38.4984 34.2423 38.5H29.9444C28.531 38.5 27.3889 37.359 27.3889 35.9531V29.25C27.3889 27.6243 26.0713 26.3125 24.4444 26.3125H19.5556C17.9287 26.3125 16.6111 27.6243 16.6111 29.25V35.9531C16.6111 37.359 15.4689 38.5 14.0556 38.5H9.78542C9.69021 38.5 9.59222 38.4937 9.47484 38.4859L9.43752 38.4834L9.40026 38.4865C9.30507 38.4944 9.23412 38.5 9.16667 38.5H7.94444C6.53173 38.5 5.38889 37.359 5.38889 35.9531V27.4219C5.38889 27.3633 5.39088 27.2983 5.39465 27.2419L5.39576 27.2253V27.2086V21.907V21.407H4.89576H2.44826C1.3471 21.407 0.5 20.5569 0.5 19.4619C0.5 18.9096 0.676624 18.4326 1.10606 17.9995L20.678 0.987968L20.6911 0.976574L20.7034 0.964297C21.0991 0.568947 21.5465 0.5 22.0306 0.5C22.4798 0.5 22.9657 0.629453 23.3195 0.91617L42.8148 18.0097L42.8157 18.0105C43.3483 18.4752 43.5458 18.9331 43.4884 19.4011ZM34.8964 38.5064L34.8959 38.5062C34.8983 38.5068 34.8986 38.507 34.8964 38.5064Z" 
                    stroke={stroke}
                    fill={fill}    
                />
            </svg>
        </NavIconLink>
    )
}