import axios from "axios"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import colors from "../components/UIKit/colors"
import { selectedUser } from '../utils/selectors'
import FormInfos from "../components/Admin/user/FormInfos"

const Wrapper = styled.div`
    width : 100%;
    overflow-x: hidden;
`
const Name = styled.div`
    margin-top : 1rem;
    width: 100%;
    text-align: center;
    font-size : 2rem;
    color : ${colors.beige}
`
const AlimList = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    background-color: ${colors.greenPastel};

    &.nok{
        background-color: ${colors.redPastel};
    }
`
const ListTtitle = styled.div`
    font-size: 1.2rem;
`

export default function UserInfosAdmin() {
    const userData = useSelector(selectedUser)
    const [showForm, setShowForm] = useState(false)

    return (
        <Wrapper>
            <Name>{userData.name} {userData.firstName}</Name>
            <div>Poids :</div>
            <div>{userData.weight}</div>
            <AlimList>
                <ListTtitle>Aliments favoris</ListTtitle>
                {userData?.alimOk?.map((alim, index) => {
                return (
                    <div key={index}>{alim}</div>
                )
            })}</AlimList>
            <br />
            <AlimList className="nok">
            <ListTtitle>Aliments favoris</ListTtitle>
            {userData?.alimNok?.map((alim, index) => {
                return (
                    <div key={index}>{alim}</div>
                )
            })}</AlimList>
            <div onClick={() => setShowForm(!showForm)}>Formulaire</div>
            {showForm ? <FormInfos /> : <></>}
        </Wrapper>
    )
}