import styled from "styled-components"
import colors from "../UIKit/colors"

const MotivationDiv = styled.div`
    color : ${colors.grey};
    width: 90%;
    margin: 0.5rem auto 1rem;
`

export default function Motivation(){

    const motivationalSentences = [
        "La différence entre ce que tu as et ce que tu veux c'est ce que tu fais.",
        "N'oublie pas de te dépasser aujourd'hui",
        "No pain no gain",
        "Si tu veux quelque chose que tu n'as jamais eu, fais quelque chose que tu n'as jamais fait.",
        "Tout se passe en dehors de ta zone de confort.",
        "Se dépasser quand tout va bien, se surpasser quand tout va mal.",
        "Leve toi avec détermination, couche toi avec satisfaction.",
        "La différence entre ce que tu as et ce que tu veux c'est ce que tu fais.",
    ]
    const date = new Date()
    const currentDayofWeek = date.getDay()

    return (
        <MotivationDiv className="motivation">
            {motivationalSentences[currentDayofWeek]}
        </MotivationDiv>
    )
}