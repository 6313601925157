import React from "react"
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import AdminRoute from "./AdminRoute";


import styled from 'styled-components'
import Home from "../../pages/Home";
import Bilan from "../../pages/Bilan";
import Profil from "../../pages/Profil";
import TrainingList from "../../pages/TrainingList"
import Start from "../../pages/Start";
import LogPage from "../../pages/LogPage"
import AdminDashboard from "../../pages/AdminDashboard"
import AdminDashboardUser from "../Admin/user/AdminDashnoardUser"

import WithNav from "./withNav"
import WithSmallNav from "./withSmallNav"
import WithoutNav from "./withoutNav"
import Suivi from "../../pages/Suivi";
import BilanInfo from "../../pages/BilanInfo";
import BilanInfoAdmin from "../Admin/bilan/BilanInfoAdmin";
import Diet from "../../pages/Diet";
import Documents from "../../pages/Documents";
import Formulaire from "../../pages/Formulaire";
import Confirm from "../../pages/Confirm";
import RestorePassword from "../../pages/RestorePassword";
import DietAdmin from "../../pages/DietAdmin";
import UserInfosAdmin from "../../pages/UserInfosAdmin";
import NewDiet from "../Admin/diet/NewDiet";
import TrainingAdmin from "../../pages/TrainingAdmin";
import DietDisplay from "../../pages/DietDisplay";
import { RecettesAdmin } from "../../pages/RecettesAdmin";
import { RecetteDisplay } from "../../pages/RecetteDisplay";
import Recettes from "../../pages/Recettes";
import NewTraining from "../Admin/training/NewTraining";
import FormEasy from "../../pages/FormEasy";

const ContainerRoutes = styled(Routes)`
      width: 100vw;
      max-width: 100vw;
      overflow-x: hidden;
`


export default function Routing() {

  return (
    <Router>
      <ContainerRoutes>
        <Route element={<ProtectedRoute />}>
          <Route element={<WithNav />}>
            <Route path="/" element={<Home />} />
            <Route path='/bilan' element={<Bilan />} />
            <Route path='/profil' element={<Profil />} />
            <Route path="/diet" element={<Diet />} />
            <Route path='/training' element={<TrainingList />} />
            <Route path='/suivi' element={<Suivi />} />
            <Route path="/suivi/:bilanId" element={<BilanInfo />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/formulaire" element={<FormEasy />} />
            <Route path="*" element={<Home />} />
          </Route>
          <Route element={<AdminRoute />}>
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/admin/:userId" element={<AdminDashboardUser />} />
            <Route path="/admin/bilan/:bilanId" element={<BilanInfoAdmin />} />
            <Route path="/admin/diet" element={<DietAdmin />} />
            <Route path="/admin/newDiet" element={<NewDiet />} />
            <Route path="/admin/form/:userId" element={<UserInfosAdmin />} />
            <Route path="/admin/training" element={<TrainingAdmin />} />
            <Route path="/admin/training/new" element={<NewTraining />} />
            <Route path="/admin/recipe" element={<RecettesAdmin />} />
          </Route>
        </Route>

        <Route element={<WithSmallNav />}>
            <Route path="/diet/:dietId" element={<DietDisplay />} />
          </Route>

        <Route element={<WithoutNav />}>
          <Route path='/start' element={<Start />} />
          <Route path='/register' element={<LogPage signin="" signup="true" />} />
          <Route path='/login' element={<LogPage signin="true" signup="" />} />
          <Route path='/confirm/:code' element={<Confirm />} />
          <Route path="/changePassword/:code" element={<RestorePassword />} />
          <Route path="/recipe/:recipeId" element={<RecetteDisplay />} />
          <Route path="/recipe" element={<Recettes />} />
          <Route path="/easyForm/:userId" element={<FormEasy />} />
        </Route>
      </ContainerRoutes>
    </Router>
  )
}

