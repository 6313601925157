import { createAction, createReducer } from "@reduxjs/toolkit"

export const addMeal = createAction('ADD_MEAL');
export const removeMeal = createAction('REMOVE_MEAL');
export const addRecipe = createAction('ADD_RECIPE');
export const addIngredient = createAction('ADD_INGREDIENT');
export const addAlternativeIngredient = createAction('ADD_ALTERNATIVE_INGREDIENT');
export const changeIngredient = createAction('CHANGE_INGREDIENT');
export const changeIngredientNumber = createAction('CHANGE_INGREDIENT_NUMBER');
export const removeIngredient = createAction('DELETE_INGREDIENT');
export const updateSelectedMeal = createAction('UPDATE_SELECTED_MEAL')
export const updateNbOfMeals = createAction('UPDATE_NB_OF_MEALS')
export const total = createAction('UPDATE_TOTAL')
export const setTitle = createAction('SET_TITLE')
export const logNewIngredient = createAction('ADD_INGREDIENT_DATA')
export const getIngredients = createAction('GET_ALL_INGREDIENTS')
export const removeIngredientFromList = createAction('DELETE_INGREDIENT_FROM_LIST')
export const createCopy = createAction('CREATE_COPY');


export function setAlternativeIngredient(data) {
    console.log('setAlternativeIngredient');
    return async (dispatch) => {
        await dispatch(addAlternativeIngredient(data))
    }
}
export function editIngredient(index ,ingredientToChange ,newQuantity) {
    console.log('editIngredient');
    return async (dispatch) => {
        const data = {
            'index' : index,
            'ingredientToChange' : ingredientToChange,
            'newQuantity' : newQuantity
        }
        dispatch(changeIngredient(data))
    }
}
export function editIngredientNumber(index ,ingredientToChange ,newNumber) {
    console.log('editIngredient');
    return async (dispatch) => {
        const data = {
            'index' : index,
            'ingredientToChange' : ingredientToChange,
            'newNumber' : newNumber
        }
        dispatch(changeIngredientNumber(data))
    }
}

export function addNewMeal(data) {
    console.log('addNewMeal');
    return async (dispatch) => {
        dispatch(addMeal(data))
    }
}
export function deleteMeal(data) {
    console.log('deleteMeal');
    return async (dispatch) => {
        dispatch(removeMeal(data))
    }
}

export function setRecipe(index, recipe) {
    console.log('setRecipe');
    return async (dispatch) => {
        const data = {
            'index' : index,
            'recipe' : recipe
        }
        dispatch(addRecipe(data))
    }
}

export function addNewIngredient(data) {
    console.log('addNewIngredient');
    return async (dispatch) => {
        await dispatch(addIngredient(data))
    }
}

export function deleteIngredient(index, name) {
    console.log('deleteIngredient');
    return async (dispatch) => {
        const data = {
            'index' : index,
            'name' : name
        }
        console.log(data);
        await dispatch(removeIngredient(data))
    }
}

export function setSelectedMeal(data) {
    console.log('setSelectedMeal');
    return async (dispatch) => {
        dispatch(updateSelectedMeal(data.index))
    }
}

export function nbOfMeals(data) {
    console.log('selectedMeal');
    return async (dispatch) => {
        dispatch(updateNbOfMeals(data))
    }
}

export function updateTotals(data) {
    console.log('updateTotals');
    return async (dispatch) => {

        dispatch(total())
    }
}

export function updateTitle(data) {
    console.log('updateTitle');
    return async (dispatch) => {

        dispatch(setTitle(data))
    }
}

export function addIngredientData(data) {
    console.log('addIngredientData');
    return async (dispatch) => {

        dispatch(logNewIngredient(data))
    }
}
export function getAllIngredients(data) {
    console.log('getAllIngredients');
    return async (dispatch) => {

        dispatch(getIngredients(data))
    }
}

export function deleteIngredientFromList(id) {
    console.log('deleteIngredientFromList');
    return async (dispatch) => {
        await dispatch(removeIngredientFromList(id))
    }
}
export function copyDiet(data) {
    console.log('copyDiet');
    return async (dispatch) => {
        dispatch(createCopy(data))
    }
}

const initialState = {
    dietId: null,
    userId: null,
    title: 'Nouveau menu',
    meals: [],
    selectedMeal: null,
    nbOfMeals: 0,
    ingredientList : null,
    modif: false
}

export default createReducer(initialState, (builder) =>
    builder
        .addCase(addMeal, (draft, action) => {
            console.log('meal addcase');
            draft.meals.push({
                name: action.payload.name,
                index: action.payload.index,
                recipe: null,
                calories : 0,
                prot : 0,
                carbs : 0,
                fat : 0,
                ingredients: [
                ]
            })
        })
        .addCase(removeMeal, (draft, action) => {
            draft.meals = draft.meals.filter((meal) => meal.index !== action.payload)              
        })
        .addCase(addRecipe, (draft, action) => {
            draft.meals.forEach(meal => {
                if (meal.index === action.payload.index) {
                    meal.recipe = action.payload.recipe
                }
            })
        })
        .addCase(addIngredient, (draft, action) => {
            draft.meals.forEach(meal => {
                if (meal.index === action.payload.index) {
                    meal.ingredients.push(action.payload.ingredient)
                }
            });
        })
        .addCase(addAlternativeIngredient, (draft, action) => {
            console.log('ADDCASE ALTERNATIVE');
            draft.meals.forEach(meal => {
                console.log('meal : ' + meal.index + 'and payload.index : ' + action.payload.index);
                if (meal.index === action.payload.index) {
                    console.log('Meal found');
                    meal.ingredients.forEach(ingredient => {
                        if (ingredient.name === action.payload.ingredientToChange) {
                            console.log('Ingredient found');
                            ingredient.alternatives.push(action.payload.alternative)
                        } else console.log('no ingredient found on Feature')
                    })
                } else console.log('no meal found on Feature')
            })
        })
        .addCase(changeIngredient, (draft, action) => {
            draft.meals.forEach(meal => {
                if (meal.index === action.payload.index) {
                    meal.ingredients.forEach(ingredient => {
                        if (ingredient.name === action.payload.ingredientToChange) {
                            ingredient.quantity = action.payload.newQuantity
                        }
                    })
                }
            })
        })
        .addCase(changeIngredientNumber, (draft, action) => {
            draft.meals.forEach(meal => {
                if (meal.index === action.payload.index) {
                    meal.ingredients.forEach(ingredient => {
                        if (ingredient.name === action.payload.ingredientToChange) {
                            ingredient.number = action.payload.newNumber
                        }
                    })
                }
            })
        })
        .addCase(removeIngredient, (draft, action) => {
            draft.meals.forEach(meal => {
                if (meal.index === action.payload.index) {
                    let newList = []
                    meal.ingredients.forEach(ingredient => {
                        if (ingredient.name !== action.payload.name) {
                            newList.push(ingredient)
                        }   
                    })
                    meal.ingredients = newList
                }
            })
        })
        .addCase(updateSelectedMeal, (draft, action) => {
            draft.selectedMeal = action.payload
        })
        .addCase(updateNbOfMeals, (draft, action) => {
            draft.nbOfMeals = action.payload
        })
        .addCase(setTitle, (draft, action) => {
            draft.title = action.payload
        })
        .addCase(logNewIngredient, (draft, action) => {
            draft.ingredientList.push(action.payload)
        })
        .addCase(getIngredients, (draft, action) => {
            draft.ingredientList = action.payload
        })
        .addCase(removeIngredientFromList, (draft, action) => {
            draft.ingredientList = draft.ingredientList.filter((ingredient) => ingredient._id !== action.payload)              
        })
        .addCase(createCopy, (draft, action) => {
            draft.dietId = action.payload._id
            draft.userId = action.payload.userId
            draft.title = action.payload.title
            draft.meals = action.payload.meals
            draft.nbOfMeals = action.payload.meals.length
            draft.modif = true
        })
)