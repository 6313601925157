import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { uploadDoc } from "../../../features/selectedUser";
import colors from '../../UIKit/colors';
import Loading from '../Loading'

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    left 0px;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 780px) {
        width: calc(100% - 100px);
        left: unset;
        right: 0px;
      }
`

const Form = styled.form`
    width: 90%;
    padding: 50px 0px;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background-color: ${colors.beige};
    position: relative;
`
const CloseBtn = styled.div`
    position: absolute;
    top: 5px;
    right:10px;
`

export default function AddDoc({ userId, setShowAddDoc }) {
    const [docTitle, setDocTitle] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [file, setFile] = useState(null)
    const dispatch = useDispatch()

    const handleFiles = async (e) => {
        const inputSelected = document.getElementById(e.target.id)
        if (e.target.files[0].type !== "application/pdf") {
            inputSelected.value = null
            alert('Format incompatible')
        } else {
            setFile(e.target.files[0])

        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)

        const data = new FormData()
        data.append('docTitle', docTitle)
        data.append('docFile', file)

        const res = await axios.put(`${process.env.REACT_APP_API_URL}api/user/addDoc/${userId}`, data, { withCredentials: true })
        
        if (res.status !== 201) setIsLoading(false)
        if (res.status === 201) {
            const lastDoc = res.data.documents[res.data.documents.length - 1]
            dispatch(uploadDoc(lastDoc))
            setIsLoading(false)
        }
    };

    return (
        <Wrapper>
            {isLoading && <Loading />}
            <Form action="" method="post" enctype="multipart/form-data">
                <CloseBtn onClick={() => setShowAddDoc(false)}>X</CloseBtn>
                <h2>Ajouter un document</h2>
                <input type="text" onChange={(e) => setDocTitle(e.target.value)} placeholder="Nom de la doc" />
                <input type="file" onChange={(e) => handleFiles(e)} name="docFile" id="docFile" />
                <button  onClick={(e) => handleSubmit(e)}>Ajouter</button>
            </Form>
        </Wrapper>
    )
}