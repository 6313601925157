import MainWidget from '../UIKit/mainWidget'
import pic from '../../images/trainingBG.jpg'
import styled from 'styled-components'
import { keyframes } from 'styled-components'
import colors from '../UIKit/colors'

const link = '/training'
const title = 'Découvre ton entrainement offert'

const offerAnimation = keyframes`
 0% { transform: scale(1); }
 100% { transform: scale(1); }
`

const FreeTrainingContainer = styled.div`
position: relative;
`
const FreeOffer = styled.div`
    position: absolute;
    top: 0px;
    right: 10px;
    background-color: ${colors.beige};
    border : 2px solid ${colors.darkBrown};
    padding: 0.1rem 0.5rem;
    border-radius: 1rem;
    animation-name: ${offerAnimation};
 animation-duration: 8s;
 animation-iteration-count: infinite;
`

export default function TrainingWidget(){
    return (
        <FreeTrainingContainer>
            <MainWidget pic={pic} link={link} title={title} />
            <FreeOffer>Gratuit</FreeOffer>
        </FreeTrainingContainer>
    )
   
}