import axios from "axios"
import { useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { addIngredientData } from "../../../../features/dietCreator"
import colors from "../../../UIKit/colors"
import Loading from "../../Loading"

const Wrapper = styled.div`
    width: 100%;
    padding : 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    & h2{
        margin-bottom: 1rem;
        color: rgba(50,50,50,1);
    }
`
const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 95%;
    margin: auto;
`
const Input2 = styled.input`
    width: 5rem;
    border : 1px solid rgba(100,100,100, 0.3);
    border-radius: 3rem;
    padding : 0.4rem 0.8rem;
    color: ${colors.grey};
    font-size: 1rem;
    text-align: center;
    
    &::placeholder{
        color: ${colors.grey};
        font-size: 1rem;
      }

      &:focus {
        outline: none;
        
    }

    &.name{
        width: 100%;
        max-width: 20rem;
    }
`
const AddBtn = styled.input`
    border: unset;
    background-color: ${colors.pink};
    color: whitesmoke;
    border-radius: 3rem;
    padding : 0.4rem 0.8rem;
    font-size: 1.2rem;
    width: 8rem;
`
export default function AddNewIngredient() {
    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState(null)
    const [cal, setCal] = useState('')
    const [prot, setProt] = useState('')
    const [carb, setCarb] = useState('')
    const [fat, setFat] = useState('')
    const dispatch = useDispatch()

    const handleSubmit = async (e) => {
        setIsLoading(true)
        e.preventDefault()
        let data = {
            "name": name,
            "cal": cal,
            "prot": prot,
            "carb": carb,
            "fat": fat
        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}api/diet/newIngredient`, data, { withCredentials: true })
        const resData = await res.data
        if (res.status == 201) {
            setIsLoading(false)
            dispatch(addIngredientData(resData))
            setName('')
            setFat('')
            setCarb('')
            setProt('')
            setCal('')
        }
    }

    return (
        <Wrapper>
            {isLoading && <Loading />}
            <h2>Nouvel aliment</h2>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Input2
                className="name"
                    name="name"
                    autoFocus
                    type="text"
                    placeholder="Nom"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required
                />
                <Input2
                    type="number"
                    placeholder="Calories"
                    step="0.01"
                    onChange={(e) => setCal(e.target.value)}
                    value={cal}
                    required
                />

                <Input2
                    type="number"
                    step="0.01"
                    placeholder="Protéines"
                    onChange={(e) => setProt(e.target.value)}
                    value={prot}
                    required
                />
                <Input2
                    type="number"
                    step="0.01"
                    placeholder="Glucides"
                    onChange={(e) => setCarb(e.target.value)}
                    value={carb}
                    required
                />
                <Input2
                    type="number"
                    step="0.01"
                    placeholder="Lipides"
                    onChange={(e) => setFat(e.target.value)}
                    value={fat}
                    required
                />
                <AddBtn type="submit" value="Ajouter" />
            </Form>
        </Wrapper>
    )
}