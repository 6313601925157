import React from "react";
import { useSelector } from "react-redux"
import styled from "styled-components";
import colors from "../components/UIKit/colors";

import DietWidget from '../components/widgets/diet'
import TrainingWidget from '../components/widgets/training'
import Tracking from '../components/widgets/tracking'
import Motivation from "../components/widgets/motivation"
import DoubleWidget from "../components/widgets/DoubleWidget";
import { Link } from "react-router-dom";

 const Welcome = styled.h3`
    color: ${colors.pink};
    font-size: 2rem;
    width: 90%;
    margin: auto;
    font-family: Alata, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Oxygen, Cantarell, sans-serif;
 `
 const LinkSuiviContainer = styled.div`
  display: flex;
  justify-content: center;
`
const LinkSuivi = styled(Link)`
  background-color: ${colors.cyan};
  color: white;
  padding : 0.5rem 1rem;
  margin: 1rem;
`
const DietTrainingContainer = styled.div`
  @media (min-width: 780px) {
    margin: auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;  
  }
`

export default function Home(){
    const userData = useSelector((state) => state.user)


    return (
        <div id='Home'>
            <Welcome>Bienvenue {userData.pseudo}</Welcome>
            <Motivation />
            <DoubleWidget />
            <DietTrainingContainer>
              <DietWidget />
              <TrainingWidget />
              <Tracking />
            </DietTrainingContainer>
            <LinkSuiviContainer>
          <LinkSuivi to="/suivi">Voir mes bilans</LinkSuivi>
        </LinkSuiviContainer>
        </div>
    )
}