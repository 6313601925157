import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { uploadDiet } from "../../../features/selectedUser";
import colors from '../../UIKit/colors';
import Loading from '../Loading'

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    left 0px;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (min-width: 780px) {
        width: calc(100% - 100px);
        left: unset;
        right: 0px;
      }
`

const Form = styled.form`
    width: 90%;
    padding: 50px 0px;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background-color: ${colors.beige};
    position: relative;
`
const CloseBtn = styled.div`
    position: absolute;
    top: 5px;
    right:10px;
`
const DietsList = styled.div`   
    display: flex;
    flex-direction: column;
`
const DietLine = styled.div`

`
const DietLineSelected = styled.div`
    text-decoration: underline;
`
const ToggleDietDoc = styled.div`
    
    padding: 0.1rem 0.5rem;
    font-size: 1.2rem;
    border-radius: 1rem;
    display: flex;
    align-items: center; 
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

    &.active {
        box-shadow: inset rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    }
`
const NameForm = styled.form`
    display: flex;
    flex-direction: column;
`

const NameInput = styled.input`
    margin: 1rem 0;
    padding: 0.3rem 0.5rem;
    border-radius: 1rem;
    border: unset;
    background-color: rgba(100,100,100,0.4);   
`

export default function AddDiet({ userId, setShowAddDiet }) {
    const [dietTitle, setDietTitle] = useState(null)
    const [userDietTitle, setUserDietTitle] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [dietSelected, setDietSelected] = useState(null)
    const [diets, setDiets] = useState(null)
    const [file, setFile] = useState(null)
    const dispatch = useDispatch()

    const handleFiles = async (e) => {
        const inputSelected = document.getElementById(e.target.id)
        console.log(e.target.files[0]);
        if (e.target.files[0].type !== "application/pdf") {
            inputSelected.value = null
            console.log('nope');
            alert('Format incompatible')
        } else {
            setFile(e.target.files[0])
        }
        console.log(file);

    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true)

        const data = new FormData()
        data.append('dietTitle', dietTitle)
        data.append('dietFile', file)

        const res = await axios.put(`${process.env.REACT_APP_API_URL}api/user/addDiet/${userId}`, data, { withCredentials: true })

        if (res.status !== 201) alert("Erreur lors de l'envoie")
        if (res.status === 201) {
            const lastDiet = res.data.diets[res.data.diets.length - 1]
            dispatch(uploadDiet(lastDiet))
            setShowAddDiet(false)
        }
    };
    
    const handleSubmitAddDiet = async (e) => {
        e.preventDefault()
        console.log(dietSelected);
        setIsLoading(true)
        if (userDietTitle && dietSelected) {
            const data = {
                'dietUserTitle' : userDietTitle,
                'dietId': dietSelected
            }
            const res = await axios.put(`${process.env.REACT_APP_API_URL}api/user/addDietFMD/${userId}`, data, { withCredentials: true })
            if (res.status == 201) {
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        const fetchDiets = async () => {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}api/diet/getAllDiets`, { withCredentials: true })
            if (res.status == 200) {
                const data = await res.data
                setDiets(data)
                setIsLoading(false)
            }
        }
        fetchDiets()
    }, [])

    return (
        <Wrapper>
            {isLoading ? <Loading /> :
                <Form action="" method="post" enctype="multipart/form-data">
                    <CloseBtn onClick={() => setShowAddDiet(false)}>X</CloseBtn>
                    <h2>Ajouter une diet</h2>
                    {
                        <>
                            <input type="text" onChange={(e) => setDietTitle(e.target.value)} placeholder="Nom de la diet" />
                            <input type="file" onChange={(e) => handleFiles(e)} name="dietFile" id="dietFile" />
                            <button onClick={(e) => handleSubmit(e)} >Ajouter</button>
                        </>
                    }
                </Form>}
        </Wrapper>
    )
}