import React, { useContext } from "react"
import { Link } from "react-router-dom"
import logo from "../images/logo.png"
import styled from 'styled-components'
import colors from "./UIKit/colors"


import { BsPersonCircle } from "react-icons/bs"

const HeaderDiv = styled.header`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: Alata, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Oxygen, Cantarell, sans-serif;
`

const LineOne = styled.div`
    display: flex;
`

const Logo = styled.img`
    margin: 2rem auto;
    width: 150px;
`


const LinkStyled = styled.div`
    color : ${colors.grey};
    background-color:red;
`
const ProfileLink = styled(Link)`

`

const ProfileIcon = styled(BsPersonCircle)`
    position: absolute;
    right : 2.2rem;
    top : 2rem;
    font-size: 3rem;
`


const Header = () => {

    return (
        <HeaderDiv>
            <LineOne>
                <Logo src={logo} alt="Logo donuts musclé" />
                <LinkStyled>
                    <ProfileLink to="/profil">
                        <ProfileIcon />
                    </ProfileLink>
                </LinkStyled>
            </LineOne>
        </HeaderDiv>
    )
}

export default Header;