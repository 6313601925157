import styled from "styled-components"
import colors from "../components/UIKit/colors"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`
const TrainingText = styled.div`
    color: ${colors.grey};
    text-align:center;
    margin-auto;
    width : 90%;
`

const TrainingList = () => {

    return (
        <Wrapper>
            <TrainingText>
                Nous enregistrons actuellement tous les exercices afin de vous aider au mieux dans vos entrainements.
            </TrainingText>
            {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/3Pzhf8sMvWU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
        </Wrapper>
    )
}

export default TrainingList