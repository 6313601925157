import { useState } from 'react'
import { BsPlusCircleFill } from 'react-icons/bs'
import { useDispatch } from 'react-redux'
import styled from "styled-components"
import { addNewMeal } from "../../../../features/dietCreator"
import colors from '../../../UIKit/colors'
import { BiSearch } from "react-icons/bi"



const EditDiv = styled.form`
    width: 100%;
    margin: auto;
    padding : 0.5rem 0px;
    display: flex;
    align-items: center;
    align-items: center;
    background-color: white;
    border-radius: 1rem;
`

const EditInput = styled.input`
    background-color: unset;
    border : unset;
    border-radius: 0rem;
    color: rgb(10, 12, 16);
    font-size: 1rem;
    margin-left: 1rem;
    
    
    &::placeholder{
        color: rgb(155,155,155);
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }

      &:focus {
        outline: none;
        
    }
`
const AddBtn = styled(BsPlusCircleFill)`
    color: rgb(155,155,155);
`
const Submit = styled.input`
    display: none;
`
const Label = styled.label`
    margin-left: 1rem;
    margin-top: 0.6rem;
    font-size : 1.5rem;
`

export default function AddMealForm({ setAddMeal, index }) {
    const [meal, setMeal] = useState(null)
    const dispatch = useDispatch()

    const addMeal = async (e) => {
        e.preventDefault();
        if (meal === null) {
            setAddMeal(false)
            return
        }
        if (meal === '') {
            setAddMeal(false)
            return
        }
        setMeal(null)
        document.getElementById('addMealForm').value = ''
        dispatch(addNewMeal({
            name : meal,
            index : index,
        }))
        setAddMeal(false)
    }

    return (
        <EditDiv onSubmit={(e) => addMeal(e)}>
            <Label htmlFor="submitAlim"><AddBtn /></Label>
            <EditInput
                autoFocus
                type="text"
                id='addMealForm'
                className=""
                placeholder="Ajouter un repas"
                onChange={(e) => {
                    setMeal(e.target.value)
                }} 
            />
            <Submit type="submit" id="submitAlim" value="V" />
        </EditDiv>

    )
}