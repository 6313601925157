import axios from "axios"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import ModaleRecipe from "../components/Admin/Recettes/modaleRecipe/ModaleRecipe"
import { RecettesListe } from "../components/Admin/Recettes/RecettesListe"
import colors from "../components/UIKit/colors"
import { setRecipesList } from "../features/recipeCreator"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;    
`
const BtnNewRecipe = styled.button`
    border: none;
    background-color: ${colors.pink};
    display: inline-block;
    padding: 8px 24px;
    margin: 1rem 0;
    font-size: 1.2rem;
    color: #f6f6f6;
`
const Title = styled.div`
    color : ${colors.grey};
    font-size: 2rem;
    width: 100%;
    text-align: center;
    padding: 0.5rem 0px;
`

export const RecettesAdmin = () => {
    const [modale, setModale] = useState(false)


    return (
        <Wrapper>
            <Title>
                Recette Manager
            </Title>
            <BtnNewRecipe onClick={() => setModale(true)}>
                Nouvelle recette
            </BtnNewRecipe>
            <RecettesListe />
            {modale &&
                <ModaleRecipe setModale={setModale} />
            }
        </Wrapper>
    )
}