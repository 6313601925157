import styled from "styled-components"
import { AiFillCloseCircle } from 'react-icons/ai'
import colors from "../../UIKit/colors"
import { useEffect, useState } from "react"
import AddIngredientForm from "./modaleMenu-content/AddIngredientForm"
import AddNewIngredient from "./modaleMenu-content/AddNewIngredient"
import { MdRestaurantMenu } from "react-icons/md"
import { FaCarrot } from "react-icons/fa"
import { BiSearchAlt } from "react-icons/bi"
import MealList from "./modaleMenu-content/MealList"
import AddAlternativeIngredient from "./modaleMenu-content/AddAlternativeIngredient"

const BgWrapper = styled.div`
    position: fixed;
    top: 0px;
    width: 100%;
    height : 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(250,250,250,0.95);
    overflow: hidden;

    @media (min-width: 780px) {
        width: calc(100vw - 100px);
        max-width: calc(100vw - 100px);
    }   
`
const Wrapper = styled.div`
    z-index: 2;
    background-color: #edceb083;
    background-color: rgba(160, 125, 85,0.2);
    width: 100%;
    max-height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const BtnCloseContainer = styled.div`
    padding : 2rem 2rem;
    align-self: flex-end;
`
const BtnClose = styled(AiFillCloseCircle)`
    display: block;
    color: rgba(250,250,250,0.7);
    background-color: ${colors.grey};
    width : 2rem;
    height : 2rem;
    border-radius: 50%;  
`
const BtnContainer = styled.div`
    display: flex;
    width: 95%;
    margin: 1rem auto;
    justify-content: center;
    gap : 1rem;
`
const Button = styled.button`
    background-color: white;
    font-size: 1rem;
    width: 30%;
    height: 3em;
    border-radius: 1rem;
    color: #455e52;
    border : unset;
`
export default function ModaleMeal({ setModale }) {
    const [modaleContent, setModaleContent] = useState(null)

    useEffect(() => {
        switch (modaleContent) {
            case 'addNewIngredient' || '' || null:
                document.querySelector(".btn-new-alim").style.backgroundColor = colors.beige
                document.querySelector(".btn-search-alim").style.backgroundColor = "white"
                document.querySelector(".btn-add-meal").style.backgroundColor = "white"
                document.querySelector(".btn-add-alternative-ingredient").style.backgroundColor = "white"
                break

            case 'mealList':
                document.querySelector(".btn-add-meal").style.backgroundColor = colors.beige
                document.querySelector(".btn-search-alim").style.backgroundColor = "white"
                document.querySelector(".btn-new-alim").style.backgroundColor = "white"
                document.querySelector(".btn-add-alternative-ingredient").style.backgroundColor = "white"
                break

            case 'ALTERNATIVE_INGREDIENT':
                document.querySelector(".btn-add-alternative-ingredient").style.backgroundColor = colors.beige
                document.querySelector(".btn-add-meal").style.backgroundColor = "white"
                document.querySelector(".btn-search-alim").style.backgroundColor = "white"
                document.querySelector(".btn-new-alim").style.backgroundColor = "white"
                break


            default:
                document.querySelector(".btn-search-alim").style.backgroundColor = colors.beige
                document.querySelector(".btn-add-meal").style.backgroundColor = "white"
                document.querySelector(".btn-new-alim").style.backgroundColor = "white"
                document.querySelector(".btn-add-alternative-ingredient").style.backgroundColor = "white"
                return
        }
    }, [modaleContent])


    return (
        <BgWrapper>
            <BtnCloseContainer>
                <BtnClose onClick={() => setModale(false)} />
            </BtnCloseContainer>
            <Wrapper>
                <BtnContainer>
                    <Button
                        className="btn-new-alim"
                        onClick={() => setModaleContent('addNewIngredient')}>
                        {/* <FaCarrot /> */}
                        Nouveau
                    </Button >
                    <Button
                        className="btn-add-meal"
                        onClick={() => setModaleContent('mealList')}>
                        {/* <MdRestaurantMenu /> */}
                        Repas
                    </Button>
                    <Button
                        className="btn-search-alim"
                        onClick={() => setModaleContent('addIngredient')}>
                        {/* <BiSearchAlt /> */}
                        Aliments
                    </Button>
                    <Button
                        className="btn-add-alternative-ingredient"
                        onClick={() => setModaleContent('ALTERNATIVE_INGREDIENT')}>
                        {/* <BiSearchAlt /> */}
                        Alternatives
                    </Button>
                </BtnContainer>
                {(modaleContent === 'addIngredient' || modaleContent === '' || modaleContent === null) &&
                    <AddIngredientForm />
                }
                {modaleContent === 'addNewIngredient' &&
                    <AddNewIngredient />
                }
                {modaleContent === 'mealList' &&
                    <MealList setModale={setModale}/>
                }
                {modaleContent === 'ALTERNATIVE_INGREDIENT' &&
                    <AddAlternativeIngredient setModale={setModale}/>
                }
            </Wrapper>
        </BgWrapper>
    )
}