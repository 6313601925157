import { useSelector } from "react-redux"
import styled from "styled-components"
import colors from "../UIKit/colors"
import { formateDateWithYear, isBeforeToday } from "../../utils/utils"

const ContainerBeige = styled.div`
    background-color: ${colors.beige};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.2rem;
`
const InfosContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 80%;
color:  rgba(136, 122, 113, 0.5);
padding: 1.5rem 0px 0.8rem 0px;
`
const TitleSection = styled.div`
    color: white;
    width: 100%;
    font-size: 1.3rem;
    margin-bottom: 1rem;
`
const Info = styled.div`
    width: 100%;
    margin-bottom: 0.7rem;
    padding : 0.3rem 0px;
    border-bottom: 1px solid #887A71;
`
const InfoIsThere = styled.span`
    color: ${colors.grey}
`
const Suivi = styled.div`
    width: 100%;
    margin-bottom: 0.7rem;
    padding : 0.3rem 0px;
    color: ${colors.grey} 
`

export default function Infos() {
    const userData = useSelector((state) => state.user)
    const formula = useSelector((state) => state.user.formula)

    const end = formula ? formula.end : '15/10/2026'
    const status = formula ? formula.status : false
    let suiviMessage = ''

    if (status === false) {
        suiviMessage = 'Contacte-nous pour commencer ton suivi.'
    } else if (status === true) {
        suiviMessage = 'Suivi'

        if (isBeforeToday(end)) {
            suiviMessage = `Suivi valable jusqu'au ${formateDateWithYear(end)}`
        } else if (!isBeforeToday(end)) {
            suiviMessage = 'Suivi terminé'
        }
    }

    return (
        <ContainerBeige>
            <InfosContainer>
                <TitleSection>Mes infos personnelles</TitleSection>
                <Info>{userData.firstName ? <InfoIsThere>{userData.firstName}</InfoIsThere> : 'Prénom'}</Info>
                <Info>{userData.name ? <InfoIsThere>{userData.name}</InfoIsThere> : 'Nom'}</Info>
                <Info>{userData.pseudo ? <InfoIsThere>{userData.pseudo}</InfoIsThere> : 'Pseudo'}</Info>
                <Info>{userData.email ? <InfoIsThere>{userData.email}</InfoIsThere> : 'Email'}</Info>
                <Suivi>{suiviMessage}</Suivi>
            </InfosContainer>
        </ContainerBeige>
    )
}