import React from 'react'
import NavBar from "../NavBar"
import { Outlet } from 'react-router'
import styled from 'styled-components'

const Main = styled.div`
margin-bottom: 90px;

  @media (min-width: 780px) {
    margin-bottom: 0px;
    margin-left: 100px;
  }  
`

const withSmallNav = () => {
  return (
    <>
      <Main>
        <Outlet />
      </Main>
      <NavBar />
    </>
  )
}

export default withSmallNav