const colors = {
    whiteCover: 'rgba(255, 255, 255,0.25)',
    pink: '#DB92BB',
    darkBrown: '#887A71',
    grey: '#887A71',
    beige: '#EDCFB0',
    lightGrey : '#fbfbfb',
    cyan : '#95DDBC',
    beigeLight: '#edceb07e',
    greenPastel: '#94c578',
    redPastel: '#c57878',
    beigeBG : '#F6F7F6'
}

export default colors