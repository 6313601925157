import { useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { addAlim } from "../../features/user"
import {BsPlusCircleFill} from 'react-icons/bs'

const EditDiv = styled.form`
    width: 100%;
    margin-top: 0.7rem;
    padding : 0.3rem 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid white;
`

const EditInput = styled.input`
    background-color: unset;
    border : unset;
    border-radius: 0rem;
    color: rgba(255, 255, 255, 0.75);
    font-size: 1rem;
    width : 100%;
    
    &::placeholder{
        margin-top: 0.7rem;
        padding : 0.3rem 0px;
        color: rgba(255, 255, 255, 0.5);
        font-size: 1rem;
      }

      &:focus {
        outline: none;
        
    }
`
const Submit = styled.input`
    display: none;
`

export default function FormAlimOk(props) {
    const {uid, setEditOk, alimType} = props
    const [alimOk, setAlimOk] = useState(null)
    const dispatch = useDispatch()
    
    /* useEffect(() => {
        const input = document.getElementsByClassName('alimInput')
        input.focus()
    }, []) */

    const handleAlimOk = async (e) => {
        e.preventDefault()
        if (alimOk !== null)
        {
            if (alimOk !== "")
            {
                let data = {
                    alim : alimOk
                }
                dispatch(addAlim(uid, alimType, data))
            }
        }
        setEditOk(false)
    }


    return (
        <EditDiv onSubmit={handleAlimOk}>
            <EditInput autoFocus type="text" className="alimInput"  placeholder="Ajouter un aliment" onChange={(e) => setAlimOk(e.target.value)} />
            <Submit type="submit" id="submitAlim" value="V" />
            <label htmlFor="submitAlim"><BsPlusCircleFill /></label>
        </EditDiv>
    )
}