import { useSelector } from "react-redux"
import styled from 'styled-components'
import { BsArrowRightCircleFill } from 'react-icons/bs'
import colors from "../components/UIKit/colors";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const ListeBilanWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
`

const LinkBilan = styled.a`
    width : 100%;
    border-bottom: 1px solid ${colors.pink};
    padding: 0.5rem 0px;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const LinkForm = styled(Link)`
    width : 100%;
    border-bottom: 1px solid ${colors.pink};
    padding: 0.5rem 0px;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const InfoBilan = styled.div`
    font-size:1.2rem;
`
const Arrow = styled(BsArrowRightCircleFill)`
    color: ${colors.pink};
    font-size: 1.7rem;
`
const NoSuiviWrapper = styled.div`
    width : 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items:center;

`
const EmptyDiets = styled.div`
    width : 100%;
    margin: auto;
    text-align: center;
    background-color: ${colors.cyan};
    color : white;
    font-size: 1.2rem;
    padding : 0.5em 0px;
`
const EmptyMessage = styled.div`
    margin-top: 1rem;
    color: ${colors.grey}
`

export default function Documents() {
    const userData = useSelector((state) => state.user)
    const formula = useSelector((state) => state.user.formula)
    const [docLinks, setDocLinks] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        let links = []
        async function loadLinks(array) {
            await array.forEach(element => {
                links.push(element.docLink)
            });

            const res = await axios.post(`${process.env.REACT_APP_API_URL}api/bilan/images`, { keys: links }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            if (res.status === 201) {
                const data = res.data
                setDocLinks(data)

            }
            setIsLoading(false)
            setIsLoading(false)
        }
        loadLinks(userData.documents)
    }, [userData.documents])

    function isBeforeToday(date) {
        const today = new Date()
        const end = new Date(date)

        return end.getTime() > today.getTime()
    }
    const end = formula ? formula.end : null
    const status = formula ? formula.status : false

    if (isLoading) return <div>Loading</div>
    /* if (formula?.status === false) {
        return (
            <NoSuiviWrapper>
                <EmptyDiets>
                    Contacte-nous pour commencer ton suivi.
                </EmptyDiets>
                <ListeBilanWrapper>
                    {!userData.start &&
                        <LinkForm to="/formulaire">
                            <InfoBilan>
                                Formulaire de début
                            </InfoBilan>
                            <Arrow />
                        </LinkForm>
                    }
                </ListeBilanWrapper>
                <EmptyMessage>
                    Tu trouveras la liste de tes documents ici.
                </EmptyMessage>
            </NoSuiviWrapper>
        )
    } */
    return (
        <ListeBilanWrapper>
            {formula?.status === false &&
                <EmptyDiets>
                    Contacte-nous pour commencer ton suivi.
                </EmptyDiets>
            }
            {status && !isBeforeToday(end) && <EmptyDiets>Pense a renouveler ton suivi afin de garder tes menus adaptée</EmptyDiets>}
            {!userData.start &&
                <LinkForm to="/formulaire">
                    <InfoBilan>
                        Formulaire de début
                    </InfoBilan>
                    <Arrow />
                </LinkForm>
            }
            <LinkBilan target="_blank" href="/upload/docs/charte.pdf" >
                <InfoBilan>
                    Charte
                </InfoBilan>
                <Arrow />
            </LinkBilan>
            {userData.documents.length === 0 &&
                <NoSuiviWrapper>
                    <EmptyMessage>
                        Tu trouveras tes documents ici.
                    </EmptyMessage>
                </NoSuiviWrapper>
            }
            {userData.documents.map((doc, index) => {
                return (
                    <LinkBilan target="_blank" href={docLinks[index]} key={index}>
                        <InfoBilan>
                            {doc.docName}
                        </InfoBilan>
                        <InfoBilan>
                            {doc.weight}
                        </InfoBilan>
                        <Arrow />
                    </LinkBilan>
                )
            })}
        </ListeBilanWrapper>
    )
}