import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components'
import colors from "../../UIKit/colors";
import { useEffect } from "react";
import { useState } from "react";
import { deleteDiet, fetchDietUser } from "../../../features/selectedUser";
import { AiOutlineDelete } from "react-icons/ai";
import axios from "axios";
import { useNavigate } from "react-router-dom"
import { copyDiet } from "../../../features/dietCreator";


const ListeBilanWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    background-color: white;
    max-height: 170px;
    overflow-y: scroll;
    border : 2px solid ${colors.beige}
`
const DietDiv = styled.div`
    width : 90%;
    display : flex;
    justify-content: space-between;
    align-items:center;
    border-bottom: 1px solid ${colors.beige};
    margin-top: 1rem;
    font-size:1.7rem;
`
const LinkBilan = styled.a`
    display: flex;
    flex:1;

`
const LinkModifDiet = styled.div`

`
const InfoBilan = styled.div`
    font-size:1.2rem;
`

const Delete = styled.div`
    
`
const SecondTitle = styled.div`
    color : ${colors.beige};
    font-size: 1.6rem;
    width: 90%;
    padding: 0.5rem 0px 0px;
`



export default function DietsListUser({ userId }) {
    let navigate = useNavigate()
    const dispatch = useDispatch()
    const userDiets = useSelector((state) => state.selectedUser.data.diets)
    const userDietsFMD = useSelector((state) => state.selectedUser.data.dietsFMD)
    const [dietLinks, setDietLinks] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    console.log(userDietsFMD);



    const handleDelete = (dietId, dietLink) => {
        console.log('deleteFront');
        const data = {
            "dietId": dietId,
            "dietLink": dietLink
        }
        dispatch(deleteDiet(userId, data))
    }

    const handleClickDietFMD = (diet) => {
        dispatch(copyDiet(diet))
        navigate("/admin/newDiet", { replace: true })
    }



    useEffect(() => {
        let links = []
        async function loadLinks(array) {
            await array.forEach(element => {
                links.push(element.dietLink)
            });

            const res = await axios.post(`${process.env.REACT_APP_API_URL}api/bilan/images`, { keys: links }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            if (res.status === 201) {
                const data = res.data
                setDietLinks(data)

            }
        }

        async function fetchUserDiets(uid){
            const response = await axios.get(`${process.env.REACT_APP_API_URL}api/diet/getUserDiets/${uid}`, { withCredentials: true })
            const data = await response.data
            dispatch(fetchDietUser(data))
        }

        if (userDiets) loadLinks(userDiets)
        fetchUserDiets(userId)
        setIsLoading(false)

    }, [dispatch, userDiets, userId])

    return isLoading ? <div>Loading</div> : (
        <ListeBilanWrapper>
            <SecondTitle>Diets</SecondTitle>
            {userDiets?.map((diet, index) => {
                return (
                    <DietDiv key={index}>
                        <LinkBilan target="_blank" href={dietLinks[index]}>
                            <InfoBilan>
                                {diet.dietName}
                            </InfoBilan>
                            <InfoBilan>
                                {diet.weight}
                            </InfoBilan>
                        </LinkBilan>
                        <Delete className="delete-icon" onClick={() => handleDelete(diet._id, diet.dietLink)}>
                            <AiOutlineDelete />
                        </Delete>
                    </DietDiv>
                )
            })}
            {userDietsFMD?.map((diet, index) => {
                return (
                    <DietDiv key={index}>
                        <LinkModifDiet  onClick={() => handleClickDietFMD(diet)}>
                            <InfoBilan>
                                {diet.title}
                            </InfoBilan>
                        </LinkModifDiet>
                        <Delete className="delete-icon">
                            X
                        </Delete>
                    </DietDiv>
                )
            })}
        </ListeBilanWrapper>
    )

}