import axios from "axios"
import { AiFillCloseCircle } from "react-icons/ai"
import { BiSearchAlt } from "react-icons/bi"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { fetchOrUpdateUsers } from "../../../features/admin"
import colors from "../../UIKit/colors"

const BgWrapper = styled.div`
    position: fixed;
    top: 0px;
    width: 100%;
    height : 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(250,250,250,0.95);
    overflow: hidden;

    @media (min-width: 780px) {
        width: calc(100vw - 100px);
        max-width: calc(100vw - 100px);
    }   
`
const Wrapper = styled.div`
    z-index: 2;
    background-color: #edceb083;
    background-color: rgba(250,250,250,0);
    width: 100%;
    max-height: 80vh;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const BtnCloseContainer = styled.div`
    padding : 2rem 2rem;
    align-self: flex-end;
`
const BtnClose = styled(AiFillCloseCircle)`
    display: block;
    color: rgba(250,250,250,0.7);
    background-color: ${colors.grey};
    width : 2rem;
    height : 2rem;
    border-radius: 50%;  
`

const SearchInputContainer = styled.div`
    background-color: white;
    border-radius: 3rem;
    padding : 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
`
const SearchInputLabel = styled.label`
    margin-top: 0.5rem;
    color: ${colors.grey};
    font-size: 1.5rem;
`
const SearchInput = styled.input`
    border : unset;
    color: ${colors.grey};
    font-size: 1.2rem;
    
    
    &::placeholder{
        text-align: center;
        color: ${colors.grey};
        font-size: 1.2rem;
      }

      &:focus {
        outline: none;
        
    }
`
const UserLine = styled.div`
    display: none;
    width: 100%;
    padding: 0.3rem 0;
    border-bottom: 1px solid ${colors.darkBrown}
`

export default function ModaleDiet( {setModaleDiet,setIsSaving} ){
    const diet = useSelector((state) => state.dietCreator)
    const allUsers = useSelector((state) => state.adminData.users)
    const dispatch = useDispatch()

    async function fetchUsersAndBilans() {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}api/user`, { withCredentials: true })
        const data = await res.data
        dispatch(fetchOrUpdateUsers(data))

    }

    if(allUsers) console.log(allUsers);
        else {
            fetchUsersAndBilans()
        }


    const handleSaveMenu = async (userId) => {
        setIsSaving(true)
        const data = {
            userId: userId,
            title: diet.title,
            meals: diet.meals
        }
        const res = await axios.post(`${process.env.REACT_APP_API_URL}api/diet/saveDiet`, data, { withCredentials: true })
        if (res.status == 201) {
            console.log('Diet saved');
            setIsSaving(false)
            setModaleDiet(false)
        } else {
            console.log('Enable to save diet');
        }
    }

    const search = (value) => {
        const allDiv = document.querySelectorAll('.userLine')

        for (let i = 0; i < allUsers.length; i++) {
            const element = allUsers[i];
            const pseudo = element.pseudo.toUpperCase()
            if (pseudo.indexOf(value.toUpperCase()) > -1) {
                allDiv[i].style.display = "flex";
            } else {
                allDiv[i].style.display = "none";
            }

        }
    }


    return (
        <BgWrapper>
            <BtnCloseContainer>
                <BtnClose onClick={() => setModaleDiet(false)} />
            </BtnCloseContainer>
            <Wrapper>
            <SearchInputContainer>
                <SearchInputLabel htmlFor="searchInput">
                    <BiSearchAlt />
                </SearchInputLabel>
                <SearchInput
                    id="searchInput"
                    autoFocus
                    type="text"
                    placeholder="Rechercher un membre"
                    onChange={(e) => {
                        search(e.target.value)
                    }} />
            </SearchInputContainer>
                    {allUsers?.map((user, index) => (
                        <UserLine 
                        key={index}
                        className="userLine"
                        onClick={() => handleSaveMenu(user._id)}
                        >{user.pseudo}</UserLine>
                        ))}
            </Wrapper>
        </BgWrapper>
    )
}