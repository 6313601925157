import { createAction, createReducer } from "@reduxjs/toolkit";
import axios from "axios";

//Action
export const getUserBilans = createAction('GET_BILAN');

export const GET_BILAN_ERRORS = "GET_BILAN_ERRORS";


export function fetchOrUpdateUserBilans(uid){
    return async (dispatch) => {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/bilan/user/${uid}`, { withCredentials: true })
        const data = await response.data
        dispatch(getUserBilans(data));
    }
}

/* export const addBilan = (data) => {
    return async (dispatch) => {
      const res = await axios
        .post(`${process.env.REACT_APP_API_URL}api/bilan`, data, { withCredentials: true });
      if (res.data.errors) {
        dispatch({ type: GET_BILAN_ERRORS, payload: res.data.errors });
      } else {
        dispatch({ type: GET_BILAN_ERRORS, payload: "" });
      }
    };
  }; */

  //Reducer

  const initialState = '[]'

  export default createReducer(initialState, (builder) => 
    builder
      .addCase(getUserBilans, (state, action) => {
        return action.payload
    })  
  )

