import { useDispatch, useSelector } from "react-redux"
import styled from 'styled-components'
import { BsArrowRightCircleFill } from 'react-icons/bs'
import colors from "../components/UIKit/colors";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { fetchOrUpdateUserDietsFMD } from "../features/user";

const ListeBilanWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
`

const LinkBilan = styled.a`
    width : 100%;
    border-bottom: 1px solid ${colors.pink};
    padding: 0.5rem 0px;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const InfoBilan = styled.div`
    font-size:1.2rem;
`
const Arrow = styled(BsArrowRightCircleFill)`
    color: ${colors.pink};
    font-size: 1.7rem;
`
const NoSuiviWrapper = styled.div`
    width : 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items:center;

`
const EmptyDiets = styled.div`
    width : 100%;
    margin: auto;
    text-align: center;
    background-color: ${colors.cyan};
    color : white;
    font-size: 1.2rem;
    padding : 0.5em 0px;
`
const EmptyMessage = styled.div`
    margin-top: 1rem;
    color: ${colors.grey}
`

const LinkDietFMD = styled(Link)`
    width : 100%;
    border-bottom: 1px solid ${colors.pink};
    padding: 0.5rem 0px;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export default function Diet() {
    const userData = useSelector((state) => state.user)
    const diets = useSelector((state) => state.user.diets)
    const dietsFMD = useSelector((state) => state.user.dietsFMD)
    const formula = useSelector((state) => state.user.formula)
    const [dietLinks, setDietLinks] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const dispatch = useDispatch()

    useEffect(() => {
        let links = []
        async function loadLinks(array) {
            await array.forEach(element => {
                links.push(element.dietLink)
            });
            const res = await axios.post(`${process.env.REACT_APP_API_URL}api/bilan/images`, { keys: links }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true,
            })
            if (res.status === 201) {
                const data = res.data
                setDietLinks(data)

            }
            setIsLoading(false)
        }


        loadLinks(diets)

        dispatch(fetchOrUpdateUserDietsFMD(userData._id))
    }, [diets, dispatch, userData._id])

    function isBeforeToday(date) {
        const today = new Date()
        const end = new Date(date)

        return end.getTime() > today.getTime()
    }
    const end = formula ? formula.end : null
    const status = formula ? formula.status : false

    if (isLoading) return <div>Loading</div>
    if (formula?.status === false) {
        return (
            <NoSuiviWrapper>
                <EmptyDiets>
                    Contacte-nous pour commencer ton suivi.
                </EmptyDiets>
                <EmptyMessage>
                    Tu trouveras la liste de tes menus ici.
                </EmptyMessage>
            </NoSuiviWrapper>
        )
    }
    return diets.length === 0 && dietsFMD.length === 0 ?
        (<NoSuiviWrapper>
            <EmptyDiets>Ton premier menu arrive bientot</EmptyDiets>
            <EmptyMessage>
                Tu trouveras la liste de tes menus ici.
            </EmptyMessage>
        </NoSuiviWrapper>
        ) :
        (
            <ListeBilanWrapper>
                {status && !isBeforeToday(end) && <EmptyDiets>Pense a renouveler ton suivi afin de garder tes menus adaptés</EmptyDiets>}
                {diets.map((diet, index) => {
                    return (
                        <LinkBilan target="_blank" href={dietLinks[index]} key={index}>
                            <InfoBilan>
                                {diet.dietName}
                            </InfoBilan>
                            <Arrow />
                        </LinkBilan>
                    )
                })}
                {dietsFMD === 'LOADING_DIETS_FMD' ?
                    <>LOADING</>
                    :
                    dietsFMD === 'LOADING_DIETS_FMD' ?
                        <>ERROR</>
                        :
                        dietsFMD.map((diet, index) => {
                            return (
                                <LinkDietFMD to={diet._id} key={index}>
                                    <InfoBilan>
                                        {diet.title}
                                    </InfoBilan>
                                    <Arrow />
                                </LinkDietFMD>
                            )
                        })
                }
            </ListeBilanWrapper>
        )
}