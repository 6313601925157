import { useContext } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { UidContext } from "../AppContext"
import "../../styles/index.css"
import AdminNavBar from "../Admin/AdminNavBar"
import styled from "styled-components"

const Main = styled.div`
margin-bottom: 90px;

  @media (min-width: 780px) {
    margin-bottom: 0px;
    margin-left: 100px;
  }  
`

export default function AdminRoute() {

  const { pl, isLoading } = useContext(UidContext)

  if (isLoading) return <div className="loadingBg"> <div className="lds-ripple"> <div></div> <div></div> </div> </div>

  return (

    pl == process.env.REACT_APP_PL_CODE ?
    <Main>
      <Outlet />
      <AdminNavBar />
    </Main> : <Navigate to="/" />
  )
}