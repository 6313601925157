import { formateDate } from "../../../utils/utils"
import styled from "styled-components"

const Wrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction : column;
`

const BtnSuivi = styled.button`
    margin-top: 0.5rem;
    background-color: unset;
    border : unset;
    text-decoration: underline;
    font-size : 1rem;
`

export default function StatusNextCheckUp({setShowNextCheckUp, nextCheckUp}){

    return (
        <Wrapper>
            <span>{nextCheckUp && 'Date du prochain bilan : ' + formateDate(nextCheckUp)}</span>
            <BtnSuivi  onClick={() => setShowNextCheckUp(true)}>Déterminer le prochain bilan</BtnSuivi>
        </Wrapper>
    )

}