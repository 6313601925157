import { AiOutlineDelete } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { removeAlim } from "../../features/user";

//For bilan and user only

const Delete = (props) => {
  const dispatch = useDispatch()
  const { uid, alimType, alim } = props
  const handleDelete = () => {
    let data = {
      alim: alim
    }
    dispatch(removeAlim(uid, alimType, data))
  };
  return (
    <div className="delete-icon" onClick={() => handleDelete()}>
      <AiOutlineDelete />
    </div>
  );
};

export default Delete;