import axios from "axios"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { addExercice } from "../../../features/trainingCreator"

const Wrapper = styled.div`
    width:100%;
`
const Title = styled.div`
    font-size: 1.5rem;
    font-weight: bold;    
    font-family: 'Manrope', sans-serif;
    margin-bottom: 1rem; 
    margin-left: 5%;
`
const Categories = styled.select`
    background-color: #b1b1b11e;
    font-size: 1.2rem;
    padding: 0.3em;
    width: 80%;
    color: #464646ce;
`
const Category = styled.option`
    background-color: #b1b1b11e;
    padding: 0.5rem;
    width: 50%;
`
const ExercicesList = styled.div`
    width: 90%;
    margin: 1rem auto;
    display: flex;
    flex-wrap: wrap;
    max-height: 50vh;
`
const Exercice = styled.div`
    width: 45%;
`


export default function Exercices({setIsLoading}) {
    const [categories, setCategories] = useState(null)
    const [exercices, setExercices] = useState(null)
    const [error, setError] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState('all')
    const dispatch = useDispatch()

    useEffect(() => {
        const sortCategories = (exercices) => {
            let allCategories = []
            exercices.forEach(element => {
                if (!allCategories.includes(element.muscle)) {
                    allCategories.push(element.muscle)
                }
            })
            setCategories(allCategories)
        }

        const fetchRecipe = async () => {
            setIsLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_API_URL}api/training/getExercices`, { withCredentials: true })
            if (res.status === 200) {
                const data = await res.data
                setExercices(data)
                console.log(data);
                sortCategories(data)
            } else {
                setError('FAILED_TO_LOAD_exercices_LIST')
            }
            setIsLoading(false)
        }

        if (!exercices) fetchRecipe()
    }, [exercices, setIsLoading])

    return (
        <Wrapper>
            <Title>
                Exercices
            </Title>
            <Categories onChange={(e) => setSelectedCategory(e.target.value)}>
                        <Category value='all'>Toutes</Category>
                        <Category value='eui'>Aucune</Category>
                        {
                            categories?.map((category, index) => {
                                return (
                                    <Category value={category} key={index}>
                                        {category}
                                    </Category>
                                )
                            })
                        }
                    </Categories>
            <ExercicesList>
            {
                            exercices?.map((exercice, index) => {
                                return selectedCategory !== 'all' ?
                                    selectedCategory === exercice.muscle ?
                                        <Exercice onClick={() => {dispatch(addExercice(exercice))}} key={index}>
                                            {exercice.name}
                                        </Exercice>
                                        : null
                                    :
                                    <Exercice onClick={() => {dispatch(addExercice(exercice))}} key={index}>
                                        {exercice.name}
                                    </Exercice>
                            })
                        }
            </ExercicesList>
        </Wrapper >
    )
}