import axios from "axios"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import colors from "../../../components/UIKit/colors"

const Wrapper = styled.div`
    width : 100%;
    overflow-x: hidden;
`
const FormInfo = styled.div`
    margin: auto;
    margin-bottom: 100px;
    width : 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size : 1.2rem;
`
const Info = styled.div`
    font-size: 1.2rem;
`
const TextQuestion = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid ${colors.beige};

    & label {
        color: ${colors.grey};
        font-size: 1rem;
    }

    & label span {
        color : ${colors.beige}
    }
`

const LoadingWrapper = styled.div`
    position: absolute;
    width:100%;
    height:100%;
    z-index:2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    & .lds-ripple div {
        position: absolute;
        border: 4px solid white;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1.2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }
`

export default function FormInfos() {
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([])
    const { userId } = useParams()

    const optionsX = [
        {
            value: "1",
            label: "Sédentaire (pas d'activé physique, marche légère, travail sédentaire)"
        },
        {
            value: "2",
            label: "Légèrement actif (sport 1 à 3x semaine & travail sédentaire ou pas d'activité et travail un peu plus actif)"
        },
        {
            value: "3",
            label: "Actif (sport 3 à 5x semaine & travail sédentaire ou pas d'activité mais travail très intense)"
        },
        {
            value: "4",
            label: "Très actif (sport 3 à 5x semaine & travail très intense)"
        },
        {
            value: "5",
            label: "Extrêmement actif (sport + de 5x semaine & travail très intense)"
        },
    ]

    useEffect(() => {
        async function getForm(userId) {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}api/user/formInfo/${userId}`, { withCredentials: true })
            const data = await res.data
            setData(data[0])
            setIsLoading(false)
        }
        getForm(userId)
    }, [userId])


    return isLoading ?
        (
            <LoadingWrapper>
                <div className="lds-ripple" >
                    <div></div>
                    <div></div>
                </div>
            </LoadingWrapper>
        )
        : data.length === 0 ?
            (
                <>Pas encore de formulaire</>
            )
            :
            (
                <Wrapper>
                    <Wrapper>
                        <FormInfo >
                            <TextQuestion>
                                <label htmlFor="">Objectif</label>
                                <Info>{data.goal}</Info>
                            </TextQuestion>

                            <TextQuestion>
                                <label htmlFor="">Age</label>
                                <Info>{data.age}</Info>
                            </TextQuestion>

                            <TextQuestion>
                                <label htmlFor="">Poids</label>
                                <Info>{data.weight}</Info>
                            </TextQuestion>

                            <TextQuestion>
                                <label htmlFor="">Taille</label>
                                <Info>{data.height}</Info>
                            </TextQuestion>

                            <TextQuestion>
                                <label htmlFor="">Allergies, intolérances</label>
                                <Info>{data.allergies}</Info>
                            </TextQuestion>

                            <TextQuestion>
                                <label htmlFor="">Traitement médical</label>
                                <Info>{data.medicalTreatment}</Info>
                            </TextQuestion>

                            <TextQuestion>
                                <label htmlFor="">Consomation d'eau moyenne</label>
                                <Info>{data.water}</Info>
                            </TextQuestion>

                            <TextQuestion>
                                <label htmlFor="">Habitudes alimentaires</label>
                                <Info>{data.foodHabits}</Info>
                            </TextQuestion>

                            <TextQuestion>
                                <label htmlFor="">Emploie du temps</label>
                                <Info>{data.schedule}</Info>
                            </TextQuestion>

                            <TextQuestion>
                                <label htmlFor="">Blessures, douleurs</label>
                                <Info>{data.injuries}</Info>
                            </TextQuestion>

                            <TextQuestion>
                                <label>Niveau d'activité physique quotidien</label>
                                <Info>{optionsX[data.activityLevel].label}</Info>
                            </TextQuestion>

                            <TextQuestion>
                                <label htmlFor="">Nombre d'entrainement par semaine</label>
                                <Info>{data.trainingsWeek}</Info>
                            </TextQuestion>

                            <TextQuestion>
                                <label htmlFor="">Moment de l'entrainemnet</label>
                                <Info>{data.trainingTime}</Info>
                            </TextQuestion>

                        </FormInfo>
                    </Wrapper>
                </Wrapper>
            )
}