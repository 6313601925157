import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import colors from "../UIKit/colors"
import { MdModeEdit } from "react-icons/md"
import { useState } from "react"
import { changeGoal } from "../../features/user"
import { BsCheckCircleFill, BsPlusCircleFill } from "react-icons/bs"




const ContainerCyan = styled.div`
    background-color: ${colors.cyan};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.2rem;
`

const ObjectifsContainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    color: rgba(255, 255, 255, 1);;
    padding: 1.5rem 0px 1.5rem 0px;
`

const TitleSection = styled.div`
    color: white;
    width: 100%;
    font-size: 1.3rem;
    margin-bottom: 1rem;
`
const ObjectifsBox = styled.div`
    width : 100%;
    margin-bottom: 1rem;
    border: 1px solid white;
    padding : 1rem 0.6rem;
`
const InputGoal = styled.input`
    background-color : unset;
    border : unset;
    width : 100%;
    height : 100%;
    color : white;
    font-size : 1rem;

    &:focus {
        outline: none;
    }
`
const InputGoalHidden = styled.input`
    display: none;
`

const EditBoxGoal = styled.div`
    display : flex;
    justify-content: space-between;
`


export default function Objectifs() {
    const [edit, setEdit] = useState(false)
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.user)
    const uid = userData._id
    const [goal, setGoal] = useState(userData.goal)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (goal !== null) {
            if (goal !== "") {
                let data = {
                    goal: goal
                }
                dispatch(changeGoal(uid, data))
            }
        }
        setEdit(false)
    }


    return (
        <ContainerCyan>
            <ObjectifsContainer onSubmit={handleSubmit}>
                <TitleSection>Mes Objectifs</TitleSection>
                <ObjectifsBox>
                    {edit
                        ? <EditBoxGoal>
                            <InputGoal defaultValue={userData.goal} autoFocus type="text" name="" id="" onChange={(e) => { setGoal(e.target.value) }} />
                            <InputGoalHidden type="submit" value="" id="inputGoal" />
                            <label htmlFor="inputGoal"><BsCheckCircleFill /></label>
                        </ EditBoxGoal>
                        : <EditBoxGoal >
                            <span>{userData.goal ?? userData.goal}</span>
                            <div onClick={() => { setEdit(true) }}><MdModeEdit /></div>
                        </EditBoxGoal>
                    }
                </ObjectifsBox>
            </ObjectifsContainer>
        </ContainerCyan>
    )
}