import axios from "axios";
import Cookies from "js-cookie";


export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

function isBeforeToday(date) {
  const today = new Date()
  const end = new Date(date)

  return end.getTime() > today.getTime()
}

export function checkSuivi(formula) {
  const end = formula ? formula.end : null
  const status = formula ? formula.status : false

  if (status) if (isBeforeToday(end)) return true
  return false
}

export function setAuthToken(token){
  if (token) {
      axios.defaults.headers.common['auth'] = token;
  }
  else
      delete axios.defaults.headers.common["auth"];
}

export async function isLoggedIn(){
  const token = Cookies.get('token')

  if (!token) return false

  setAuthToken(token);

  try {
    const res = await axios ({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}jwtid`,
      withCredentials: true,
    })
    if (res.status === 200) return true
  } catch (error) {
    return false
  }
}





