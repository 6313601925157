import { createAction, createReducer } from "@reduxjs/toolkit";
import axios from "axios"

//Actions
export const getUser = createAction('GET_USER')
export const addAlimOk = createAction('ADD_Alim')
export const addAlimNok = createAction('ADD_Alim_NOK')
export const removeAlimOk = createAction('REMOVE_ALIM_OK')
export const removeAlimNok = createAction('REMOVE_ALIM_NOK')
export const editEmail = createAction('EDIT_EMAIL')
export const editGoal = createAction('GOAL')
export const editWeight = createAction('WEIGHT')
export const editStartWeight = createAction('START_WEIGHT')
export const getUserDiets = createAction('GET_USER_DIETS')


export function fetchOrUpdateUser (data) {
    return async (dispatch) => {
        dispatch(getUser(data))
    }
}

//Profil

export function addAlim (uid, alimType, data) {
    return async (dispatch) => {
        await axios.patch(`${process.env.REACT_APP_API_URL}api/user/${alimType}/${uid}`, data, { withCredentials: true })
        console.log(data.alim);
        alimType === 'addAlimOk' ? 
            dispatch(addAlimOk(data.alim))
            : dispatch(addAlimNok(data.alim))
    }
}

export function removeAlim (uid, alimType, data) {
    return async (dispatch) => {
        await axios.patch(`${process.env.REACT_APP_API_URL}api/user/${alimType}/${uid}`, data, { withCredentials: true })
        alimType === 'removeAlimOk' ? 
            dispatch(removeAlimOk(data.alim))
            : dispatch(removeAlimNok(data.alim))
    }
}

export function changeEmail (uid, data) {
    return async (dispatch) => {
        await axios.patch(`${process.env.REACT_APP_API_URL}api/user/email/${uid}`, data, { withCredentials: true })
            dispatch(editEmail(data.email))
    }
}

export function changeGoal (uid, data) {
    return async (dispatch) => {
        await axios.patch(`${process.env.REACT_APP_API_URL}api/user/goal/${uid}`, data, { withCredentials: true })
            dispatch(editGoal(data.goal))
    }
}

export function changeWeight (data) {
    return async (dispatch) => {
            dispatch(editWeight(data.weight))
    }
}
export function changeStartWeight (data) {
    return async (dispatch) => {
            dispatch(editStartWeight(data.weight))
    }
}
export function fetchDietsFMD (data) {
    return async (dispatch) => {
            dispatch(getUserDiets(data))
    }
}
export function fetchOrUpdateUserDietsFMD(uid){
    return async (dispatch) => {
        dispatch(getUserDiets('LOADING_DIETS_FMD'))
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/diet/getUserDiets/${uid}`, { withCredentials: true })
        if (response.status === 200) 
        {
            const data = await response.data
            dispatch(getUserDiets(data));
        } else {
            dispatch(getUserDiets('ERROR_DIETS_FMD'))
        }
    }
}

//Reducer
const initialState = {};

export default createReducer(initialState, (builder) => 
    builder
    .addCase(getUser, (state, action) => {
        return action.payload
    })
    //Profil
    .addCase(addAlimOk, (draft, action) => {
        draft.foodOk.push(action.payload)
    })
    .addCase(addAlimNok, (draft, action) => {
        draft.foodNok.push(action.payload)
    })
    .addCase(removeAlimOk, (draft, action) => {
        draft.foodOk = draft.foodOk.filter((alim) => alim !== action.payload)
    })
    .addCase(removeAlimNok, (draft, action) => {
        draft.foodNok = draft.foodNok.filter((alim) => alim !== action.payload)
    })
    .addCase(editEmail, (draft, action) => {
        draft.email = action.payload
    })   
    .addCase(editGoal, (draft, action) => {
        draft.goal = action.payload
    })  
    .addCase(editWeight, (draft, action) => {
        draft.weight = action.payload
    })  
    .addCase(editStartWeight, (draft, action) => {
        draft.start = action.payload
    })  
    .addCase(getUserDiets, (draft, action) => {
        draft.dietsFMD = action.payload
    })  
)