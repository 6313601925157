export function formateDate(oldDate) {
    const date = new Date(oldDate)
    const day = date.getDate()
    const month = date.getMonth() + 1

    return day + " / " + month
}
export function formateDateWithYear(oldDate) {
    const date = new Date(oldDate)
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    return day + " / " + month + " / " + year
}

export function isBeforeToday(date) {
    const today = new Date()
    const end = new Date(date)

    return end.getTime() > today.getTime()
}