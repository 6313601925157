import { current } from "@reduxjs/toolkit";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { MdOutlinePeopleAlt } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components"
import colors from "../components/UIKit/colors";

/* font-family: 'Dancing Script', cursive;
    font-family: 'Solitreo', cursive;
    font-family: 'Shadows Into Light', cursive;
    font-family: 'Neucha', cursive;
    font-family: 'Amatic SC', cursive;
    font-family: 'Alata', cursive; */

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items:center;
    margin-bottom: 2rem;
`
const Title = styled.div`
    font-family: 'Dancing Script', cursive;
    font-size: 3rem;
    text-align: center;
    margin: 0.5rem 0 0.5rem;
    font-weight: 600;
`
const RecipeCreator = styled.div`
    text-align: center;
    margin: auto;
    color: rgba(50,50,50,0.5);
`
const Cover = styled.img`
    margin: 1rem;
    height: 15rem;
    margin~: auto;
    border-radius: 2rem;
`
const SelectDisplay = styled.div`
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 1rem auto;
`
const SelectDisplayBtn = styled.div`
    width: 50%;
    text-align: center;
    position: relative;
    font-size: 1.2rem;
    font-weight: bold;    
    font-family: 'Manrope', sans-serif;

    &.active{
        color: ${colors.beige};
    }

    &.active::before{
        content: '';
        width: 0.6rem;
        height: 0.6rem;
        background-color: ${colors.beige};
        display: block;
        position: absolute;
        left: calc(50% - 0.3rem);
        top : -1rem;
        border-radius: 50%;
    }
`
const IngredientList = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 1rem;
`
const Ingredient = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
`
const IngredientName = styled.div`
    font-family: 'Alata', cursive;
    font-family: 'Manrope', sans-serif;
`

const IngredientQuantity = styled.div`
    font-family: 'Alata', cursive;
    font-family: 'Manrope', sans-serif;
`
const ExplanationText = styled.div`
    font-family: 'Alata', cursive;
    font-family: 'Manrope', sans-serif;
    font-size: 1.2rem;
`
const NbOfPerson = styled.div`
    display: flex;
    width: 90%;
    margin: 1rem auto;
    justify-content: center;
    font-weight: bold;
    font-size: 1.2rem;
    border-radius: 1rem;
    background-color: rgba(0,0,0,0.05);
`
const NbOfPersonBtn = styled.div`
    flex : 2;
    text-align: center;
    padding: 2rem 0;
`
const Person = styled.div`
    flex : 3;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const PersonIcon = styled.div`
    font-size: 2rem;
`
const PersonNumber = styled.div`
    font-family: 'Manrope', sans-serif;
`
const VideoBtnContainer = styled.div`
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: linear-gradient(0deg, rgba(256,256,256,1) 30%, rgba(256,256,256,0) 100%);
`
const VideoLink = styled(Link)`
    display: flex;
    width: 50%;
    background-color: black;
    color: whitesmoke;
    font-size: 1.1rem;
    font-family: 'Manrope', sans-serif;
    padding: 1rem 0.5rem;
    justify-content: center;
    border-radius: 4rem;
    margin: 3rem 0 2rem;
`
const VideoNtmMargin = styled.div`
    margin-bottom: 5rem;
`

export const RecetteDisplay = () => {

    let { recipeId } = useParams()
    const [recipe, setRecipe] = useState(null)
    const [selectedDisplay, setSelectedDisplay] = useState(1)
    const [nbPersons, setNbPersons] = useState(1)


    useEffect(() => {
        const ingredientsBtn = document.getElementById('ingredientsBtn')
        const explicationsBtn = document.getElementById('explicationsBtn')

        if (selectedDisplay === 1) {
            ingredientsBtn.classList.add("active")
            explicationsBtn.classList.remove("active")
        } else {
            explicationsBtn.classList.add("active")
            ingredientsBtn.classList.remove("active")
        }
    }, [selectedDisplay])


    const fetchRecipe = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}api/diet/getOneRecipe/${recipeId}`, { withCredentials: true })
        const data = await res.data
        console.log(data)
        setRecipe(data[0])
        if (data[0].persons) setNbPersons(data[0].persons)
    }
    if (recipe === null) fetchRecipe()

    const handleChangeNbOfPerson = (id) => {
        let currentNb = nbPersons
        if (id === 'plus') {
            currentNb++
            setNbPersons(currentNb)
        }

        if (id === 'minus') {
            if (currentNb > 1) {
                currentNb--
                setNbPersons(currentNb)
            }
        }
    }

    let cal = 0
    recipe?.ingredients?.forEach(ingredient => {
        cal += ingredient.cal * ingredient.quantity / 100
    });

    let prot = 0
    recipe?.ingredients?.forEach(ingredient => {
        prot += ingredient.prot * ingredient.quantity / 100
    });
    let carbs = 0
    recipe?.ingredients?.forEach(ingredient => {
        carbs += ingredient.carb * ingredient.quantity / 100
    });
    let fat = 0
    recipe?.ingredients?.forEach(ingredient => {
        fat += ingredient.fat * ingredient.quantity / 100
    });

    

    return (
        <Wrapper>
            <Title>{recipe?.name}</Title>
            <RecipeCreator>by Ali.diet_ethique</RecipeCreator>
            {recipe?.cover ? <Cover src={`../img/recipes/${recipe?.cover}`} alt="Cover recipe" /> 
            : <></>
            }
            <IngredientList>
                <NbOfPerson>
                    <NbOfPersonBtn id="minus" onClick={(e) => { handleChangeNbOfPerson(e.target.id) }}>-</NbOfPersonBtn>
                    <Person>
                        <PersonIcon><MdOutlinePeopleAlt /></PersonIcon>
                        <PersonNumber>{nbPersons} {nbPersons === 1 ? 'Personne' : 'Personnes'}</PersonNumber>
                    </Person>
                    <NbOfPersonBtn id="plus" onClick={(e) => { handleChangeNbOfPerson(e.target.id) }}>+</NbOfPersonBtn>
                </NbOfPerson>
                <SelectDisplay>
                    <SelectDisplayBtn id="ingredientsBtn" onClick={() => { setSelectedDisplay(1) }}>
                        Ingredients
                    </SelectDisplayBtn>
                    <SelectDisplayBtn id="explicationsBtn" onClick={() => { setSelectedDisplay(2) }}>
                        Recette
                    </SelectDisplayBtn>
                </SelectDisplay>
                {selectedDisplay === 1 ?
                    recipe?.ingredients?.map((ingredient, index) => {
                        return (
                            <Ingredient key={index}>
                                <IngredientName>{ingredient.name}</IngredientName>
                                <IngredientQuantity>{ingredient.quantity * nbPersons}</IngredientQuantity>
                            </Ingredient>
                        )
                    }) :
                    <ExplanationText>
                        {recipe?.text}
                    </ExplanationText>
                }
            </IngredientList>
            {recipe?.video ? <>
                <VideoBtnContainer>
                    <VideoLink to={`/${recipe?.video}`}>
                        Regarder la vidéo
                    </VideoLink>
                </VideoBtnContainer>
                <VideoNtmMargin></VideoNtmMargin>
                </>
                :
                <></>
            }
        </Wrapper>
    )
}