import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { HiOutlineArrowNarrowRight } from 'react-icons/hi'
import colors from "../../UIKit/colors";
import { useEffect } from "react";
import { useState } from "react";
import { fetchOrUpdateUserBilansAdmin } from "../../../features/selectedBilans";
import axios from "axios";

const ListeBilanWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    background-color: white;
    max-height: 170px;
    overflow-y: scroll;
    border : 2px solid ${colors.pink}
`
const LinkBilan = styled(Link)`
    width : 90%;
    border-bottom: 1px solid ${colors.pink};
    padding: 0.5rem 0px;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
`
const InfoBilan = styled.div`

`

const Arrow = styled(HiOutlineArrowNarrowRight)`
    color: #d5d5d5;
`

const SecondTitle = styled.div`
    color : ${colors.pink};
    font-size: 1.6rem;
    width: 90%;
    padding: 0.5rem 0px 0px;
`



export default function ListeBilan({ userId }) {
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(true)
    const bilans = useSelector((state) => state.selectedBilans);
    

    function formateDate(oldDate) {
        const date = new Date(oldDate)
        const day = date.getDate()
        const month = date.getMonth() + 1

        return day + " / " + month
    }

    useEffect(() => {
        async function getBilans(uid){
            const res = await axios.get(`${process.env.REACT_APP_API_URL}api/bilan/user/${uid}`, { withCredentials: true })
            if (res.status === 200 ) {
                const data =await res.data
                dispatch(fetchOrUpdateUserBilansAdmin(data))
                setIsLoading(false)
            } else {

            }
        }
        getBilans(userId)
    }, [dispatch, userId])


    return isLoading ? <div>Loading</div> : (
        <ListeBilanWrapper>
            <SecondTitle>Bilans</SecondTitle>
            {bilans?.map((bilan) => {
                return (
                    <LinkBilan to={"/admin/bilan/" + bilan._id} key={bilan._id}>
                        <InfoBilan>
                            {formateDate(bilan.createdAt)}
                        </InfoBilan>
                        <InfoBilan>
                            {bilan.weight + " kg"}
                        </InfoBilan>
                        <Arrow />
                    </LinkBilan>
                )
            })}
        </ListeBilanWrapper>
    )

}