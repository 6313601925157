import { useContext } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { UidContext } from "../AppContext"
import "../../styles/index.css"


export default function ProtectedRoute(){

  const {uid, isLoading} = useContext(UidContext)

  if(isLoading) return<div className="loadingBg"> <div className="lds-ripple"> <div></div> <div></div> </div> </div>

    return (
      uid ? <Outlet/> : <Navigate to="/start" />
    )
  }