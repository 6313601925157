import { useState } from 'react'
import axios from "axios";
import styled from 'styled-components';
import colors from '../UIKit/colors';
import { isLoggedIn } from '../Utils';
import Cookies from 'js-cookie';

const Wrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 300px;
  border: none;
  border-bottom: 1px solid #757575;
  &:focus {
    outline: none;
    border-bottom: 2px solid ${colors.beige};
  }
  &::placeholder{
    color: transparent;
  }
`;

const Label = styled.label`
  color: #999;
  font-size: 1rem;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  ${Input}:focus ~ & {
    top: -18px;
    font-size: 1.2rem;
    color: ${colors.beige};
  }
  ${Input}:not(:placeholder-shown) ~ & {
    top: -18px;
    font-size: 1.2rem;
    color: ${colors.beige};
  }
`


const SubmitBtn = styled.input`
  background-color: ${colors.beige};
  border: unset;
  padding: 5px 10px;
  font-size: 1.1rem;
  color: ${colors.darkBrown};
  margin: auto;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top:50px;
`
const ErrorDiv = styled.div`
  width: 300px;
  margin: auto;
  margin-bottom: 1.5rem;
`

const SignInForm = (props) => {

  const { setIsLoading, justValided } = props

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleLogin = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const emailError = document.querySelector('.email.error')
    const passwordError = document.querySelector('.password.error')
    const errorMessage = document.getElementById('errorMessage')

    try {

      const res = await axios ({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}api/user/login`,
        withCredentials: true,
        data: {email, password}
      })

      const status = res.status 

      if (status === 201) {

        const token = await res.data.token

        Cookies.set('token', token, { expires: 7 })

        if (token && isLoggedIn()) 
        {
          const res = await axios ({
            method: "get",
            url: `${process.env.REACT_APP_API_URL}pl`,
            withCredentials: true
          })
          const status = res.status

          if (status === 200) window.location = "/admin";

          else window.location = "/";

        }

      }
    } catch (err) {
      if (err.response.status === 401) {
        errorMessage.innerHTML = "Merci de vérifier tes emails afin d'activer ton compte"
        console.log('verify');
      }
      else if (err.response.data) {
        emailError.innerHTML = err.response.data.errors.email
        passwordError.innerHTML = err.response.data.errors.password
      } else {
        errorMessage.innerHTML = "Malheureusement une erreur s'est produite. Merci de réessayer plus tard."
      }
      setIsLoading(false)
    }
  }



  return (
    <Form action="" onSubmit={handleLogin} id="sign-up-form">
      <ErrorDiv id="errorMessage"></ErrorDiv>
      <ErrorDiv>{justValided && 'Compte validé'}</ErrorDiv>
      <Wrapper>
        <Input
          type="text"
          id="email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder="email"
          key={`inputEmail`}
        />
        <Label htmlFor="email">Email</Label>
      </Wrapper>
      <span className='email error'></span>
      <br />
      <Wrapper>
        <Input
          type="password"
          id="password"
          name="password"
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          key={`inputPass`}
        />
        <Label htmlFor="password">Mot de passe</Label>

      </Wrapper>
      <span className='password error'></span>
      <br />
      <SubmitBtn className="input_connexion" type="submit" value="Connexion" />
      <br />
    </Form>
  )
}

export default SignInForm