import styled from "styled-components"
import "../../styles/index.css"

export default function Loading({message}){
    const Wrapper = styled.div`
        position: absolute;
        width:100%;
        height:100%;
        background-color: rgba(255,255,255,0.7);
        z-index:2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `
    return(
        <Wrapper>
            <span>{message}</span>
            <div className="lds-ripple" >
                    <div></div>
                    <div></div>
                </div>
        </Wrapper>
    )
}