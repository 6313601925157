import styled from "styled-components"
import { Link } from "react-router-dom"

const TextBtnLink = styled(Link)`
    
    display: inline-block;
    color: #666666;
    text-decoration: underline;
`

export default function TextBtn(props) {
    const {text, link} = props

    return(
        <TextBtnLink to={link}>
                {text}
        </TextBtnLink>
    )
}